// Navigation
#site-navigations {
  position: relative;
  z-index: 1000;
  background-color: $white;
}

.navigation-top {
  &-left,
  &-right {
    .nav-desktop .nav-drop {
      @include toRem(padding, 20);
      background-color: $white;

      li {
        a {
          color: $blue_1;

          &:hover {
            color: $green_4 !important;
          }
        }
      }
    }

    .menu-item {
      display: flex;
      align-items: center;

      &.with-icon {
        &:hover {
          .item-link {
            color: $orange !important;

            > .icon {
              @include transform(translateX(10px));

              svg rect {
                fill: $orange;
              }
            }
          }
        }

        .item-link > .icon {
          @include toRem(margin-left, 12);
          @include transition(transform 600ms);

          svg rect {
            fill: $orange;
            @include transition(all 225ms);
          }
        }
      }

      &.current-menu-item,
      &.current_page_item,
      &.current-menu-ancestor,
      &.current-menu-parent {
        > .item-link {
          color: $green_4 !important;
        }
      }

      > .item-link {
        position: relative;
        display: flex;
        align-items: center;
        @include transition(color 225ms);

        @include media-breakpoint-down(xl) {
          font-weight: 700;
        }

        &:hover {
          color: $green_4 !important;
        }
      }

      &.menu-item-has-children {
        &:hover {
          > .item-link {
            color: $green_4 !important;
          }

          .nav-drop {
            opacity: 1 !important;
            visibility: visible !important;

            > li {
              opacity: 1 !important;
              @include transform(translateY(0));
            }
          }
        }

        .nav-drop {
          @include toRem(top, 30);
          z-index: 0;
          width: 160%;
          @include transition(all 225ms);

          @include media-breakpoint-up(xl) {
            opacity: 0;
            visibility: hidden;
          }

          > li {
            @include media-breakpoint-up(xl) {
              opacity: 0;
              @include transform(translateY(-60%));

              @for $i from 1 through 50 {
                &:nth-child(#{$i}) {
                  transition: all 1s #{$i * .08}s cubic-bezier(.29, 1.4, .44, .96);
                }
              }
            }
          }
        }
      }
    }
  }
}

//Burger Menu
#burger-menu {
  position: relative;
  z-index: 1;
  @include toRem(width, 35);
  @include toRem(height, 35);
  @include transform(rotate(0deg));
  @include transition(all 600ms ease-in-out);
  cursor: pointer;

  &.open span {
    &:nth-child(1) {
      @include toRem(top, 10);
      width: 0;
      left: 50%;
    }

    &:nth-child(2) {
      @include transform(rotate(45deg));
    }

    &:nth-child(3) {
      @include transform(rotate(-45deg));
    }

    &:nth-child(4) {
      @include toRem(top, 10);
      width: 0;
      left: 50%;
    }
  }

  span {
    display: block;
    position: absolute;
    @include toRem(height, 2);
    width: 100%;
    background-color: $blue_1;
    opacity: 1;
    left: 0;
    @include transform(rotate(0deg));
    transition: .25s ease-in-out;

    body.open-menu & {
      background-color: $white;
    }

    &:nth-child(1) {
      @include toRem(top, 5);
    }

    &:nth-child(2),
    &:nth-child(3) {
      @include toRem(top, 17);
    }

    &:nth-child(4) {
      @include toRem(top, 29);
    }
  }
}

#site-navigation-top__mobile {
  z-index: 0;
  @include transform(translateX(-100%));
  @include transition(transform 600ms cubic-bezier(.42, 0, .58, 1));

  &.open {
    pointer-events: auto !important;
    @include transform(translateX(0));
  }
}
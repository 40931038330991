//Breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        laptop: 1440px,
        xxl: 1441px
);

//Containers
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1280px,
        xxl: 1440px
);

//Spacers
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer*.25),
                        2: ($spacer*.4),
                        3: ($spacer*.8),
                        35: ($spacer*1.25),
                        4: ($spacer*1.875),
                        45: ($spacer*2.25),
                        5: ($spacer*3),
                        55: ($spacer*3.5),
                        6: ($spacer*3.85),
                        65: ($spacer*5),
                        68: ($spacer*5.5),
                        7: ($spacer*6.5),
                        75: ($spacer*7),
                        8: ($spacer*8),
                        9: ($spacer*10),
                        95: ($spacer*12),
                        10: ($spacer*14),
                        11: ($spacer*21),
                        12: ($spacer*16.5),
                        13: ($spacer*17),
                        14: ($spacer*18),
                        15: ($spacer*19),
                        16: ($spacer*20),
                        17: ($spacer*21),
                        18: ($spacer*22),
                        19: ($spacer*23),
                        20: ($spacer*24),
                        21: ($spacer*25),
                        22: ($spacer*26),
                        23: ($spacer*27),
                        24: ($spacer*28),
                        25: ($spacer*29),
                        26: ($spacer*30),
                        27: ($spacer*31),
                        28: ($spacer*32),
                        29: ($spacer*33),
                        30: ($spacer*34),
                        nav: ($spacer*.5),
                        100: ($spacer*6.25),
                ),
                $spacers
);

//Font-sizes
$display-font-sizes: (
        1: 5rem,
        2: 4.5rem,
        3: 3.9375rem,
        4: 3.4375rem,
        5: 3rem,
        6: 2.5rem,
        7: 2rem,
        8: 1.5625rem,
        9: 1.35rem,
        10: 0.75rem,
);

//Position
$position-values: (
        0: 0,
        20: 20%,
        50: 50%,
        100: 100%
);

//Gutters
$grid-gutter-width: 1.5rem;
$gutters: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 4,
        7: $spacer * 5,
        8: $spacer * 6,
        9: $spacer * 7,
        10: $spacer * 8,
);

//Width
$utilities: (
        "width": (
                property: width,
                class: w,
                values: (
                        25: 25%,
                        35: 35%,
                        40: 40%,
                        50: 50%,
                        55: 55%,
                        60: 60%,
                        70: 70%,
                        75: 75%,
                        85: 85%,
                        100: 100%,
                        120: 120%,
                        auto: auto
                )
        ),
        "height": (
                property: height,
                class: h,
                values: (
                        25: 25%,
                        35: 35%,
                        40: 40%,
                        45: 45%,
                        50: 50%,
                        55: 55%,
                        70: 70%,
                        75: 75%,
                        85: 85%,
                        100: 100%,
                        auto: auto
                )
        ),
        "text-opacity": (
                css-var: true,
                class: text-opacity,
                values: (
                        25: .25,
                        50: .5,
                        75: .75,
                        80: .8,
                        100: 1
                )
        ),
);

//Line-height
$line-height-base: 1.65;
$line-height-sm: 1.45;
$line-height-lg: 1.65;

//Buttons
.nav-link, a {
  color: black;
  text-decoration: none;
  @include transition(all 225ms);
}

.hover-underline-animation {
  display: inline-block;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: $green_4;
    transform-origin: bottom right;
    transition: transform 225ms ease-out;
  }

  &:hover{
    &:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}

.btn-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  color: $blue_1;
  font-weight: 700;
  outline: 0;
  padding: 0;
  text-decoration: none;

  > .icon {
    display: inline-flex;
    @include toRem(width, 20);
    @include toRem(height, 17);
    @include transition(transform 600ms);

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;

      rect,
      path {
        @include transition(all 225ms);
      }
    }
  }

  &:hover {
    color: $green_4 !important;

    > .icon {
      @include transform(translateX(10px));

      svg path {
        fill: $green_4;
      }
    }
  }

  &-icon-circle {
    > .icon {
      @include toRem(width, 50);
      @include toRem(height, 50);

      svg rect {
        fill: $green_4;
      }
    }

    &:hover {
      > .icon {
        svg path {
          fill: $white !important;
        }
      }
    }

    &-orange {
      > .icon {
        svg rect {
          fill: $orange;
        }
      }

      &:hover {
        color: $orange !important;
      }
    }

    &-green-light {
      > .icon {
        svg {
          rect {
            fill: $green_2;
          }

          path {
            fill: $green_4;
          }
        }
      }

      &:hover {
        > .icon {
          svg {
            rect {
              fill: $green_4;
            }
          }
        }
      }
    }
  }

  &-underline {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $black;
    }
  }
}

.btn-primary {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border-color: $white;
  border-radius: 0;
  @include toRem(padding-left, 40);
  @include toRem(padding-right, 40);
  @include toRem(height, 70);
  @include transition(background-color 600ms, border-color 600ms);

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  &-green {
    border-color: $green;
    color: $green;

    > svg {
      path {
        stroke: $green;
        @include transition(all 600ms);
      }
    }
  }

  &:hover {
    background-color: $blue_1;
    border-color: $blue_1;
    color: $white;

    > img,
    > svg {
      @include transform(translateX(10px));
    }

    > svg {
      path {
        stroke: $white;
      }
    }
  }

  > img,
  > svg {
    display: inline-flex;
    @include transition(transform 600ms);

    @include media-breakpoint-up(md) {
      @include toRem(margin-left, 130);
    }
  }
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}
// Template "Comité de direction de France Défi"
.template-comite {
  &__content {
    &-anchors {
      overflow-x: auto;
      overflow-y: hidden;
      scroll-snap-type: x mandatory;

      &-wrapper {
        z-index: 10;
      }

      li {
        position: relative;
        scroll-snap-align: start;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          @include toRem(height, 5);
          background-color: $blue_1;
          transform-origin: center;
          @include transform(scaleX(0));
          @include transition(transform, 600ms);
        }
        @include media-breakpoint-up(md) {
          &:hover {
            &:after {
              @include transform(scaleX(0.5));
            }
          }
        }
        &.active {
          &:after {
            @include transform(scaleX(1));
          }
        }

        &.active {
          button {
            font-weight: 700;
          }
        }
      }
    }

    &-items-item-rich-text {
      h3 {
        position: relative;
        color: $green_4;
        font-weight: 700;
        @include toRem(font-size, 18);
        @include toRem(margin-top, 35);
        @include toRem(margin-bottom, 30);
      }
    }
  }
}
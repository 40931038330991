// Template "Notre fonctionnement"
.template-our-operation {
  &__link {
    @include toRem(right, 200);
    @include toRem(bottom, -57);
    z-index: 1;
  }

  &__title-image {

    &.section-parallax{
      @include media-breakpoint-down(md) {
        &:after{
          content: '';
          z-index: -1;
          position:  absolute;
          bottom: -2px;
          right: 0;
          left: 0;
          height: 180px;
          background: rgb(0, 22, 70);
          background: linear-gradient(
            90deg,
            rgba(0, 22, 70, 1) 0%,
            rgba(11, 39, 100, 1) 100%
          );
        }
      }
    }

    z-index: 1;

    @include media-breakpoint-up(lg) {
      @include toRem(margin-top, -150);
    }

    &-bg {
      z-index: -1;
    }
  }

  &__bg-blue {
    z-index: 0;
    @include media-breakpoint-down(md) {
      .row.pt-12.pt-xl-22.pb-5.pb-xl-65{
        padding-top: 3rem !important;
      }
    }
    @include media-breakpoint-up(md) {
      @include toRem(margin-top, -260);
    }
  }

  &__deco-circle {
    z-index: 2;
    top: -50px;
    right: -150px;

    svg circle {
      stroke: $orange;
    }
  }

  &__testimonials {
    z-index: 0;
  }

 
}
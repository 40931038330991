//Animations
@include media-breakpoint-up(xl) {
  [anim-fade-in] {
    opacity: 0;

    &.is-active {
      animation: fadeInUp 0.6s ease 0s forwards;
    }
  }
}

.overlay {
  z-index: 10000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &-logo {
    z-index: 10001;
  }
}

.image-container {
  position: relative;
  overflow: hidden;
  width: 100%;

  > img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
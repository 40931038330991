// Page d'accueil
.block {

  // Bloc "Box"
  &-box {
    &__items {
      &-item {
        position: relative;

        @include media-breakpoint-up(lg) {
          padding-right: calc(var(--bs-gutter-x) * 1.5);
          padding-left: calc(var(--bs-gutter-x) * 1.5);

          &:nth-child(even) {
            @include toRem(top, 200);
          }
        }
      }
    }

    &__deco-circle {
      z-index: 2;
      @include toRem(width, 510);
      @include toRem(height, 510);
      @include toRem(top, 600);
      @include toRem(right, -200);

      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;

        circle {
          stroke: $orange;
        }
      }

      @include media-breakpoint-up(xxl) {
        @include toRem(right, -150);
      }
    }

    &__deco-circle-2 {
      z-index: 2;
      @include toRem(top, -200);
      @include toRem(left, -200);

      svg circle {
        stroke: $green_4;
      }
    }
  }

  // Bloc "Les membres"
  &-members {
    @include toRem(margin-top, -335);

    &__bg {
      z-index: -1;
    }

    &__video,
    &__image {
      @include media-breakpoint-up(lg) {
        @include toRem(height, 850);
      }
    }
  }

  // Bloc "Actualités et événements"
  &-news-events {
    z-index: 0;

    &__deco-circle {
      z-index: 2;
      @include toRem(width, 510);
      @include toRem(height, 510);
      @include toRem(top, -350);
      @include toRem(left, -300);

      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;

        circle {
          stroke: $orange;
        }
      }

      @include media-breakpoint-up(xxl) {
        @include toRem(right, -300);
      }
    }

    &__bg {
      z-index: -1;
      @include toRem(height, 1180);
    }

    &__title {
      @include toRem(line-height, 53);
    }
  }
}
//News (partial)
.news {
  &__title {
    h4 {
      @include transition(color 225ms);
    }

    &:hover h4 {
      color: $green_4;
    }
  }
}
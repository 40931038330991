// Template "Actualités et événements"
.template-news-events {
  input[type="checkbox"] {
    position: relative;
    border: 1px solid $blue_1;
    border-radius: 4px;
    @include toRem(top, 2);
    @include toRem(width, 17);
    @include toRem(height, 17);
    @include toRem(margin-right, 12);
    padding: 0;
    cursor: pointer;
    pointer-events: none;

    &:checked {
      background-image: url("../img/icons/icon-check.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 15px;
    }
  }

  &__items-item-metas-city {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      @include toRem(top, 2);
      @include toRem(left, -1);
      @include toRem(width, 2);
      @include toRem(height, 23);
      background-color: $green_4;
    }
  }

  &__filters-item {
    &.active {
      input[type="checkbox"] {
        background-image: url("../img/icons/icon-check.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 15px;
      }
    }
  }
}

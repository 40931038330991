// share
.share {
  &__rich-text {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      column-count: 2;

      li {
        background-image: url("../img/icons/icon-arrow-right-orange-2.svg");
        background-repeat: no-repeat;
        background-position: center left;
        background-size: 18px;
        display: flex;
        @include toRem(padding-left, 45);
        @include toRem(margin-bottom, 8);
      }
    }
  }
}
body {
  min-width: 320px;
  background-color: $white;
  color: $blue_1;
  @include toRem(font-size, 16);
  @include toRem(line-height, 25);
  font-family: $raleway;
  overscroll-behavior: none;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;

  &.open-menu {
    overflow: hidden;
  }
}

strong {
  font-weight: 700;
}

input,
select,
button {
  appearance: none;
  box-shadow: none;
  border: 0;
  background: transparent;
  outline: 0;
}

#content {
  > div {
    position: relative;
    z-index: 3;
  }

  > .header {
    position: relative;
    z-index: 4;
  }
}

.main {
  position: relative;
  z-index: 1;
}

#app,
.site-container {
  overflow-x: hidden;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  padding-right: calc(var(--bs-gutter-x) * 0.75);
  padding-left: calc(var(--bs-gutter-x) * 0.75);
}

a:not(.btn) {
  &:hover {
    color: $green_4 !important;
  }
}

.border {
  &-blue-1 {
    --bs-border-color: #001646;
  }

  &-green-2 {
    --bs-border-color: #dff8f1;
  }
}

.text {
  &-blue-1 {
    color: $blue_1;
  }

  &-black {
    color: $black;
  }

  &-gray {
    color: $gray;
  }

  &-green-2 {
    color: $green_2;
  }

  &-green-4 {
    color: $green_4;
  }
}

.icon-svg {
  svg path {
    @include transition(fill 225ms);
  }

  &:hover svg path {
    fill: $red;
  }
}

.anim-scale-img {
  overflow: hidden;

  img {
    @include transition(all 350ms);
  }

  &:hover {
    img {
      @include transform(scale(1.15));
    }
  }
}

.card {
  --bs-card-border-radius: 0;
}

.bg {
  &-gradient-green {
    background: rgb(223, 248, 241);
    background: linear-gradient(
      90deg,
      rgba(236, 248, 245, 1) 0%,
      rgba(223, 248, 241, 1) 100%
    );
  }

  &-gradient-blue {
    background: rgb(0, 22, 70);
    background: linear-gradient(
      90deg,
      rgba(0, 22, 70, 1) 0%,
      rgba(11, 39, 100, 1) 100%
    );
  }

  &-blue-1 {
    background-color: $blue_1;
  }

  &-green-1 {
    background-color: $green_1;
  }

  &-green-2 {
    background-color: $green_2;
  }

  &-orange {
    background-color: $orange;
  }
}

.field {
  &__title {
    strong {
      font-weight: 800;
    }
  }

  &__text {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        position: relative;
        @include toRem(padding-left, 8);

        &:before {
          content: "";
          position: absolute;
          left: 0;
          @include toRem(top, 16);
          @include toRem(width, 2);
          @include toRem(height, 2);
          background-color: $black;
          border-radius: 50%;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .column-count-3 {
    column-count: 3;
  }
}

.fs {
  &-15 {
    @include toRem(font-size, 15);
  }

  &-16 {
    @include toRem(font-size, 16);
  }

  &-18 {
    @include toRem(font-size, 18);
  }

  &-20 {
    @include toRem(font-size, 20);
  }

  &-25 {
    @include toRem(font-size, 16);

    @include media-breakpoint-up(xl) {
      @include toRem(font-size, 25);
    }
  }

  &-32 {
    @include toRem(font-size, 32);

    @include media-breakpoint-up(xl) {
      @include toRem(font-size, 32);
    }
  }

  &-35 {
    @include toRem(font-size, 30);

    @include media-breakpoint-up(xl) {
      @include toRem(font-size, 35);
    }
  }

  &-42 {
    @include toRem(font-size, 35);

    @include media-breakpoint-up(xl) {
      @include toRem(font-size, 42);
    }
  }

  &-45 {
    @include toRem(font-size, 38);

    @include media-breakpoint-up(xl) {
      @include toRem(font-size, 45);
    }
  }

  &-100 {
    @include toRem(font-size, 60);

    @include media-breakpoint-up(xl) {
      @include toRem(font-size, 100);
    }
  }
}

@include media-breakpoint-up(md) {
  .column-count-2 {
    column-count: 2;
  }
}

@include media-breakpoint-down(xl) {
  .vh-100 {
    height: auto !important;
  }
}

.plyr {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 0;

  .plyr__control--overlaid {
    background: $green_4;
    padding: calc(var(--plyr-control-spacing, 10px) * 1.5);
    color: var(--plyr-video-control-color, $black);

    &:hover {
      background: $black !important;
    }

    svg {
      height: var(--plyr-control-icon-size, 20px);
      width: var(--plyr-control-icon-size, 20px);
    }
  }

  .plyr__video-wrapper {
    position: relative;
    height: 100%;

    .plyr__poster {
      background-size: cover;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.fw {
  &-light {
    font-weight: 300;
  }

  &-light-italic {
    font-weight: 300;
    font-style: italic;
  }

  &-extra-bold {
    font-weight: 800;
  }
}

.block {
  // Bloc "Slider de mots"
  &-words-slider {
    z-index: 1;

    @include media-breakpoint-up(xl) {
      @include toRem(height, 700);
    }

    .container {
      > .row {
        @include media-breakpoint-up(xl) {
          > .border-deco {
            position: relative;

            &:before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              width: 1px;
              height: calc(50% - (204px / 2));
              background-color: $white;
            }

            &:after {
              content: "";
              position: absolute;
              bottom: 0;
              right: 0;
              width: 1px;
              height: calc(50% - (204px / 2));
              background-color: $white;
            }
          }
        }
      }
    }

    &__title {
      @include toRem(line-height, 53);
    }

    &__text {
      @include toRem(line-height, 28);
    }

    &__slider-nav {
      @include toRem(width, 18);
    }

    &__items {
      &-item {
        color: $white;
        @include toRem(line-height, 35);

        @include media-breakpoint-up(xl) {
          color: transparentize($white, 0.82);
          @include toRem(margin-bottom, 70);

          &.vl-slide-center {
            @include toRem(font-size, 35);
            @include toRem(line-height, 50);
            font-weight: 800 !important;
            color: $white;

            @include media-breakpoint-up(xl) {
              @include toRem(font-size, 45);
            }
          }
        }
      }
    }

    .slider-button {
      @include toRem(width, 30);
      @include toRem(height, 30);
      box-shadow: none;
      border: 0;
      background-color: transparent;
      outline: 0;

      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;

        path {
          @include transition(all 225ms);
        }
      }

      &:hover {
        svg {
          path {
            fill: $orange;
          }
        }
      }
    }

    &__deco-circle {
      z-index: 2;
      @include toRem(width, 510);
      @include toRem(height, 510);
      @include toRem(top, 900);
      @include toRem(right, -300);

      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;

        circle {
          stroke: $orange;
        }
      }

      @include media-breakpoint-up(xxl) {
        @include toRem(right, -300);
      }
    }
  }

  // Bloc "Témoignages"
  &-testimonials {
    z-index: 2;

    &__bg {
      z-index: -1;
    }

    @include media-breakpoint-up(lg) {
      .template-who-are-we & {
        @include toRem(margin-top, -100);
      }
    }

    &__title {
      position: relative;
      z-index: 3;
    }

    &__items-item-author {
      @include media-breakpoint-up(md) {
        @include toRem(margin-top, -60);
      }
    }

    @include media-breakpoint-up(xl) {
      &__image,
      &__video {
        box-shadow: 0 0 30px transparentize($black, 0.85);
        // @include toRem(height, 473);
        // @include toRem(margin-top, -75);
      }
    }

    &__slider-nav {
      position: relative;
      z-index: 3;

      @include media-breakpoint-up(xl) {
        @include toRem(margin-top, -175);
      }
    }

    &__deco-circle {
      z-index: -1;
      @include toRem(width, 138);
      @include toRem(height, 138);
      @include toRem(bottom, -70);
      @include toRem(left, -70);

      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;

        circle {
          stroke: $orange;
        }
      }
    }

    .slider-button {
      @include toRem(width, 50);
      @include toRem(height, 50);
      box-shadow: none;
      padding: 0;
      border: 0;
      background-color: transparent;
      outline: 0;

      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;

        rect,
        path {
          @include transition(all 225ms);
        }
      }

      &:hover {
        svg {
          rect {
            fill: $green_4;
          }

          path {
            fill: $white;
          }
        }
      }
    }
  }
}

.tab-pane {
  display: none !important;

  &.active {
    display: block !important;
  }
}

.gm-style {
  .gm-style-iw-c {
    border-radius: 0 !important;
    padding: 20px !important;
    outline: 0 !important;
  }

  .gm-style-iw-d {
    overflow: auto !important;
  }

  .gm-style-iw-c {
    background-color: $green_1 !important;
  }

  .gm-style-iw-tc::after {
    content: none !important;
  }
}

.marker-position {
  bottom: 7px;
  left: 0;
  position: relative;
  font-weight: 700;
}

sup {
  top: -1px;
  @include toRem(font-size, 18);
  left: 2px;
}

.anim-rotate-infinite {
  animation: rotating 15s linear infinite;

  &:hover {
    animation-play-state: paused;
  }
}

@-webkit-keyframes rotating {
  /* Safari and Chrome */
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.on-top {
  z-index: 10000;

  .icon svg path {
    @include transition(all 600ms);
  }

  &:hover {
    .icon svg path {
      fill: $orange;
      opacity: 1 !important;
    }
  }
}

.gm-style-cc {
  display: none;
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}

a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

.wpcf7-response-output {
  position: absolute;
  bottom: calc(100% + 20px);
  left: 0;
  margin: 0 !important;
}

.plyr .plyr__control--overlaid {
  background: $blue_1;
  @include transition(background 225ms);

  svg {
    fill: #8192a6;
    @include transition(fill 225ms);
  }

  &:hover {
    background: $green_4 !important;

    svg {
      fill: $blue_1;
    }
  }
}

.overflow-hidden-y {
  overflow-y: hidden;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.selectr-container {
  width: 100%;

  .selectr-selected {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    border: 0;

    &::before {
      content: none;
    }
  }

  &.inverted {
    .selectr-options-container {
      bottom: calc(100% + 8px) !important;
      background-color: $green_1 !important;
    }
  }

  .selectr-options-container {
    top: calc(100% + 8px);
    border: 0;
    border-radius: 0;
    background-color: $green_1;

    .selectr-option {
      text-transform: lowercase;
      color: $blue_1;

      &:first-letter {
        text-transform: capitalize;
      }

      &.active {
        color: $white !important;
        background-color: $blue_1 !important;
      }

      &.selected {
        color: $white !important;
        background-color: $green_4 !important;
      }
    }
  }
}

.wpcf7-form .error-radio {
  color: $red;
  @include toRem(font-size, 18);
  margin: 10px 0;
  font-weight: 700;

  &.show {
    display: block !important;
  }
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.unaccepted .wpcf7-acceptance .wpcf7-list-item-label {
  &,
  a {
    color: $red;
    font-weight: 700;
  }
}

// Template "Notre promesse"
.template-our-promise {
  &__deco-circle {
    z-index: 2;
    @include toRem(bottom, -200);
    @include toRem(left, -200);

    svg circle {
      stroke: $green_4;
    }

    @include media-breakpoint-up(xxl) {
      @include toRem(bottom, -150);
      @include toRem(left, -150);
    }
  }

  &__boxes-with-icons {
    z-index: 3;

    &-deco-circle {
      z-index: 2;
      @include toRem(bottom, -200);
      @include toRem(right, -200);

      svg circle {
        stroke: $green_4;
      }

      @include media-breakpoint-up(xxl) {
        @include toRem(bottom, -150);
        @include toRem(right, -150);
      }
    }

    &-items {
      z-index: 4;

      &-item {
        width: 100%;
        box-shadow: 0 0 30px transparentize($black, .93);

        @include media-breakpoint-up(lg) {
          @include toRem(width, 342);
          @include toRem(height, 342);

          &:nth-child(even) {
            position: relative;
            @include toRem(top, 200);
          }

          &:last-child {
            margin-bottom: -50px !important;
          }
        }
      }
    }
  }

  &__double_column-bis-rich-text-2 {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        @include toRem(padding-left, 50);
        @include toRem(margin-bottom, 20);
        background-image: url("../img/icons/icon-arrow-right-green.svg");
        background-repeat: no-repeat;
        background-position: top 5px left;
        background-size: 18px;

        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  &__texts-image-rich-text-1,
  &__texts-image-rich-text-2 {
    strong {
      color: $green_4;
      display: flex;
      @include toRem(margin-bottom, 30);
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        position: relative;
        @include toRem(margin-bottom, 10);

        &:before {
          content: '•';
          position: relative;
          display: inline-flex;
          @include toRem(margin-right, 5);
        }

        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  &__texts-image {
    position: relative;
    z-index: 3;

    &-image {
      position: relative;


      @include media-breakpoint-up(lg) {
        @include toRem(margin-bottom, -200);
      }
    }
  }

  &__testimonials {
    z-index: 0;
    @include media-breakpoint-up(lg) {
      @include toRem(margin-top, -330);
    }
  }
}
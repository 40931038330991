// key figures
.key-figures {
  body.home & {
    position: relative;
    z-index: 2;
    @include toRem(margin-top, -225);
    padding-bottom: 0 !important;

    @include media-breakpoint-up(lg) {
      @include toRem(margin-top, -350);
    }
  }

  body.page-template-why-join-fd & {
    position: relative;
    z-index: 2;
    padding-bottom: 0 !important;

    @include media-breakpoint-up(lg) {
      @include toRem(margin-top, -225);
    }
  }

  &__boxes {
    @include toRem(margin-top, -100);

    &.is-active {
      .key-figures__box-item {
        @for $i from 1 through 50 {
          &:nth-child(#{$i}) {
            .key-figures__box-item-number {
              opacity: 1;
              @include transform(translateX(0));
              transition: all 1s #{$i * .08}s cubic-bezier(.29, 1.4, .44, .96);
            }
          }
        }
      }
    }
  }

  &__box {
    box-shadow: 0 0 30px transparentize($black, .85);

    @include media-breakpoint-up(lg) {
      @include toRem(height, 390);
    }

    &-item-number {
      display: inline-flex;
      font-family: $lexend;
      @include media-breakpoint-up(lg) {
        opacity: 0;
        @include transform(translateX(-15px));
      }
      > .icon {
        @include toRem(top, 15);
        left: calc(100% + 15px);
      }
    }
  }
}
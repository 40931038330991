.template-the-procedure-in-detail {
  .block-testimonials {
    .block-testimonials__items {
      .block-testimonials__items-item {
        padding-bottom: 0px !important;
      }
    }
  }
}

.tns-outer{

  @include media-breakpoint-down(md) {
    max-width: 100%;
    width: 100%;
  .block-words-slider__items-item{
      max-width: 100%;
      width: 100%;
      word-break: break-word;
    }
  }
}
.relative{
  position: relative;
} 
.z-10 {
  z-index: 10;
}

.md-h-content{
  @include media-breakpoint-down(md) {
    height: fit-content;
  }
}
// Template "Qui sommes-nous ?"
.template-who-are-we {
  &__timeline .tns-inner {
    display: flex;
  }

  &__blockquote {
    &-text {
      @include toRem(line-height, 57);
    }
  }

  &__members-bg,
  &__our-strengths-bg {
    z-index: -1;
  }

  &__ope-team {
    @include toRem(margin-top, -450);
  }

  &__our-strengths {
    @include toRem(margin-top, -300);

    @include media-breakpoint-up(lg) {
      @include toRem(margin-top, -400);

      &-items-item {
        width: calc(45% - 1.875rem);
      }
    }
  }

  .customize-tools {
    border-bottom: 1px solid $green_4;

    .slider-button {
      position: relative;
      @include toRem(top, 5);

      &[disabled] {
        opacity: 0;
        pointer-events: none;
      }
    }

    .tns-nav {
      position: relative;
      @include toRem(top, 21);

      button {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          transform-origin: center;
          @include transform(scaleX(0));
          @include toRem(height, 2);
          @include toRem(bottom, -1);
          background-color: $green_4;
          @include transition(transform 600ms);
        }

        &.tns-nav-active {
          font-weight: 700;
          @include toRem(font-size, 30);
          color: $green_4;

          &:after {
            @include transform(scaleX(1));
          }
        }
      }
    }
  }
}
// Footer
.footer {
  position: relative;
  z-index: 2;

  .menu-item {
    &.icon-arrow-rotate {
      @include media-breakpoint-up(lg) {
        @include toRem(margin-top, 100);
      }

      .item-link {
        &:hover {
          color: $orange !important;
        }

        .icon {
          @include toRem(width, 20);
          @include toRem(height, 20);

          svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  &__phone {
    .icon {
      svg path {
        fill: $white;
      }
    }
  }

  &__social-medias-items-item {
    @include toRem(width, 27);
    @include toRem(height, 27);

    &:hover {
      svg {
        path {
          fill: $green_4;
        }
      }
    }

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;

      path {
        @include transition(all 225ms);
      }
    }
  }
}
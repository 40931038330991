//Breadcrumb
.breadcrumb {
  ul {
    li {
      position: relative;
      @include toRem(padding-right, 6);
      @include toRem(margin-right, 6);
      @include toRem(font-size, 12);

      &:last-child {
        padding: 0;
        margin: 0;

        &:after {
          content: none;
        }
      }

      &:after {
        content: '';
        position: absolute;
        @include toRem(top, 11);
        @include toRem(right, -1);
        @include toRem(width, 3);
        @include toRem(height, 3);
        background-color: $blue_1;
        border-radius: 50%;
      }
    }
  }
}
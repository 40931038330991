@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@700&family=Raleway:ital,wght@0,300;0,400;0,500;0,700;0,800;1,300&display=swap");
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.nav-link, a {
  color: black;
  text-decoration: none;
  -moz-transition: all 225ms;
  -o-transition: all 225ms;
  -webkit-transition: all 225ms;
  transition: all 225ms;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
}
.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #2DCEA3;
  transform-origin: bottom right;
  transition: transform 225ms ease-out;
}
.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.btn-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  color: #001646;
  font-weight: 700;
  outline: 0;
  padding: 0;
  text-decoration: none;
}
.btn-link > .icon {
  display: inline-flex;
  width: 1.25rem;
  height: 1.0625rem;
  -moz-transition: transform 600ms;
  -o-transition: transform 600ms;
  -webkit-transition: transform 600ms;
  transition: transform 600ms;
}
.btn-link > .icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.btn-link > .icon svg rect,
.btn-link > .icon svg path {
  -moz-transition: all 225ms;
  -o-transition: all 225ms;
  -webkit-transition: all 225ms;
  transition: all 225ms;
}
.btn-link:hover {
  color: #2DCEA3 !important;
}
.btn-link:hover > .icon {
  -webkit-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}
.btn-link:hover > .icon svg path {
  fill: #2DCEA3;
}
.btn-link-icon-circle > .icon {
  width: 3.125rem;
  height: 3.125rem;
}
.btn-link-icon-circle > .icon svg rect {
  fill: #2DCEA3;
}
.btn-link-icon-circle:hover > .icon svg path {
  fill: #fff !important;
}
.btn-link-icon-circle-orange > .icon svg rect {
  fill: #F06325;
}
.btn-link-icon-circle-orange:hover {
  color: #F06325 !important;
}
.btn-link-icon-circle-green-light > .icon svg rect {
  fill: #DFF8F1;
}
.btn-link-icon-circle-green-light > .icon svg path {
  fill: #2DCEA3;
}
.btn-link-icon-circle-green-light:hover > .icon svg rect {
  fill: #2DCEA3;
}
.btn-link-underline:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000000;
}

.btn-primary {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border-color: #fff;
  border-radius: 0;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  height: 4.375rem;
  -moz-transition: background-color 600ms, border-color 600ms;
  -o-transition: background-color 600ms, border-color 600ms;
  -webkit-transition: background-color 600ms, border-color 600ms;
  transition: background-color 600ms, border-color 600ms;
}
@media (max-width: 767.98px) {
  .btn-primary {
    width: 100%;
  }
}
.btn-primary-green {
  border-color: #198754;
  color: #198754;
}
.btn-primary-green > svg path {
  stroke: #198754;
  -moz-transition: all 600ms;
  -o-transition: all 600ms;
  -webkit-transition: all 600ms;
  transition: all 600ms;
}
.btn-primary:hover {
  background-color: #001646;
  border-color: #001646;
  color: #fff;
}
.btn-primary:hover > img,
.btn-primary:hover > svg {
  -webkit-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}
.btn-primary:hover > svg path {
  stroke: #fff;
}
.btn-primary > img,
.btn-primary > svg {
  display: inline-flex;
  -moz-transition: transform 600ms;
  -o-transition: transform 600ms;
  -webkit-transition: transform 600ms;
  transition: transform 600ms;
}
@media (min-width: 768px) {
  .btn-primary > img,
  .btn-primary > svg {
    margin-left: 8.125rem;
  }
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-20 {
  top: 20% !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-20 {
  bottom: 20% !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-20 {
  left: 20% !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-20 {
  right: 20% !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-85 {
  width: 85% !important;
}

.w-100 {
  width: 100% !important;
}

.w-120 {
  width: 120% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-45 {
  height: 45% !important;
}

.h-50 {
  height: 50% !important;
}

.h-55 {
  height: 55% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-85 {
  height: 85% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.4rem !important;
}

.m-3 {
  margin: 0.8rem !important;
}

.m-35 {
  margin: 1.25rem !important;
}

.m-4 {
  margin: 1.875rem !important;
}

.m-45 {
  margin: 2.25rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-55 {
  margin: 3.5rem !important;
}

.m-6 {
  margin: 3.85rem !important;
}

.m-65 {
  margin: 5rem !important;
}

.m-68 {
  margin: 5.5rem !important;
}

.m-7 {
  margin: 6.5rem !important;
}

.m-75 {
  margin: 7rem !important;
}

.m-8 {
  margin: 8rem !important;
}

.m-9 {
  margin: 10rem !important;
}

.m-95 {
  margin: 12rem !important;
}

.m-10 {
  margin: 14rem !important;
}

.m-11 {
  margin: 21rem !important;
}

.m-12 {
  margin: 16.5rem !important;
}

.m-13 {
  margin: 17rem !important;
}

.m-14 {
  margin: 18rem !important;
}

.m-15 {
  margin: 19rem !important;
}

.m-16 {
  margin: 20rem !important;
}

.m-17 {
  margin: 21rem !important;
}

.m-18 {
  margin: 22rem !important;
}

.m-19 {
  margin: 23rem !important;
}

.m-20 {
  margin: 24rem !important;
}

.m-21 {
  margin: 25rem !important;
}

.m-22 {
  margin: 26rem !important;
}

.m-23 {
  margin: 27rem !important;
}

.m-24 {
  margin: 28rem !important;
}

.m-25 {
  margin: 29rem !important;
}

.m-26 {
  margin: 30rem !important;
}

.m-27 {
  margin: 31rem !important;
}

.m-28 {
  margin: 32rem !important;
}

.m-29 {
  margin: 33rem !important;
}

.m-30 {
  margin: 34rem !important;
}

.m-nav {
  margin: 0.5rem !important;
}

.m-100 {
  margin: 6.25rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.4rem !important;
  margin-left: 0.4rem !important;
}

.mx-3 {
  margin-right: 0.8rem !important;
  margin-left: 0.8rem !important;
}

.mx-35 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-4 {
  margin-right: 1.875rem !important;
  margin-left: 1.875rem !important;
}

.mx-45 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-55 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-6 {
  margin-right: 3.85rem !important;
  margin-left: 3.85rem !important;
}

.mx-65 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-68 {
  margin-right: 5.5rem !important;
  margin-left: 5.5rem !important;
}

.mx-7 {
  margin-right: 6.5rem !important;
  margin-left: 6.5rem !important;
}

.mx-75 {
  margin-right: 7rem !important;
  margin-left: 7rem !important;
}

.mx-8 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-9 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-95 {
  margin-right: 12rem !important;
  margin-left: 12rem !important;
}

.mx-10 {
  margin-right: 14rem !important;
  margin-left: 14rem !important;
}

.mx-11 {
  margin-right: 21rem !important;
  margin-left: 21rem !important;
}

.mx-12 {
  margin-right: 16.5rem !important;
  margin-left: 16.5rem !important;
}

.mx-13 {
  margin-right: 17rem !important;
  margin-left: 17rem !important;
}

.mx-14 {
  margin-right: 18rem !important;
  margin-left: 18rem !important;
}

.mx-15 {
  margin-right: 19rem !important;
  margin-left: 19rem !important;
}

.mx-16 {
  margin-right: 20rem !important;
  margin-left: 20rem !important;
}

.mx-17 {
  margin-right: 21rem !important;
  margin-left: 21rem !important;
}

.mx-18 {
  margin-right: 22rem !important;
  margin-left: 22rem !important;
}

.mx-19 {
  margin-right: 23rem !important;
  margin-left: 23rem !important;
}

.mx-20 {
  margin-right: 24rem !important;
  margin-left: 24rem !important;
}

.mx-21 {
  margin-right: 25rem !important;
  margin-left: 25rem !important;
}

.mx-22 {
  margin-right: 26rem !important;
  margin-left: 26rem !important;
}

.mx-23 {
  margin-right: 27rem !important;
  margin-left: 27rem !important;
}

.mx-24 {
  margin-right: 28rem !important;
  margin-left: 28rem !important;
}

.mx-25 {
  margin-right: 29rem !important;
  margin-left: 29rem !important;
}

.mx-26 {
  margin-right: 30rem !important;
  margin-left: 30rem !important;
}

.mx-27 {
  margin-right: 31rem !important;
  margin-left: 31rem !important;
}

.mx-28 {
  margin-right: 32rem !important;
  margin-left: 32rem !important;
}

.mx-29 {
  margin-right: 33rem !important;
  margin-left: 33rem !important;
}

.mx-30 {
  margin-right: 34rem !important;
  margin-left: 34rem !important;
}

.mx-nav {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-100 {
  margin-right: 6.25rem !important;
  margin-left: 6.25rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.4rem !important;
  margin-bottom: 0.4rem !important;
}

.my-3 {
  margin-top: 0.8rem !important;
  margin-bottom: 0.8rem !important;
}

.my-35 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-4 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.my-45 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-55 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-6 {
  margin-top: 3.85rem !important;
  margin-bottom: 3.85rem !important;
}

.my-65 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-68 {
  margin-top: 5.5rem !important;
  margin-bottom: 5.5rem !important;
}

.my-7 {
  margin-top: 6.5rem !important;
  margin-bottom: 6.5rem !important;
}

.my-75 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.my-8 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-9 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-95 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.my-10 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.my-11 {
  margin-top: 21rem !important;
  margin-bottom: 21rem !important;
}

.my-12 {
  margin-top: 16.5rem !important;
  margin-bottom: 16.5rem !important;
}

.my-13 {
  margin-top: 17rem !important;
  margin-bottom: 17rem !important;
}

.my-14 {
  margin-top: 18rem !important;
  margin-bottom: 18rem !important;
}

.my-15 {
  margin-top: 19rem !important;
  margin-bottom: 19rem !important;
}

.my-16 {
  margin-top: 20rem !important;
  margin-bottom: 20rem !important;
}

.my-17 {
  margin-top: 21rem !important;
  margin-bottom: 21rem !important;
}

.my-18 {
  margin-top: 22rem !important;
  margin-bottom: 22rem !important;
}

.my-19 {
  margin-top: 23rem !important;
  margin-bottom: 23rem !important;
}

.my-20 {
  margin-top: 24rem !important;
  margin-bottom: 24rem !important;
}

.my-21 {
  margin-top: 25rem !important;
  margin-bottom: 25rem !important;
}

.my-22 {
  margin-top: 26rem !important;
  margin-bottom: 26rem !important;
}

.my-23 {
  margin-top: 27rem !important;
  margin-bottom: 27rem !important;
}

.my-24 {
  margin-top: 28rem !important;
  margin-bottom: 28rem !important;
}

.my-25 {
  margin-top: 29rem !important;
  margin-bottom: 29rem !important;
}

.my-26 {
  margin-top: 30rem !important;
  margin-bottom: 30rem !important;
}

.my-27 {
  margin-top: 31rem !important;
  margin-bottom: 31rem !important;
}

.my-28 {
  margin-top: 32rem !important;
  margin-bottom: 32rem !important;
}

.my-29 {
  margin-top: 33rem !important;
  margin-bottom: 33rem !important;
}

.my-30 {
  margin-top: 34rem !important;
  margin-bottom: 34rem !important;
}

.my-nav {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-100 {
  margin-top: 6.25rem !important;
  margin-bottom: 6.25rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.4rem !important;
}

.mt-3 {
  margin-top: 0.8rem !important;
}

.mt-35 {
  margin-top: 1.25rem !important;
}

.mt-4 {
  margin-top: 1.875rem !important;
}

.mt-45 {
  margin-top: 2.25rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-55 {
  margin-top: 3.5rem !important;
}

.mt-6 {
  margin-top: 3.85rem !important;
}

.mt-65 {
  margin-top: 5rem !important;
}

.mt-68 {
  margin-top: 5.5rem !important;
}

.mt-7 {
  margin-top: 6.5rem !important;
}

.mt-75 {
  margin-top: 7rem !important;
}

.mt-8 {
  margin-top: 8rem !important;
}

.mt-9 {
  margin-top: 10rem !important;
}

.mt-95 {
  margin-top: 12rem !important;
}

.mt-10 {
  margin-top: 14rem !important;
}

.mt-11 {
  margin-top: 21rem !important;
}

.mt-12 {
  margin-top: 16.5rem !important;
}

.mt-13 {
  margin-top: 17rem !important;
}

.mt-14 {
  margin-top: 18rem !important;
}

.mt-15 {
  margin-top: 19rem !important;
}

.mt-16 {
  margin-top: 20rem !important;
}

.mt-17 {
  margin-top: 21rem !important;
}

.mt-18 {
  margin-top: 22rem !important;
}

.mt-19 {
  margin-top: 23rem !important;
}

.mt-20 {
  margin-top: 24rem !important;
}

.mt-21 {
  margin-top: 25rem !important;
}

.mt-22 {
  margin-top: 26rem !important;
}

.mt-23 {
  margin-top: 27rem !important;
}

.mt-24 {
  margin-top: 28rem !important;
}

.mt-25 {
  margin-top: 29rem !important;
}

.mt-26 {
  margin-top: 30rem !important;
}

.mt-27 {
  margin-top: 31rem !important;
}

.mt-28 {
  margin-top: 32rem !important;
}

.mt-29 {
  margin-top: 33rem !important;
}

.mt-30 {
  margin-top: 34rem !important;
}

.mt-nav {
  margin-top: 0.5rem !important;
}

.mt-100 {
  margin-top: 6.25rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.4rem !important;
}

.me-3 {
  margin-right: 0.8rem !important;
}

.me-35 {
  margin-right: 1.25rem !important;
}

.me-4 {
  margin-right: 1.875rem !important;
}

.me-45 {
  margin-right: 2.25rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-55 {
  margin-right: 3.5rem !important;
}

.me-6 {
  margin-right: 3.85rem !important;
}

.me-65 {
  margin-right: 5rem !important;
}

.me-68 {
  margin-right: 5.5rem !important;
}

.me-7 {
  margin-right: 6.5rem !important;
}

.me-75 {
  margin-right: 7rem !important;
}

.me-8 {
  margin-right: 8rem !important;
}

.me-9 {
  margin-right: 10rem !important;
}

.me-95 {
  margin-right: 12rem !important;
}

.me-10 {
  margin-right: 14rem !important;
}

.me-11 {
  margin-right: 21rem !important;
}

.me-12 {
  margin-right: 16.5rem !important;
}

.me-13 {
  margin-right: 17rem !important;
}

.me-14 {
  margin-right: 18rem !important;
}

.me-15 {
  margin-right: 19rem !important;
}

.me-16 {
  margin-right: 20rem !important;
}

.me-17 {
  margin-right: 21rem !important;
}

.me-18 {
  margin-right: 22rem !important;
}

.me-19 {
  margin-right: 23rem !important;
}

.me-20 {
  margin-right: 24rem !important;
}

.me-21 {
  margin-right: 25rem !important;
}

.me-22 {
  margin-right: 26rem !important;
}

.me-23 {
  margin-right: 27rem !important;
}

.me-24 {
  margin-right: 28rem !important;
}

.me-25 {
  margin-right: 29rem !important;
}

.me-26 {
  margin-right: 30rem !important;
}

.me-27 {
  margin-right: 31rem !important;
}

.me-28 {
  margin-right: 32rem !important;
}

.me-29 {
  margin-right: 33rem !important;
}

.me-30 {
  margin-right: 34rem !important;
}

.me-nav {
  margin-right: 0.5rem !important;
}

.me-100 {
  margin-right: 6.25rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.4rem !important;
}

.mb-3 {
  margin-bottom: 0.8rem !important;
}

.mb-35 {
  margin-bottom: 1.25rem !important;
}

.mb-4 {
  margin-bottom: 1.875rem !important;
}

.mb-45 {
  margin-bottom: 2.25rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-55 {
  margin-bottom: 3.5rem !important;
}

.mb-6 {
  margin-bottom: 3.85rem !important;
}

.mb-65 {
  margin-bottom: 5rem !important;
}

.mb-68 {
  margin-bottom: 5.5rem !important;
}

.mb-7 {
  margin-bottom: 6.5rem !important;
}

.mb-75 {
  margin-bottom: 7rem !important;
}

.mb-8 {
  margin-bottom: 8rem !important;
}

.mb-9 {
  margin-bottom: 10rem !important;
}

.mb-95 {
  margin-bottom: 12rem !important;
}

.mb-10 {
  margin-bottom: 14rem !important;
}

.mb-11 {
  margin-bottom: 21rem !important;
}

.mb-12 {
  margin-bottom: 16.5rem !important;
}

.mb-13 {
  margin-bottom: 17rem !important;
}

.mb-14 {
  margin-bottom: 18rem !important;
}

.mb-15 {
  margin-bottom: 19rem !important;
}

.mb-16 {
  margin-bottom: 20rem !important;
}

.mb-17 {
  margin-bottom: 21rem !important;
}

.mb-18 {
  margin-bottom: 22rem !important;
}

.mb-19 {
  margin-bottom: 23rem !important;
}

.mb-20 {
  margin-bottom: 24rem !important;
}

.mb-21 {
  margin-bottom: 25rem !important;
}

.mb-22 {
  margin-bottom: 26rem !important;
}

.mb-23 {
  margin-bottom: 27rem !important;
}

.mb-24 {
  margin-bottom: 28rem !important;
}

.mb-25 {
  margin-bottom: 29rem !important;
}

.mb-26 {
  margin-bottom: 30rem !important;
}

.mb-27 {
  margin-bottom: 31rem !important;
}

.mb-28 {
  margin-bottom: 32rem !important;
}

.mb-29 {
  margin-bottom: 33rem !important;
}

.mb-30 {
  margin-bottom: 34rem !important;
}

.mb-nav {
  margin-bottom: 0.5rem !important;
}

.mb-100 {
  margin-bottom: 6.25rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.4rem !important;
}

.ms-3 {
  margin-left: 0.8rem !important;
}

.ms-35 {
  margin-left: 1.25rem !important;
}

.ms-4 {
  margin-left: 1.875rem !important;
}

.ms-45 {
  margin-left: 2.25rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-55 {
  margin-left: 3.5rem !important;
}

.ms-6 {
  margin-left: 3.85rem !important;
}

.ms-65 {
  margin-left: 5rem !important;
}

.ms-68 {
  margin-left: 5.5rem !important;
}

.ms-7 {
  margin-left: 6.5rem !important;
}

.ms-75 {
  margin-left: 7rem !important;
}

.ms-8 {
  margin-left: 8rem !important;
}

.ms-9 {
  margin-left: 10rem !important;
}

.ms-95 {
  margin-left: 12rem !important;
}

.ms-10 {
  margin-left: 14rem !important;
}

.ms-11 {
  margin-left: 21rem !important;
}

.ms-12 {
  margin-left: 16.5rem !important;
}

.ms-13 {
  margin-left: 17rem !important;
}

.ms-14 {
  margin-left: 18rem !important;
}

.ms-15 {
  margin-left: 19rem !important;
}

.ms-16 {
  margin-left: 20rem !important;
}

.ms-17 {
  margin-left: 21rem !important;
}

.ms-18 {
  margin-left: 22rem !important;
}

.ms-19 {
  margin-left: 23rem !important;
}

.ms-20 {
  margin-left: 24rem !important;
}

.ms-21 {
  margin-left: 25rem !important;
}

.ms-22 {
  margin-left: 26rem !important;
}

.ms-23 {
  margin-left: 27rem !important;
}

.ms-24 {
  margin-left: 28rem !important;
}

.ms-25 {
  margin-left: 29rem !important;
}

.ms-26 {
  margin-left: 30rem !important;
}

.ms-27 {
  margin-left: 31rem !important;
}

.ms-28 {
  margin-left: 32rem !important;
}

.ms-29 {
  margin-left: 33rem !important;
}

.ms-30 {
  margin-left: 34rem !important;
}

.ms-nav {
  margin-left: 0.5rem !important;
}

.ms-100 {
  margin-left: 6.25rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.4rem !important;
}

.p-3 {
  padding: 0.8rem !important;
}

.p-35 {
  padding: 1.25rem !important;
}

.p-4 {
  padding: 1.875rem !important;
}

.p-45 {
  padding: 2.25rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-55 {
  padding: 3.5rem !important;
}

.p-6 {
  padding: 3.85rem !important;
}

.p-65 {
  padding: 5rem !important;
}

.p-68 {
  padding: 5.5rem !important;
}

.p-7 {
  padding: 6.5rem !important;
}

.p-75 {
  padding: 7rem !important;
}

.p-8 {
  padding: 8rem !important;
}

.p-9 {
  padding: 10rem !important;
}

.p-95 {
  padding: 12rem !important;
}

.p-10 {
  padding: 14rem !important;
}

.p-11 {
  padding: 21rem !important;
}

.p-12 {
  padding: 16.5rem !important;
}

.p-13 {
  padding: 17rem !important;
}

.p-14 {
  padding: 18rem !important;
}

.p-15 {
  padding: 19rem !important;
}

.p-16 {
  padding: 20rem !important;
}

.p-17 {
  padding: 21rem !important;
}

.p-18 {
  padding: 22rem !important;
}

.p-19 {
  padding: 23rem !important;
}

.p-20 {
  padding: 24rem !important;
}

.p-21 {
  padding: 25rem !important;
}

.p-22 {
  padding: 26rem !important;
}

.p-23 {
  padding: 27rem !important;
}

.p-24 {
  padding: 28rem !important;
}

.p-25 {
  padding: 29rem !important;
}

.p-26 {
  padding: 30rem !important;
}

.p-27 {
  padding: 31rem !important;
}

.p-28 {
  padding: 32rem !important;
}

.p-29 {
  padding: 33rem !important;
}

.p-30 {
  padding: 34rem !important;
}

.p-nav {
  padding: 0.5rem !important;
}

.p-100 {
  padding: 6.25rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.4rem !important;
  padding-left: 0.4rem !important;
}

.px-3 {
  padding-right: 0.8rem !important;
  padding-left: 0.8rem !important;
}

.px-35 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-4 {
  padding-right: 1.875rem !important;
  padding-left: 1.875rem !important;
}

.px-45 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-55 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-6 {
  padding-right: 3.85rem !important;
  padding-left: 3.85rem !important;
}

.px-65 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-68 {
  padding-right: 5.5rem !important;
  padding-left: 5.5rem !important;
}

.px-7 {
  padding-right: 6.5rem !important;
  padding-left: 6.5rem !important;
}

.px-75 {
  padding-right: 7rem !important;
  padding-left: 7rem !important;
}

.px-8 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.px-9 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-95 {
  padding-right: 12rem !important;
  padding-left: 12rem !important;
}

.px-10 {
  padding-right: 14rem !important;
  padding-left: 14rem !important;
}

.px-11 {
  padding-right: 21rem !important;
  padding-left: 21rem !important;
}

.px-12 {
  padding-right: 16.5rem !important;
  padding-left: 16.5rem !important;
}

.px-13 {
  padding-right: 17rem !important;
  padding-left: 17rem !important;
}

.px-14 {
  padding-right: 18rem !important;
  padding-left: 18rem !important;
}

.px-15 {
  padding-right: 19rem !important;
  padding-left: 19rem !important;
}

.px-16 {
  padding-right: 20rem !important;
  padding-left: 20rem !important;
}

.px-17 {
  padding-right: 21rem !important;
  padding-left: 21rem !important;
}

.px-18 {
  padding-right: 22rem !important;
  padding-left: 22rem !important;
}

.px-19 {
  padding-right: 23rem !important;
  padding-left: 23rem !important;
}

.px-20 {
  padding-right: 24rem !important;
  padding-left: 24rem !important;
}

.px-21 {
  padding-right: 25rem !important;
  padding-left: 25rem !important;
}

.px-22 {
  padding-right: 26rem !important;
  padding-left: 26rem !important;
}

.px-23 {
  padding-right: 27rem !important;
  padding-left: 27rem !important;
}

.px-24 {
  padding-right: 28rem !important;
  padding-left: 28rem !important;
}

.px-25 {
  padding-right: 29rem !important;
  padding-left: 29rem !important;
}

.px-26 {
  padding-right: 30rem !important;
  padding-left: 30rem !important;
}

.px-27 {
  padding-right: 31rem !important;
  padding-left: 31rem !important;
}

.px-28 {
  padding-right: 32rem !important;
  padding-left: 32rem !important;
}

.px-29 {
  padding-right: 33rem !important;
  padding-left: 33rem !important;
}

.px-30 {
  padding-right: 34rem !important;
  padding-left: 34rem !important;
}

.px-nav {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-100 {
  padding-right: 6.25rem !important;
  padding-left: 6.25rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
}

.py-3 {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.py-35 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-4 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.py-45 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-55 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-6 {
  padding-top: 3.85rem !important;
  padding-bottom: 3.85rem !important;
}

.py-65 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-68 {
  padding-top: 5.5rem !important;
  padding-bottom: 5.5rem !important;
}

.py-7 {
  padding-top: 6.5rem !important;
  padding-bottom: 6.5rem !important;
}

.py-75 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.py-8 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-9 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-95 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.py-10 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.py-11 {
  padding-top: 21rem !important;
  padding-bottom: 21rem !important;
}

.py-12 {
  padding-top: 16.5rem !important;
  padding-bottom: 16.5rem !important;
}

.py-13 {
  padding-top: 17rem !important;
  padding-bottom: 17rem !important;
}

.py-14 {
  padding-top: 18rem !important;
  padding-bottom: 18rem !important;
}

.py-15 {
  padding-top: 19rem !important;
  padding-bottom: 19rem !important;
}

.py-16 {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important;
}

.py-17 {
  padding-top: 21rem !important;
  padding-bottom: 21rem !important;
}

.py-18 {
  padding-top: 22rem !important;
  padding-bottom: 22rem !important;
}

.py-19 {
  padding-top: 23rem !important;
  padding-bottom: 23rem !important;
}

.py-20 {
  padding-top: 24rem !important;
  padding-bottom: 24rem !important;
}

.py-21 {
  padding-top: 25rem !important;
  padding-bottom: 25rem !important;
}

.py-22 {
  padding-top: 26rem !important;
  padding-bottom: 26rem !important;
}

.py-23 {
  padding-top: 27rem !important;
  padding-bottom: 27rem !important;
}

.py-24 {
  padding-top: 28rem !important;
  padding-bottom: 28rem !important;
}

.py-25 {
  padding-top: 29rem !important;
  padding-bottom: 29rem !important;
}

.py-26 {
  padding-top: 30rem !important;
  padding-bottom: 30rem !important;
}

.py-27 {
  padding-top: 31rem !important;
  padding-bottom: 31rem !important;
}

.py-28 {
  padding-top: 32rem !important;
  padding-bottom: 32rem !important;
}

.py-29 {
  padding-top: 33rem !important;
  padding-bottom: 33rem !important;
}

.py-30 {
  padding-top: 34rem !important;
  padding-bottom: 34rem !important;
}

.py-nav {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-100 {
  padding-top: 6.25rem !important;
  padding-bottom: 6.25rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.4rem !important;
}

.pt-3 {
  padding-top: 0.8rem !important;
}

.pt-35 {
  padding-top: 1.25rem !important;
}

.pt-4 {
  padding-top: 1.875rem !important;
}

.pt-45 {
  padding-top: 2.25rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-55 {
  padding-top: 3.5rem !important;
}

.pt-6 {
  padding-top: 3.85rem !important;
}

.pt-65 {
  padding-top: 5rem !important;
}

.pt-68 {
  padding-top: 5.5rem !important;
}

.pt-7 {
  padding-top: 6.5rem !important;
}

.pt-75 {
  padding-top: 7rem !important;
}

.pt-8 {
  padding-top: 8rem !important;
}

.pt-9 {
  padding-top: 10rem !important;
}

.pt-95 {
  padding-top: 12rem !important;
}

.pt-10 {
  padding-top: 14rem !important;
}

.pt-11 {
  padding-top: 21rem !important;
}

.pt-12 {
  padding-top: 16.5rem !important;
}

.pt-13 {
  padding-top: 17rem !important;
}

.pt-14 {
  padding-top: 18rem !important;
}

.pt-15 {
  padding-top: 19rem !important;
}

.pt-16 {
  padding-top: 20rem !important;
}

.pt-17 {
  padding-top: 21rem !important;
}

.pt-18 {
  padding-top: 22rem !important;
}

.pt-19 {
  padding-top: 23rem !important;
}

.pt-20 {
  padding-top: 24rem !important;
}

.pt-21 {
  padding-top: 25rem !important;
}

.pt-22 {
  padding-top: 26rem !important;
}

.pt-23 {
  padding-top: 27rem !important;
}

.pt-24 {
  padding-top: 28rem !important;
}

.pt-25 {
  padding-top: 29rem !important;
}

.pt-26 {
  padding-top: 30rem !important;
}

.pt-27 {
  padding-top: 31rem !important;
}

.pt-28 {
  padding-top: 32rem !important;
}

.pt-29 {
  padding-top: 33rem !important;
}

.pt-30 {
  padding-top: 34rem !important;
}

.pt-nav {
  padding-top: 0.5rem !important;
}

.pt-100 {
  padding-top: 6.25rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.4rem !important;
}

.pe-3 {
  padding-right: 0.8rem !important;
}

.pe-35 {
  padding-right: 1.25rem !important;
}

.pe-4 {
  padding-right: 1.875rem !important;
}

.pe-45 {
  padding-right: 2.25rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-55 {
  padding-right: 3.5rem !important;
}

.pe-6 {
  padding-right: 3.85rem !important;
}

.pe-65 {
  padding-right: 5rem !important;
}

.pe-68 {
  padding-right: 5.5rem !important;
}

.pe-7 {
  padding-right: 6.5rem !important;
}

.pe-75 {
  padding-right: 7rem !important;
}

.pe-8 {
  padding-right: 8rem !important;
}

.pe-9 {
  padding-right: 10rem !important;
}

.pe-95 {
  padding-right: 12rem !important;
}

.pe-10 {
  padding-right: 14rem !important;
}

.pe-11 {
  padding-right: 21rem !important;
}

.pe-12 {
  padding-right: 16.5rem !important;
}

.pe-13 {
  padding-right: 17rem !important;
}

.pe-14 {
  padding-right: 18rem !important;
}

.pe-15 {
  padding-right: 19rem !important;
}

.pe-16 {
  padding-right: 20rem !important;
}

.pe-17 {
  padding-right: 21rem !important;
}

.pe-18 {
  padding-right: 22rem !important;
}

.pe-19 {
  padding-right: 23rem !important;
}

.pe-20 {
  padding-right: 24rem !important;
}

.pe-21 {
  padding-right: 25rem !important;
}

.pe-22 {
  padding-right: 26rem !important;
}

.pe-23 {
  padding-right: 27rem !important;
}

.pe-24 {
  padding-right: 28rem !important;
}

.pe-25 {
  padding-right: 29rem !important;
}

.pe-26 {
  padding-right: 30rem !important;
}

.pe-27 {
  padding-right: 31rem !important;
}

.pe-28 {
  padding-right: 32rem !important;
}

.pe-29 {
  padding-right: 33rem !important;
}

.pe-30 {
  padding-right: 34rem !important;
}

.pe-nav {
  padding-right: 0.5rem !important;
}

.pe-100 {
  padding-right: 6.25rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.4rem !important;
}

.pb-3 {
  padding-bottom: 0.8rem !important;
}

.pb-35 {
  padding-bottom: 1.25rem !important;
}

.pb-4 {
  padding-bottom: 1.875rem !important;
}

.pb-45 {
  padding-bottom: 2.25rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-55 {
  padding-bottom: 3.5rem !important;
}

.pb-6 {
  padding-bottom: 3.85rem !important;
}

.pb-65 {
  padding-bottom: 5rem !important;
}

.pb-68 {
  padding-bottom: 5.5rem !important;
}

.pb-7 {
  padding-bottom: 6.5rem !important;
}

.pb-75 {
  padding-bottom: 7rem !important;
}

.pb-8 {
  padding-bottom: 8rem !important;
}

.pb-9 {
  padding-bottom: 10rem !important;
}

.pb-95 {
  padding-bottom: 12rem !important;
}

.pb-10 {
  padding-bottom: 14rem !important;
}

.pb-11 {
  padding-bottom: 21rem !important;
}

.pb-12 {
  padding-bottom: 16.5rem !important;
}

.pb-13 {
  padding-bottom: 17rem !important;
}

.pb-14 {
  padding-bottom: 18rem !important;
}

.pb-15 {
  padding-bottom: 19rem !important;
}

.pb-16 {
  padding-bottom: 20rem !important;
}

.pb-17 {
  padding-bottom: 21rem !important;
}

.pb-18 {
  padding-bottom: 22rem !important;
}

.pb-19 {
  padding-bottom: 23rem !important;
}

.pb-20 {
  padding-bottom: 24rem !important;
}

.pb-21 {
  padding-bottom: 25rem !important;
}

.pb-22 {
  padding-bottom: 26rem !important;
}

.pb-23 {
  padding-bottom: 27rem !important;
}

.pb-24 {
  padding-bottom: 28rem !important;
}

.pb-25 {
  padding-bottom: 29rem !important;
}

.pb-26 {
  padding-bottom: 30rem !important;
}

.pb-27 {
  padding-bottom: 31rem !important;
}

.pb-28 {
  padding-bottom: 32rem !important;
}

.pb-29 {
  padding-bottom: 33rem !important;
}

.pb-30 {
  padding-bottom: 34rem !important;
}

.pb-nav {
  padding-bottom: 0.5rem !important;
}

.pb-100 {
  padding-bottom: 6.25rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.4rem !important;
}

.ps-3 {
  padding-left: 0.8rem !important;
}

.ps-35 {
  padding-left: 1.25rem !important;
}

.ps-4 {
  padding-left: 1.875rem !important;
}

.ps-45 {
  padding-left: 2.25rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-55 {
  padding-left: 3.5rem !important;
}

.ps-6 {
  padding-left: 3.85rem !important;
}

.ps-65 {
  padding-left: 5rem !important;
}

.ps-68 {
  padding-left: 5.5rem !important;
}

.ps-7 {
  padding-left: 6.5rem !important;
}

.ps-75 {
  padding-left: 7rem !important;
}

.ps-8 {
  padding-left: 8rem !important;
}

.ps-9 {
  padding-left: 10rem !important;
}

.ps-95 {
  padding-left: 12rem !important;
}

.ps-10 {
  padding-left: 14rem !important;
}

.ps-11 {
  padding-left: 21rem !important;
}

.ps-12 {
  padding-left: 16.5rem !important;
}

.ps-13 {
  padding-left: 17rem !important;
}

.ps-14 {
  padding-left: 18rem !important;
}

.ps-15 {
  padding-left: 19rem !important;
}

.ps-16 {
  padding-left: 20rem !important;
}

.ps-17 {
  padding-left: 21rem !important;
}

.ps-18 {
  padding-left: 22rem !important;
}

.ps-19 {
  padding-left: 23rem !important;
}

.ps-20 {
  padding-left: 24rem !important;
}

.ps-21 {
  padding-left: 25rem !important;
}

.ps-22 {
  padding-left: 26rem !important;
}

.ps-23 {
  padding-left: 27rem !important;
}

.ps-24 {
  padding-left: 28rem !important;
}

.ps-25 {
  padding-left: 29rem !important;
}

.ps-26 {
  padding-left: 30rem !important;
}

.ps-27 {
  padding-left: 31rem !important;
}

.ps-28 {
  padding-left: 32rem !important;
}

.ps-29 {
  padding-left: 33rem !important;
}

.ps-30 {
  padding-left: 34rem !important;
}

.ps-nav {
  padding-left: 0.5rem !important;
}

.ps-100 {
  padding-left: 6.25rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.4rem !important;
}

.gap-3 {
  gap: 0.8rem !important;
}

.gap-35 {
  gap: 1.25rem !important;
}

.gap-4 {
  gap: 1.875rem !important;
}

.gap-45 {
  gap: 2.25rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-55 {
  gap: 3.5rem !important;
}

.gap-6 {
  gap: 3.85rem !important;
}

.gap-65 {
  gap: 5rem !important;
}

.gap-68 {
  gap: 5.5rem !important;
}

.gap-7 {
  gap: 6.5rem !important;
}

.gap-75 {
  gap: 7rem !important;
}

.gap-8 {
  gap: 8rem !important;
}

.gap-9 {
  gap: 10rem !important;
}

.gap-95 {
  gap: 12rem !important;
}

.gap-10 {
  gap: 14rem !important;
}

.gap-11 {
  gap: 21rem !important;
}

.gap-12 {
  gap: 16.5rem !important;
}

.gap-13 {
  gap: 17rem !important;
}

.gap-14 {
  gap: 18rem !important;
}

.gap-15 {
  gap: 19rem !important;
}

.gap-16 {
  gap: 20rem !important;
}

.gap-17 {
  gap: 21rem !important;
}

.gap-18 {
  gap: 22rem !important;
}

.gap-19 {
  gap: 23rem !important;
}

.gap-20 {
  gap: 24rem !important;
}

.gap-21 {
  gap: 25rem !important;
}

.gap-22 {
  gap: 26rem !important;
}

.gap-23 {
  gap: 27rem !important;
}

.gap-24 {
  gap: 28rem !important;
}

.gap-25 {
  gap: 29rem !important;
}

.gap-26 {
  gap: 30rem !important;
}

.gap-27 {
  gap: 31rem !important;
}

.gap-28 {
  gap: 32rem !important;
}

.gap-29 {
  gap: 33rem !important;
}

.gap-30 {
  gap: 34rem !important;
}

.gap-nav {
  gap: 0.5rem !important;
}

.gap-100 {
  gap: 6.25rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.4rem !important;
}

.row-gap-3 {
  row-gap: 0.8rem !important;
}

.row-gap-35 {
  row-gap: 1.25rem !important;
}

.row-gap-4 {
  row-gap: 1.875rem !important;
}

.row-gap-45 {
  row-gap: 2.25rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.row-gap-55 {
  row-gap: 3.5rem !important;
}

.row-gap-6 {
  row-gap: 3.85rem !important;
}

.row-gap-65 {
  row-gap: 5rem !important;
}

.row-gap-68 {
  row-gap: 5.5rem !important;
}

.row-gap-7 {
  row-gap: 6.5rem !important;
}

.row-gap-75 {
  row-gap: 7rem !important;
}

.row-gap-8 {
  row-gap: 8rem !important;
}

.row-gap-9 {
  row-gap: 10rem !important;
}

.row-gap-95 {
  row-gap: 12rem !important;
}

.row-gap-10 {
  row-gap: 14rem !important;
}

.row-gap-11 {
  row-gap: 21rem !important;
}

.row-gap-12 {
  row-gap: 16.5rem !important;
}

.row-gap-13 {
  row-gap: 17rem !important;
}

.row-gap-14 {
  row-gap: 18rem !important;
}

.row-gap-15 {
  row-gap: 19rem !important;
}

.row-gap-16 {
  row-gap: 20rem !important;
}

.row-gap-17 {
  row-gap: 21rem !important;
}

.row-gap-18 {
  row-gap: 22rem !important;
}

.row-gap-19 {
  row-gap: 23rem !important;
}

.row-gap-20 {
  row-gap: 24rem !important;
}

.row-gap-21 {
  row-gap: 25rem !important;
}

.row-gap-22 {
  row-gap: 26rem !important;
}

.row-gap-23 {
  row-gap: 27rem !important;
}

.row-gap-24 {
  row-gap: 28rem !important;
}

.row-gap-25 {
  row-gap: 29rem !important;
}

.row-gap-26 {
  row-gap: 30rem !important;
}

.row-gap-27 {
  row-gap: 31rem !important;
}

.row-gap-28 {
  row-gap: 32rem !important;
}

.row-gap-29 {
  row-gap: 33rem !important;
}

.row-gap-30 {
  row-gap: 34rem !important;
}

.row-gap-nav {
  row-gap: 0.5rem !important;
}

.row-gap-100 {
  row-gap: 6.25rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.4rem !important;
}

.column-gap-3 {
  column-gap: 0.8rem !important;
}

.column-gap-35 {
  column-gap: 1.25rem !important;
}

.column-gap-4 {
  column-gap: 1.875rem !important;
}

.column-gap-45 {
  column-gap: 2.25rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.column-gap-55 {
  column-gap: 3.5rem !important;
}

.column-gap-6 {
  column-gap: 3.85rem !important;
}

.column-gap-65 {
  column-gap: 5rem !important;
}

.column-gap-68 {
  column-gap: 5.5rem !important;
}

.column-gap-7 {
  column-gap: 6.5rem !important;
}

.column-gap-75 {
  column-gap: 7rem !important;
}

.column-gap-8 {
  column-gap: 8rem !important;
}

.column-gap-9 {
  column-gap: 10rem !important;
}

.column-gap-95 {
  column-gap: 12rem !important;
}

.column-gap-10 {
  column-gap: 14rem !important;
}

.column-gap-11 {
  column-gap: 21rem !important;
}

.column-gap-12 {
  column-gap: 16.5rem !important;
}

.column-gap-13 {
  column-gap: 17rem !important;
}

.column-gap-14 {
  column-gap: 18rem !important;
}

.column-gap-15 {
  column-gap: 19rem !important;
}

.column-gap-16 {
  column-gap: 20rem !important;
}

.column-gap-17 {
  column-gap: 21rem !important;
}

.column-gap-18 {
  column-gap: 22rem !important;
}

.column-gap-19 {
  column-gap: 23rem !important;
}

.column-gap-20 {
  column-gap: 24rem !important;
}

.column-gap-21 {
  column-gap: 25rem !important;
}

.column-gap-22 {
  column-gap: 26rem !important;
}

.column-gap-23 {
  column-gap: 27rem !important;
}

.column-gap-24 {
  column-gap: 28rem !important;
}

.column-gap-25 {
  column-gap: 29rem !important;
}

.column-gap-26 {
  column-gap: 30rem !important;
}

.column-gap-27 {
  column-gap: 31rem !important;
}

.column-gap-28 {
  column-gap: 32rem !important;
}

.column-gap-29 {
  column-gap: 33rem !important;
}

.column-gap-30 {
  column-gap: 34rem !important;
}

.column-gap-nav {
  column-gap: 0.5rem !important;
}

.column-gap-100 {
  column-gap: 6.25rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.45 !important;
}

.lh-base {
  line-height: 1.65 !important;
}

.lh-lg {
  line-height: 1.65 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-80 {
  --bs-text-opacity: 0.8;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.4rem !important;
  }
  .m-sm-3 {
    margin: 0.8rem !important;
  }
  .m-sm-35 {
    margin: 1.25rem !important;
  }
  .m-sm-4 {
    margin: 1.875rem !important;
  }
  .m-sm-45 {
    margin: 2.25rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-55 {
    margin: 3.5rem !important;
  }
  .m-sm-6 {
    margin: 3.85rem !important;
  }
  .m-sm-65 {
    margin: 5rem !important;
  }
  .m-sm-68 {
    margin: 5.5rem !important;
  }
  .m-sm-7 {
    margin: 6.5rem !important;
  }
  .m-sm-75 {
    margin: 7rem !important;
  }
  .m-sm-8 {
    margin: 8rem !important;
  }
  .m-sm-9 {
    margin: 10rem !important;
  }
  .m-sm-95 {
    margin: 12rem !important;
  }
  .m-sm-10 {
    margin: 14rem !important;
  }
  .m-sm-11 {
    margin: 21rem !important;
  }
  .m-sm-12 {
    margin: 16.5rem !important;
  }
  .m-sm-13 {
    margin: 17rem !important;
  }
  .m-sm-14 {
    margin: 18rem !important;
  }
  .m-sm-15 {
    margin: 19rem !important;
  }
  .m-sm-16 {
    margin: 20rem !important;
  }
  .m-sm-17 {
    margin: 21rem !important;
  }
  .m-sm-18 {
    margin: 22rem !important;
  }
  .m-sm-19 {
    margin: 23rem !important;
  }
  .m-sm-20 {
    margin: 24rem !important;
  }
  .m-sm-21 {
    margin: 25rem !important;
  }
  .m-sm-22 {
    margin: 26rem !important;
  }
  .m-sm-23 {
    margin: 27rem !important;
  }
  .m-sm-24 {
    margin: 28rem !important;
  }
  .m-sm-25 {
    margin: 29rem !important;
  }
  .m-sm-26 {
    margin: 30rem !important;
  }
  .m-sm-27 {
    margin: 31rem !important;
  }
  .m-sm-28 {
    margin: 32rem !important;
  }
  .m-sm-29 {
    margin: 33rem !important;
  }
  .m-sm-30 {
    margin: 34rem !important;
  }
  .m-sm-nav {
    margin: 0.5rem !important;
  }
  .m-sm-100 {
    margin: 6.25rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important;
  }
  .mx-sm-3 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important;
  }
  .mx-sm-35 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-sm-45 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-55 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-sm-6 {
    margin-right: 3.85rem !important;
    margin-left: 3.85rem !important;
  }
  .mx-sm-65 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-68 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-sm-7 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-sm-75 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-sm-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-sm-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-sm-95 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-sm-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-sm-11 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-sm-12 {
    margin-right: 16.5rem !important;
    margin-left: 16.5rem !important;
  }
  .mx-sm-13 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-sm-14 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-sm-15 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-sm-16 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-sm-17 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-sm-18 {
    margin-right: 22rem !important;
    margin-left: 22rem !important;
  }
  .mx-sm-19 {
    margin-right: 23rem !important;
    margin-left: 23rem !important;
  }
  .mx-sm-20 {
    margin-right: 24rem !important;
    margin-left: 24rem !important;
  }
  .mx-sm-21 {
    margin-right: 25rem !important;
    margin-left: 25rem !important;
  }
  .mx-sm-22 {
    margin-right: 26rem !important;
    margin-left: 26rem !important;
  }
  .mx-sm-23 {
    margin-right: 27rem !important;
    margin-left: 27rem !important;
  }
  .mx-sm-24 {
    margin-right: 28rem !important;
    margin-left: 28rem !important;
  }
  .mx-sm-25 {
    margin-right: 29rem !important;
    margin-left: 29rem !important;
  }
  .mx-sm-26 {
    margin-right: 30rem !important;
    margin-left: 30rem !important;
  }
  .mx-sm-27 {
    margin-right: 31rem !important;
    margin-left: 31rem !important;
  }
  .mx-sm-28 {
    margin-right: 32rem !important;
    margin-left: 32rem !important;
  }
  .mx-sm-29 {
    margin-right: 33rem !important;
    margin-left: 33rem !important;
  }
  .mx-sm-30 {
    margin-right: 34rem !important;
    margin-left: 34rem !important;
  }
  .mx-sm-nav {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  .my-sm-3 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important;
  }
  .my-sm-35 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-sm-45 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-55 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-sm-6 {
    margin-top: 3.85rem !important;
    margin-bottom: 3.85rem !important;
  }
  .my-sm-65 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-68 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-sm-7 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-sm-75 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-sm-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-sm-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-sm-95 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-sm-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-sm-11 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-sm-12 {
    margin-top: 16.5rem !important;
    margin-bottom: 16.5rem !important;
  }
  .my-sm-13 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-sm-14 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-sm-15 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-sm-16 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-sm-17 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-sm-18 {
    margin-top: 22rem !important;
    margin-bottom: 22rem !important;
  }
  .my-sm-19 {
    margin-top: 23rem !important;
    margin-bottom: 23rem !important;
  }
  .my-sm-20 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }
  .my-sm-21 {
    margin-top: 25rem !important;
    margin-bottom: 25rem !important;
  }
  .my-sm-22 {
    margin-top: 26rem !important;
    margin-bottom: 26rem !important;
  }
  .my-sm-23 {
    margin-top: 27rem !important;
    margin-bottom: 27rem !important;
  }
  .my-sm-24 {
    margin-top: 28rem !important;
    margin-bottom: 28rem !important;
  }
  .my-sm-25 {
    margin-top: 29rem !important;
    margin-bottom: 29rem !important;
  }
  .my-sm-26 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important;
  }
  .my-sm-27 {
    margin-top: 31rem !important;
    margin-bottom: 31rem !important;
  }
  .my-sm-28 {
    margin-top: 32rem !important;
    margin-bottom: 32rem !important;
  }
  .my-sm-29 {
    margin-top: 33rem !important;
    margin-bottom: 33rem !important;
  }
  .my-sm-30 {
    margin-top: 34rem !important;
    margin-bottom: 34rem !important;
  }
  .my-sm-nav {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.4rem !important;
  }
  .mt-sm-3 {
    margin-top: 0.8rem !important;
  }
  .mt-sm-35 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.875rem !important;
  }
  .mt-sm-45 {
    margin-top: 2.25rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-55 {
    margin-top: 3.5rem !important;
  }
  .mt-sm-6 {
    margin-top: 3.85rem !important;
  }
  .mt-sm-65 {
    margin-top: 5rem !important;
  }
  .mt-sm-68 {
    margin-top: 5.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 6.5rem !important;
  }
  .mt-sm-75 {
    margin-top: 7rem !important;
  }
  .mt-sm-8 {
    margin-top: 8rem !important;
  }
  .mt-sm-9 {
    margin-top: 10rem !important;
  }
  .mt-sm-95 {
    margin-top: 12rem !important;
  }
  .mt-sm-10 {
    margin-top: 14rem !important;
  }
  .mt-sm-11 {
    margin-top: 21rem !important;
  }
  .mt-sm-12 {
    margin-top: 16.5rem !important;
  }
  .mt-sm-13 {
    margin-top: 17rem !important;
  }
  .mt-sm-14 {
    margin-top: 18rem !important;
  }
  .mt-sm-15 {
    margin-top: 19rem !important;
  }
  .mt-sm-16 {
    margin-top: 20rem !important;
  }
  .mt-sm-17 {
    margin-top: 21rem !important;
  }
  .mt-sm-18 {
    margin-top: 22rem !important;
  }
  .mt-sm-19 {
    margin-top: 23rem !important;
  }
  .mt-sm-20 {
    margin-top: 24rem !important;
  }
  .mt-sm-21 {
    margin-top: 25rem !important;
  }
  .mt-sm-22 {
    margin-top: 26rem !important;
  }
  .mt-sm-23 {
    margin-top: 27rem !important;
  }
  .mt-sm-24 {
    margin-top: 28rem !important;
  }
  .mt-sm-25 {
    margin-top: 29rem !important;
  }
  .mt-sm-26 {
    margin-top: 30rem !important;
  }
  .mt-sm-27 {
    margin-top: 31rem !important;
  }
  .mt-sm-28 {
    margin-top: 32rem !important;
  }
  .mt-sm-29 {
    margin-top: 33rem !important;
  }
  .mt-sm-30 {
    margin-top: 34rem !important;
  }
  .mt-sm-nav {
    margin-top: 0.5rem !important;
  }
  .mt-sm-100 {
    margin-top: 6.25rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.4rem !important;
  }
  .me-sm-3 {
    margin-right: 0.8rem !important;
  }
  .me-sm-35 {
    margin-right: 1.25rem !important;
  }
  .me-sm-4 {
    margin-right: 1.875rem !important;
  }
  .me-sm-45 {
    margin-right: 2.25rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-55 {
    margin-right: 3.5rem !important;
  }
  .me-sm-6 {
    margin-right: 3.85rem !important;
  }
  .me-sm-65 {
    margin-right: 5rem !important;
  }
  .me-sm-68 {
    margin-right: 5.5rem !important;
  }
  .me-sm-7 {
    margin-right: 6.5rem !important;
  }
  .me-sm-75 {
    margin-right: 7rem !important;
  }
  .me-sm-8 {
    margin-right: 8rem !important;
  }
  .me-sm-9 {
    margin-right: 10rem !important;
  }
  .me-sm-95 {
    margin-right: 12rem !important;
  }
  .me-sm-10 {
    margin-right: 14rem !important;
  }
  .me-sm-11 {
    margin-right: 21rem !important;
  }
  .me-sm-12 {
    margin-right: 16.5rem !important;
  }
  .me-sm-13 {
    margin-right: 17rem !important;
  }
  .me-sm-14 {
    margin-right: 18rem !important;
  }
  .me-sm-15 {
    margin-right: 19rem !important;
  }
  .me-sm-16 {
    margin-right: 20rem !important;
  }
  .me-sm-17 {
    margin-right: 21rem !important;
  }
  .me-sm-18 {
    margin-right: 22rem !important;
  }
  .me-sm-19 {
    margin-right: 23rem !important;
  }
  .me-sm-20 {
    margin-right: 24rem !important;
  }
  .me-sm-21 {
    margin-right: 25rem !important;
  }
  .me-sm-22 {
    margin-right: 26rem !important;
  }
  .me-sm-23 {
    margin-right: 27rem !important;
  }
  .me-sm-24 {
    margin-right: 28rem !important;
  }
  .me-sm-25 {
    margin-right: 29rem !important;
  }
  .me-sm-26 {
    margin-right: 30rem !important;
  }
  .me-sm-27 {
    margin-right: 31rem !important;
  }
  .me-sm-28 {
    margin-right: 32rem !important;
  }
  .me-sm-29 {
    margin-right: 33rem !important;
  }
  .me-sm-30 {
    margin-right: 34rem !important;
  }
  .me-sm-nav {
    margin-right: 0.5rem !important;
  }
  .me-sm-100 {
    margin-right: 6.25rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.4rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 0.8rem !important;
  }
  .mb-sm-35 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-sm-45 {
    margin-bottom: 2.25rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-55 {
    margin-bottom: 3.5rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 3.85rem !important;
  }
  .mb-sm-65 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-68 {
    margin-bottom: 5.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 6.5rem !important;
  }
  .mb-sm-75 {
    margin-bottom: 7rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 8rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 10rem !important;
  }
  .mb-sm-95 {
    margin-bottom: 12rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 14rem !important;
  }
  .mb-sm-11 {
    margin-bottom: 21rem !important;
  }
  .mb-sm-12 {
    margin-bottom: 16.5rem !important;
  }
  .mb-sm-13 {
    margin-bottom: 17rem !important;
  }
  .mb-sm-14 {
    margin-bottom: 18rem !important;
  }
  .mb-sm-15 {
    margin-bottom: 19rem !important;
  }
  .mb-sm-16 {
    margin-bottom: 20rem !important;
  }
  .mb-sm-17 {
    margin-bottom: 21rem !important;
  }
  .mb-sm-18 {
    margin-bottom: 22rem !important;
  }
  .mb-sm-19 {
    margin-bottom: 23rem !important;
  }
  .mb-sm-20 {
    margin-bottom: 24rem !important;
  }
  .mb-sm-21 {
    margin-bottom: 25rem !important;
  }
  .mb-sm-22 {
    margin-bottom: 26rem !important;
  }
  .mb-sm-23 {
    margin-bottom: 27rem !important;
  }
  .mb-sm-24 {
    margin-bottom: 28rem !important;
  }
  .mb-sm-25 {
    margin-bottom: 29rem !important;
  }
  .mb-sm-26 {
    margin-bottom: 30rem !important;
  }
  .mb-sm-27 {
    margin-bottom: 31rem !important;
  }
  .mb-sm-28 {
    margin-bottom: 32rem !important;
  }
  .mb-sm-29 {
    margin-bottom: 33rem !important;
  }
  .mb-sm-30 {
    margin-bottom: 34rem !important;
  }
  .mb-sm-nav {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.4rem !important;
  }
  .ms-sm-3 {
    margin-left: 0.8rem !important;
  }
  .ms-sm-35 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.875rem !important;
  }
  .ms-sm-45 {
    margin-left: 2.25rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-55 {
    margin-left: 3.5rem !important;
  }
  .ms-sm-6 {
    margin-left: 3.85rem !important;
  }
  .ms-sm-65 {
    margin-left: 5rem !important;
  }
  .ms-sm-68 {
    margin-left: 5.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 6.5rem !important;
  }
  .ms-sm-75 {
    margin-left: 7rem !important;
  }
  .ms-sm-8 {
    margin-left: 8rem !important;
  }
  .ms-sm-9 {
    margin-left: 10rem !important;
  }
  .ms-sm-95 {
    margin-left: 12rem !important;
  }
  .ms-sm-10 {
    margin-left: 14rem !important;
  }
  .ms-sm-11 {
    margin-left: 21rem !important;
  }
  .ms-sm-12 {
    margin-left: 16.5rem !important;
  }
  .ms-sm-13 {
    margin-left: 17rem !important;
  }
  .ms-sm-14 {
    margin-left: 18rem !important;
  }
  .ms-sm-15 {
    margin-left: 19rem !important;
  }
  .ms-sm-16 {
    margin-left: 20rem !important;
  }
  .ms-sm-17 {
    margin-left: 21rem !important;
  }
  .ms-sm-18 {
    margin-left: 22rem !important;
  }
  .ms-sm-19 {
    margin-left: 23rem !important;
  }
  .ms-sm-20 {
    margin-left: 24rem !important;
  }
  .ms-sm-21 {
    margin-left: 25rem !important;
  }
  .ms-sm-22 {
    margin-left: 26rem !important;
  }
  .ms-sm-23 {
    margin-left: 27rem !important;
  }
  .ms-sm-24 {
    margin-left: 28rem !important;
  }
  .ms-sm-25 {
    margin-left: 29rem !important;
  }
  .ms-sm-26 {
    margin-left: 30rem !important;
  }
  .ms-sm-27 {
    margin-left: 31rem !important;
  }
  .ms-sm-28 {
    margin-left: 32rem !important;
  }
  .ms-sm-29 {
    margin-left: 33rem !important;
  }
  .ms-sm-30 {
    margin-left: 34rem !important;
  }
  .ms-sm-nav {
    margin-left: 0.5rem !important;
  }
  .ms-sm-100 {
    margin-left: 6.25rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.4rem !important;
  }
  .p-sm-3 {
    padding: 0.8rem !important;
  }
  .p-sm-35 {
    padding: 1.25rem !important;
  }
  .p-sm-4 {
    padding: 1.875rem !important;
  }
  .p-sm-45 {
    padding: 2.25rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-55 {
    padding: 3.5rem !important;
  }
  .p-sm-6 {
    padding: 3.85rem !important;
  }
  .p-sm-65 {
    padding: 5rem !important;
  }
  .p-sm-68 {
    padding: 5.5rem !important;
  }
  .p-sm-7 {
    padding: 6.5rem !important;
  }
  .p-sm-75 {
    padding: 7rem !important;
  }
  .p-sm-8 {
    padding: 8rem !important;
  }
  .p-sm-9 {
    padding: 10rem !important;
  }
  .p-sm-95 {
    padding: 12rem !important;
  }
  .p-sm-10 {
    padding: 14rem !important;
  }
  .p-sm-11 {
    padding: 21rem !important;
  }
  .p-sm-12 {
    padding: 16.5rem !important;
  }
  .p-sm-13 {
    padding: 17rem !important;
  }
  .p-sm-14 {
    padding: 18rem !important;
  }
  .p-sm-15 {
    padding: 19rem !important;
  }
  .p-sm-16 {
    padding: 20rem !important;
  }
  .p-sm-17 {
    padding: 21rem !important;
  }
  .p-sm-18 {
    padding: 22rem !important;
  }
  .p-sm-19 {
    padding: 23rem !important;
  }
  .p-sm-20 {
    padding: 24rem !important;
  }
  .p-sm-21 {
    padding: 25rem !important;
  }
  .p-sm-22 {
    padding: 26rem !important;
  }
  .p-sm-23 {
    padding: 27rem !important;
  }
  .p-sm-24 {
    padding: 28rem !important;
  }
  .p-sm-25 {
    padding: 29rem !important;
  }
  .p-sm-26 {
    padding: 30rem !important;
  }
  .p-sm-27 {
    padding: 31rem !important;
  }
  .p-sm-28 {
    padding: 32rem !important;
  }
  .p-sm-29 {
    padding: 33rem !important;
  }
  .p-sm-30 {
    padding: 34rem !important;
  }
  .p-sm-nav {
    padding: 0.5rem !important;
  }
  .p-sm-100 {
    padding: 6.25rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
  }
  .px-sm-3 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important;
  }
  .px-sm-35 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-sm-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-sm-45 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-55 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-sm-6 {
    padding-right: 3.85rem !important;
    padding-left: 3.85rem !important;
  }
  .px-sm-65 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-68 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-sm-7 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-sm-75 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-sm-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-sm-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-sm-95 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-sm-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-sm-11 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-sm-12 {
    padding-right: 16.5rem !important;
    padding-left: 16.5rem !important;
  }
  .px-sm-13 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-sm-14 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-sm-15 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-sm-16 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-sm-17 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-sm-18 {
    padding-right: 22rem !important;
    padding-left: 22rem !important;
  }
  .px-sm-19 {
    padding-right: 23rem !important;
    padding-left: 23rem !important;
  }
  .px-sm-20 {
    padding-right: 24rem !important;
    padding-left: 24rem !important;
  }
  .px-sm-21 {
    padding-right: 25rem !important;
    padding-left: 25rem !important;
  }
  .px-sm-22 {
    padding-right: 26rem !important;
    padding-left: 26rem !important;
  }
  .px-sm-23 {
    padding-right: 27rem !important;
    padding-left: 27rem !important;
  }
  .px-sm-24 {
    padding-right: 28rem !important;
    padding-left: 28rem !important;
  }
  .px-sm-25 {
    padding-right: 29rem !important;
    padding-left: 29rem !important;
  }
  .px-sm-26 {
    padding-right: 30rem !important;
    padding-left: 30rem !important;
  }
  .px-sm-27 {
    padding-right: 31rem !important;
    padding-left: 31rem !important;
  }
  .px-sm-28 {
    padding-right: 32rem !important;
    padding-left: 32rem !important;
  }
  .px-sm-29 {
    padding-right: 33rem !important;
    padding-left: 33rem !important;
  }
  .px-sm-30 {
    padding-right: 34rem !important;
    padding-left: 34rem !important;
  }
  .px-sm-nav {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
  }
  .py-sm-3 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }
  .py-sm-35 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-sm-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-sm-45 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-55 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-sm-6 {
    padding-top: 3.85rem !important;
    padding-bottom: 3.85rem !important;
  }
  .py-sm-65 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-68 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-sm-7 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-sm-75 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-sm-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-sm-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-sm-95 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-sm-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-sm-11 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-sm-12 {
    padding-top: 16.5rem !important;
    padding-bottom: 16.5rem !important;
  }
  .py-sm-13 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-sm-14 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-sm-15 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-sm-16 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-sm-17 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-sm-18 {
    padding-top: 22rem !important;
    padding-bottom: 22rem !important;
  }
  .py-sm-19 {
    padding-top: 23rem !important;
    padding-bottom: 23rem !important;
  }
  .py-sm-20 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
  .py-sm-21 {
    padding-top: 25rem !important;
    padding-bottom: 25rem !important;
  }
  .py-sm-22 {
    padding-top: 26rem !important;
    padding-bottom: 26rem !important;
  }
  .py-sm-23 {
    padding-top: 27rem !important;
    padding-bottom: 27rem !important;
  }
  .py-sm-24 {
    padding-top: 28rem !important;
    padding-bottom: 28rem !important;
  }
  .py-sm-25 {
    padding-top: 29rem !important;
    padding-bottom: 29rem !important;
  }
  .py-sm-26 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important;
  }
  .py-sm-27 {
    padding-top: 31rem !important;
    padding-bottom: 31rem !important;
  }
  .py-sm-28 {
    padding-top: 32rem !important;
    padding-bottom: 32rem !important;
  }
  .py-sm-29 {
    padding-top: 33rem !important;
    padding-bottom: 33rem !important;
  }
  .py-sm-30 {
    padding-top: 34rem !important;
    padding-bottom: 34rem !important;
  }
  .py-sm-nav {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.4rem !important;
  }
  .pt-sm-3 {
    padding-top: 0.8rem !important;
  }
  .pt-sm-35 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.875rem !important;
  }
  .pt-sm-45 {
    padding-top: 2.25rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-55 {
    padding-top: 3.5rem !important;
  }
  .pt-sm-6 {
    padding-top: 3.85rem !important;
  }
  .pt-sm-65 {
    padding-top: 5rem !important;
  }
  .pt-sm-68 {
    padding-top: 5.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 6.5rem !important;
  }
  .pt-sm-75 {
    padding-top: 7rem !important;
  }
  .pt-sm-8 {
    padding-top: 8rem !important;
  }
  .pt-sm-9 {
    padding-top: 10rem !important;
  }
  .pt-sm-95 {
    padding-top: 12rem !important;
  }
  .pt-sm-10 {
    padding-top: 14rem !important;
  }
  .pt-sm-11 {
    padding-top: 21rem !important;
  }
  .pt-sm-12 {
    padding-top: 16.5rem !important;
  }
  .pt-sm-13 {
    padding-top: 17rem !important;
  }
  .pt-sm-14 {
    padding-top: 18rem !important;
  }
  .pt-sm-15 {
    padding-top: 19rem !important;
  }
  .pt-sm-16 {
    padding-top: 20rem !important;
  }
  .pt-sm-17 {
    padding-top: 21rem !important;
  }
  .pt-sm-18 {
    padding-top: 22rem !important;
  }
  .pt-sm-19 {
    padding-top: 23rem !important;
  }
  .pt-sm-20 {
    padding-top: 24rem !important;
  }
  .pt-sm-21 {
    padding-top: 25rem !important;
  }
  .pt-sm-22 {
    padding-top: 26rem !important;
  }
  .pt-sm-23 {
    padding-top: 27rem !important;
  }
  .pt-sm-24 {
    padding-top: 28rem !important;
  }
  .pt-sm-25 {
    padding-top: 29rem !important;
  }
  .pt-sm-26 {
    padding-top: 30rem !important;
  }
  .pt-sm-27 {
    padding-top: 31rem !important;
  }
  .pt-sm-28 {
    padding-top: 32rem !important;
  }
  .pt-sm-29 {
    padding-top: 33rem !important;
  }
  .pt-sm-30 {
    padding-top: 34rem !important;
  }
  .pt-sm-nav {
    padding-top: 0.5rem !important;
  }
  .pt-sm-100 {
    padding-top: 6.25rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.4rem !important;
  }
  .pe-sm-3 {
    padding-right: 0.8rem !important;
  }
  .pe-sm-35 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.875rem !important;
  }
  .pe-sm-45 {
    padding-right: 2.25rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-55 {
    padding-right: 3.5rem !important;
  }
  .pe-sm-6 {
    padding-right: 3.85rem !important;
  }
  .pe-sm-65 {
    padding-right: 5rem !important;
  }
  .pe-sm-68 {
    padding-right: 5.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 6.5rem !important;
  }
  .pe-sm-75 {
    padding-right: 7rem !important;
  }
  .pe-sm-8 {
    padding-right: 8rem !important;
  }
  .pe-sm-9 {
    padding-right: 10rem !important;
  }
  .pe-sm-95 {
    padding-right: 12rem !important;
  }
  .pe-sm-10 {
    padding-right: 14rem !important;
  }
  .pe-sm-11 {
    padding-right: 21rem !important;
  }
  .pe-sm-12 {
    padding-right: 16.5rem !important;
  }
  .pe-sm-13 {
    padding-right: 17rem !important;
  }
  .pe-sm-14 {
    padding-right: 18rem !important;
  }
  .pe-sm-15 {
    padding-right: 19rem !important;
  }
  .pe-sm-16 {
    padding-right: 20rem !important;
  }
  .pe-sm-17 {
    padding-right: 21rem !important;
  }
  .pe-sm-18 {
    padding-right: 22rem !important;
  }
  .pe-sm-19 {
    padding-right: 23rem !important;
  }
  .pe-sm-20 {
    padding-right: 24rem !important;
  }
  .pe-sm-21 {
    padding-right: 25rem !important;
  }
  .pe-sm-22 {
    padding-right: 26rem !important;
  }
  .pe-sm-23 {
    padding-right: 27rem !important;
  }
  .pe-sm-24 {
    padding-right: 28rem !important;
  }
  .pe-sm-25 {
    padding-right: 29rem !important;
  }
  .pe-sm-26 {
    padding-right: 30rem !important;
  }
  .pe-sm-27 {
    padding-right: 31rem !important;
  }
  .pe-sm-28 {
    padding-right: 32rem !important;
  }
  .pe-sm-29 {
    padding-right: 33rem !important;
  }
  .pe-sm-30 {
    padding-right: 34rem !important;
  }
  .pe-sm-nav {
    padding-right: 0.5rem !important;
  }
  .pe-sm-100 {
    padding-right: 6.25rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.4rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 0.8rem !important;
  }
  .pb-sm-35 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-sm-45 {
    padding-bottom: 2.25rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-55 {
    padding-bottom: 3.5rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 3.85rem !important;
  }
  .pb-sm-65 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-68 {
    padding-bottom: 5.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 6.5rem !important;
  }
  .pb-sm-75 {
    padding-bottom: 7rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 8rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 10rem !important;
  }
  .pb-sm-95 {
    padding-bottom: 12rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 14rem !important;
  }
  .pb-sm-11 {
    padding-bottom: 21rem !important;
  }
  .pb-sm-12 {
    padding-bottom: 16.5rem !important;
  }
  .pb-sm-13 {
    padding-bottom: 17rem !important;
  }
  .pb-sm-14 {
    padding-bottom: 18rem !important;
  }
  .pb-sm-15 {
    padding-bottom: 19rem !important;
  }
  .pb-sm-16 {
    padding-bottom: 20rem !important;
  }
  .pb-sm-17 {
    padding-bottom: 21rem !important;
  }
  .pb-sm-18 {
    padding-bottom: 22rem !important;
  }
  .pb-sm-19 {
    padding-bottom: 23rem !important;
  }
  .pb-sm-20 {
    padding-bottom: 24rem !important;
  }
  .pb-sm-21 {
    padding-bottom: 25rem !important;
  }
  .pb-sm-22 {
    padding-bottom: 26rem !important;
  }
  .pb-sm-23 {
    padding-bottom: 27rem !important;
  }
  .pb-sm-24 {
    padding-bottom: 28rem !important;
  }
  .pb-sm-25 {
    padding-bottom: 29rem !important;
  }
  .pb-sm-26 {
    padding-bottom: 30rem !important;
  }
  .pb-sm-27 {
    padding-bottom: 31rem !important;
  }
  .pb-sm-28 {
    padding-bottom: 32rem !important;
  }
  .pb-sm-29 {
    padding-bottom: 33rem !important;
  }
  .pb-sm-30 {
    padding-bottom: 34rem !important;
  }
  .pb-sm-nav {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-100 {
    padding-bottom: 6.25rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.4rem !important;
  }
  .ps-sm-3 {
    padding-left: 0.8rem !important;
  }
  .ps-sm-35 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.875rem !important;
  }
  .ps-sm-45 {
    padding-left: 2.25rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-55 {
    padding-left: 3.5rem !important;
  }
  .ps-sm-6 {
    padding-left: 3.85rem !important;
  }
  .ps-sm-65 {
    padding-left: 5rem !important;
  }
  .ps-sm-68 {
    padding-left: 5.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 6.5rem !important;
  }
  .ps-sm-75 {
    padding-left: 7rem !important;
  }
  .ps-sm-8 {
    padding-left: 8rem !important;
  }
  .ps-sm-9 {
    padding-left: 10rem !important;
  }
  .ps-sm-95 {
    padding-left: 12rem !important;
  }
  .ps-sm-10 {
    padding-left: 14rem !important;
  }
  .ps-sm-11 {
    padding-left: 21rem !important;
  }
  .ps-sm-12 {
    padding-left: 16.5rem !important;
  }
  .ps-sm-13 {
    padding-left: 17rem !important;
  }
  .ps-sm-14 {
    padding-left: 18rem !important;
  }
  .ps-sm-15 {
    padding-left: 19rem !important;
  }
  .ps-sm-16 {
    padding-left: 20rem !important;
  }
  .ps-sm-17 {
    padding-left: 21rem !important;
  }
  .ps-sm-18 {
    padding-left: 22rem !important;
  }
  .ps-sm-19 {
    padding-left: 23rem !important;
  }
  .ps-sm-20 {
    padding-left: 24rem !important;
  }
  .ps-sm-21 {
    padding-left: 25rem !important;
  }
  .ps-sm-22 {
    padding-left: 26rem !important;
  }
  .ps-sm-23 {
    padding-left: 27rem !important;
  }
  .ps-sm-24 {
    padding-left: 28rem !important;
  }
  .ps-sm-25 {
    padding-left: 29rem !important;
  }
  .ps-sm-26 {
    padding-left: 30rem !important;
  }
  .ps-sm-27 {
    padding-left: 31rem !important;
  }
  .ps-sm-28 {
    padding-left: 32rem !important;
  }
  .ps-sm-29 {
    padding-left: 33rem !important;
  }
  .ps-sm-30 {
    padding-left: 34rem !important;
  }
  .ps-sm-nav {
    padding-left: 0.5rem !important;
  }
  .ps-sm-100 {
    padding-left: 6.25rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.4rem !important;
  }
  .gap-sm-3 {
    gap: 0.8rem !important;
  }
  .gap-sm-35 {
    gap: 1.25rem !important;
  }
  .gap-sm-4 {
    gap: 1.875rem !important;
  }
  .gap-sm-45 {
    gap: 2.25rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-sm-55 {
    gap: 3.5rem !important;
  }
  .gap-sm-6 {
    gap: 3.85rem !important;
  }
  .gap-sm-65 {
    gap: 5rem !important;
  }
  .gap-sm-68 {
    gap: 5.5rem !important;
  }
  .gap-sm-7 {
    gap: 6.5rem !important;
  }
  .gap-sm-75 {
    gap: 7rem !important;
  }
  .gap-sm-8 {
    gap: 8rem !important;
  }
  .gap-sm-9 {
    gap: 10rem !important;
  }
  .gap-sm-95 {
    gap: 12rem !important;
  }
  .gap-sm-10 {
    gap: 14rem !important;
  }
  .gap-sm-11 {
    gap: 21rem !important;
  }
  .gap-sm-12 {
    gap: 16.5rem !important;
  }
  .gap-sm-13 {
    gap: 17rem !important;
  }
  .gap-sm-14 {
    gap: 18rem !important;
  }
  .gap-sm-15 {
    gap: 19rem !important;
  }
  .gap-sm-16 {
    gap: 20rem !important;
  }
  .gap-sm-17 {
    gap: 21rem !important;
  }
  .gap-sm-18 {
    gap: 22rem !important;
  }
  .gap-sm-19 {
    gap: 23rem !important;
  }
  .gap-sm-20 {
    gap: 24rem !important;
  }
  .gap-sm-21 {
    gap: 25rem !important;
  }
  .gap-sm-22 {
    gap: 26rem !important;
  }
  .gap-sm-23 {
    gap: 27rem !important;
  }
  .gap-sm-24 {
    gap: 28rem !important;
  }
  .gap-sm-25 {
    gap: 29rem !important;
  }
  .gap-sm-26 {
    gap: 30rem !important;
  }
  .gap-sm-27 {
    gap: 31rem !important;
  }
  .gap-sm-28 {
    gap: 32rem !important;
  }
  .gap-sm-29 {
    gap: 33rem !important;
  }
  .gap-sm-30 {
    gap: 34rem !important;
  }
  .gap-sm-nav {
    gap: 0.5rem !important;
  }
  .gap-sm-100 {
    gap: 6.25rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.4rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 0.8rem !important;
  }
  .row-gap-sm-35 {
    row-gap: 1.25rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.875rem !important;
  }
  .row-gap-sm-45 {
    row-gap: 2.25rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .row-gap-sm-55 {
    row-gap: 3.5rem !important;
  }
  .row-gap-sm-6 {
    row-gap: 3.85rem !important;
  }
  .row-gap-sm-65 {
    row-gap: 5rem !important;
  }
  .row-gap-sm-68 {
    row-gap: 5.5rem !important;
  }
  .row-gap-sm-7 {
    row-gap: 6.5rem !important;
  }
  .row-gap-sm-75 {
    row-gap: 7rem !important;
  }
  .row-gap-sm-8 {
    row-gap: 8rem !important;
  }
  .row-gap-sm-9 {
    row-gap: 10rem !important;
  }
  .row-gap-sm-95 {
    row-gap: 12rem !important;
  }
  .row-gap-sm-10 {
    row-gap: 14rem !important;
  }
  .row-gap-sm-11 {
    row-gap: 21rem !important;
  }
  .row-gap-sm-12 {
    row-gap: 16.5rem !important;
  }
  .row-gap-sm-13 {
    row-gap: 17rem !important;
  }
  .row-gap-sm-14 {
    row-gap: 18rem !important;
  }
  .row-gap-sm-15 {
    row-gap: 19rem !important;
  }
  .row-gap-sm-16 {
    row-gap: 20rem !important;
  }
  .row-gap-sm-17 {
    row-gap: 21rem !important;
  }
  .row-gap-sm-18 {
    row-gap: 22rem !important;
  }
  .row-gap-sm-19 {
    row-gap: 23rem !important;
  }
  .row-gap-sm-20 {
    row-gap: 24rem !important;
  }
  .row-gap-sm-21 {
    row-gap: 25rem !important;
  }
  .row-gap-sm-22 {
    row-gap: 26rem !important;
  }
  .row-gap-sm-23 {
    row-gap: 27rem !important;
  }
  .row-gap-sm-24 {
    row-gap: 28rem !important;
  }
  .row-gap-sm-25 {
    row-gap: 29rem !important;
  }
  .row-gap-sm-26 {
    row-gap: 30rem !important;
  }
  .row-gap-sm-27 {
    row-gap: 31rem !important;
  }
  .row-gap-sm-28 {
    row-gap: 32rem !important;
  }
  .row-gap-sm-29 {
    row-gap: 33rem !important;
  }
  .row-gap-sm-30 {
    row-gap: 34rem !important;
  }
  .row-gap-sm-nav {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-100 {
    row-gap: 6.25rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.4rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 0.8rem !important;
  }
  .column-gap-sm-35 {
    column-gap: 1.25rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.875rem !important;
  }
  .column-gap-sm-45 {
    column-gap: 2.25rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .column-gap-sm-55 {
    column-gap: 3.5rem !important;
  }
  .column-gap-sm-6 {
    column-gap: 3.85rem !important;
  }
  .column-gap-sm-65 {
    column-gap: 5rem !important;
  }
  .column-gap-sm-68 {
    column-gap: 5.5rem !important;
  }
  .column-gap-sm-7 {
    column-gap: 6.5rem !important;
  }
  .column-gap-sm-75 {
    column-gap: 7rem !important;
  }
  .column-gap-sm-8 {
    column-gap: 8rem !important;
  }
  .column-gap-sm-9 {
    column-gap: 10rem !important;
  }
  .column-gap-sm-95 {
    column-gap: 12rem !important;
  }
  .column-gap-sm-10 {
    column-gap: 14rem !important;
  }
  .column-gap-sm-11 {
    column-gap: 21rem !important;
  }
  .column-gap-sm-12 {
    column-gap: 16.5rem !important;
  }
  .column-gap-sm-13 {
    column-gap: 17rem !important;
  }
  .column-gap-sm-14 {
    column-gap: 18rem !important;
  }
  .column-gap-sm-15 {
    column-gap: 19rem !important;
  }
  .column-gap-sm-16 {
    column-gap: 20rem !important;
  }
  .column-gap-sm-17 {
    column-gap: 21rem !important;
  }
  .column-gap-sm-18 {
    column-gap: 22rem !important;
  }
  .column-gap-sm-19 {
    column-gap: 23rem !important;
  }
  .column-gap-sm-20 {
    column-gap: 24rem !important;
  }
  .column-gap-sm-21 {
    column-gap: 25rem !important;
  }
  .column-gap-sm-22 {
    column-gap: 26rem !important;
  }
  .column-gap-sm-23 {
    column-gap: 27rem !important;
  }
  .column-gap-sm-24 {
    column-gap: 28rem !important;
  }
  .column-gap-sm-25 {
    column-gap: 29rem !important;
  }
  .column-gap-sm-26 {
    column-gap: 30rem !important;
  }
  .column-gap-sm-27 {
    column-gap: 31rem !important;
  }
  .column-gap-sm-28 {
    column-gap: 32rem !important;
  }
  .column-gap-sm-29 {
    column-gap: 33rem !important;
  }
  .column-gap-sm-30 {
    column-gap: 34rem !important;
  }
  .column-gap-sm-nav {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-100 {
    column-gap: 6.25rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.4rem !important;
  }
  .m-md-3 {
    margin: 0.8rem !important;
  }
  .m-md-35 {
    margin: 1.25rem !important;
  }
  .m-md-4 {
    margin: 1.875rem !important;
  }
  .m-md-45 {
    margin: 2.25rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-55 {
    margin: 3.5rem !important;
  }
  .m-md-6 {
    margin: 3.85rem !important;
  }
  .m-md-65 {
    margin: 5rem !important;
  }
  .m-md-68 {
    margin: 5.5rem !important;
  }
  .m-md-7 {
    margin: 6.5rem !important;
  }
  .m-md-75 {
    margin: 7rem !important;
  }
  .m-md-8 {
    margin: 8rem !important;
  }
  .m-md-9 {
    margin: 10rem !important;
  }
  .m-md-95 {
    margin: 12rem !important;
  }
  .m-md-10 {
    margin: 14rem !important;
  }
  .m-md-11 {
    margin: 21rem !important;
  }
  .m-md-12 {
    margin: 16.5rem !important;
  }
  .m-md-13 {
    margin: 17rem !important;
  }
  .m-md-14 {
    margin: 18rem !important;
  }
  .m-md-15 {
    margin: 19rem !important;
  }
  .m-md-16 {
    margin: 20rem !important;
  }
  .m-md-17 {
    margin: 21rem !important;
  }
  .m-md-18 {
    margin: 22rem !important;
  }
  .m-md-19 {
    margin: 23rem !important;
  }
  .m-md-20 {
    margin: 24rem !important;
  }
  .m-md-21 {
    margin: 25rem !important;
  }
  .m-md-22 {
    margin: 26rem !important;
  }
  .m-md-23 {
    margin: 27rem !important;
  }
  .m-md-24 {
    margin: 28rem !important;
  }
  .m-md-25 {
    margin: 29rem !important;
  }
  .m-md-26 {
    margin: 30rem !important;
  }
  .m-md-27 {
    margin: 31rem !important;
  }
  .m-md-28 {
    margin: 32rem !important;
  }
  .m-md-29 {
    margin: 33rem !important;
  }
  .m-md-30 {
    margin: 34rem !important;
  }
  .m-md-nav {
    margin: 0.5rem !important;
  }
  .m-md-100 {
    margin: 6.25rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important;
  }
  .mx-md-3 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important;
  }
  .mx-md-35 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-md-45 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-55 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-md-6 {
    margin-right: 3.85rem !important;
    margin-left: 3.85rem !important;
  }
  .mx-md-65 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-68 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-md-7 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-md-75 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-md-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-md-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-md-95 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-md-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-md-11 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-md-12 {
    margin-right: 16.5rem !important;
    margin-left: 16.5rem !important;
  }
  .mx-md-13 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-md-14 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-md-15 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-md-16 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-md-17 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-md-18 {
    margin-right: 22rem !important;
    margin-left: 22rem !important;
  }
  .mx-md-19 {
    margin-right: 23rem !important;
    margin-left: 23rem !important;
  }
  .mx-md-20 {
    margin-right: 24rem !important;
    margin-left: 24rem !important;
  }
  .mx-md-21 {
    margin-right: 25rem !important;
    margin-left: 25rem !important;
  }
  .mx-md-22 {
    margin-right: 26rem !important;
    margin-left: 26rem !important;
  }
  .mx-md-23 {
    margin-right: 27rem !important;
    margin-left: 27rem !important;
  }
  .mx-md-24 {
    margin-right: 28rem !important;
    margin-left: 28rem !important;
  }
  .mx-md-25 {
    margin-right: 29rem !important;
    margin-left: 29rem !important;
  }
  .mx-md-26 {
    margin-right: 30rem !important;
    margin-left: 30rem !important;
  }
  .mx-md-27 {
    margin-right: 31rem !important;
    margin-left: 31rem !important;
  }
  .mx-md-28 {
    margin-right: 32rem !important;
    margin-left: 32rem !important;
  }
  .mx-md-29 {
    margin-right: 33rem !important;
    margin-left: 33rem !important;
  }
  .mx-md-30 {
    margin-right: 34rem !important;
    margin-left: 34rem !important;
  }
  .mx-md-nav {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  .my-md-3 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important;
  }
  .my-md-35 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-md-45 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-55 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-md-6 {
    margin-top: 3.85rem !important;
    margin-bottom: 3.85rem !important;
  }
  .my-md-65 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-68 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-md-7 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-md-75 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-md-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-md-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-md-95 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-md-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-md-11 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-md-12 {
    margin-top: 16.5rem !important;
    margin-bottom: 16.5rem !important;
  }
  .my-md-13 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-md-14 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-md-15 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-md-16 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-md-17 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-md-18 {
    margin-top: 22rem !important;
    margin-bottom: 22rem !important;
  }
  .my-md-19 {
    margin-top: 23rem !important;
    margin-bottom: 23rem !important;
  }
  .my-md-20 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }
  .my-md-21 {
    margin-top: 25rem !important;
    margin-bottom: 25rem !important;
  }
  .my-md-22 {
    margin-top: 26rem !important;
    margin-bottom: 26rem !important;
  }
  .my-md-23 {
    margin-top: 27rem !important;
    margin-bottom: 27rem !important;
  }
  .my-md-24 {
    margin-top: 28rem !important;
    margin-bottom: 28rem !important;
  }
  .my-md-25 {
    margin-top: 29rem !important;
    margin-bottom: 29rem !important;
  }
  .my-md-26 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important;
  }
  .my-md-27 {
    margin-top: 31rem !important;
    margin-bottom: 31rem !important;
  }
  .my-md-28 {
    margin-top: 32rem !important;
    margin-bottom: 32rem !important;
  }
  .my-md-29 {
    margin-top: 33rem !important;
    margin-bottom: 33rem !important;
  }
  .my-md-30 {
    margin-top: 34rem !important;
    margin-bottom: 34rem !important;
  }
  .my-md-nav {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.4rem !important;
  }
  .mt-md-3 {
    margin-top: 0.8rem !important;
  }
  .mt-md-35 {
    margin-top: 1.25rem !important;
  }
  .mt-md-4 {
    margin-top: 1.875rem !important;
  }
  .mt-md-45 {
    margin-top: 2.25rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-55 {
    margin-top: 3.5rem !important;
  }
  .mt-md-6 {
    margin-top: 3.85rem !important;
  }
  .mt-md-65 {
    margin-top: 5rem !important;
  }
  .mt-md-68 {
    margin-top: 5.5rem !important;
  }
  .mt-md-7 {
    margin-top: 6.5rem !important;
  }
  .mt-md-75 {
    margin-top: 7rem !important;
  }
  .mt-md-8 {
    margin-top: 8rem !important;
  }
  .mt-md-9 {
    margin-top: 10rem !important;
  }
  .mt-md-95 {
    margin-top: 12rem !important;
  }
  .mt-md-10 {
    margin-top: 14rem !important;
  }
  .mt-md-11 {
    margin-top: 21rem !important;
  }
  .mt-md-12 {
    margin-top: 16.5rem !important;
  }
  .mt-md-13 {
    margin-top: 17rem !important;
  }
  .mt-md-14 {
    margin-top: 18rem !important;
  }
  .mt-md-15 {
    margin-top: 19rem !important;
  }
  .mt-md-16 {
    margin-top: 20rem !important;
  }
  .mt-md-17 {
    margin-top: 21rem !important;
  }
  .mt-md-18 {
    margin-top: 22rem !important;
  }
  .mt-md-19 {
    margin-top: 23rem !important;
  }
  .mt-md-20 {
    margin-top: 24rem !important;
  }
  .mt-md-21 {
    margin-top: 25rem !important;
  }
  .mt-md-22 {
    margin-top: 26rem !important;
  }
  .mt-md-23 {
    margin-top: 27rem !important;
  }
  .mt-md-24 {
    margin-top: 28rem !important;
  }
  .mt-md-25 {
    margin-top: 29rem !important;
  }
  .mt-md-26 {
    margin-top: 30rem !important;
  }
  .mt-md-27 {
    margin-top: 31rem !important;
  }
  .mt-md-28 {
    margin-top: 32rem !important;
  }
  .mt-md-29 {
    margin-top: 33rem !important;
  }
  .mt-md-30 {
    margin-top: 34rem !important;
  }
  .mt-md-nav {
    margin-top: 0.5rem !important;
  }
  .mt-md-100 {
    margin-top: 6.25rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.4rem !important;
  }
  .me-md-3 {
    margin-right: 0.8rem !important;
  }
  .me-md-35 {
    margin-right: 1.25rem !important;
  }
  .me-md-4 {
    margin-right: 1.875rem !important;
  }
  .me-md-45 {
    margin-right: 2.25rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-55 {
    margin-right: 3.5rem !important;
  }
  .me-md-6 {
    margin-right: 3.85rem !important;
  }
  .me-md-65 {
    margin-right: 5rem !important;
  }
  .me-md-68 {
    margin-right: 5.5rem !important;
  }
  .me-md-7 {
    margin-right: 6.5rem !important;
  }
  .me-md-75 {
    margin-right: 7rem !important;
  }
  .me-md-8 {
    margin-right: 8rem !important;
  }
  .me-md-9 {
    margin-right: 10rem !important;
  }
  .me-md-95 {
    margin-right: 12rem !important;
  }
  .me-md-10 {
    margin-right: 14rem !important;
  }
  .me-md-11 {
    margin-right: 21rem !important;
  }
  .me-md-12 {
    margin-right: 16.5rem !important;
  }
  .me-md-13 {
    margin-right: 17rem !important;
  }
  .me-md-14 {
    margin-right: 18rem !important;
  }
  .me-md-15 {
    margin-right: 19rem !important;
  }
  .me-md-16 {
    margin-right: 20rem !important;
  }
  .me-md-17 {
    margin-right: 21rem !important;
  }
  .me-md-18 {
    margin-right: 22rem !important;
  }
  .me-md-19 {
    margin-right: 23rem !important;
  }
  .me-md-20 {
    margin-right: 24rem !important;
  }
  .me-md-21 {
    margin-right: 25rem !important;
  }
  .me-md-22 {
    margin-right: 26rem !important;
  }
  .me-md-23 {
    margin-right: 27rem !important;
  }
  .me-md-24 {
    margin-right: 28rem !important;
  }
  .me-md-25 {
    margin-right: 29rem !important;
  }
  .me-md-26 {
    margin-right: 30rem !important;
  }
  .me-md-27 {
    margin-right: 31rem !important;
  }
  .me-md-28 {
    margin-right: 32rem !important;
  }
  .me-md-29 {
    margin-right: 33rem !important;
  }
  .me-md-30 {
    margin-right: 34rem !important;
  }
  .me-md-nav {
    margin-right: 0.5rem !important;
  }
  .me-md-100 {
    margin-right: 6.25rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.4rem !important;
  }
  .mb-md-3 {
    margin-bottom: 0.8rem !important;
  }
  .mb-md-35 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-md-45 {
    margin-bottom: 2.25rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-55 {
    margin-bottom: 3.5rem !important;
  }
  .mb-md-6 {
    margin-bottom: 3.85rem !important;
  }
  .mb-md-65 {
    margin-bottom: 5rem !important;
  }
  .mb-md-68 {
    margin-bottom: 5.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 6.5rem !important;
  }
  .mb-md-75 {
    margin-bottom: 7rem !important;
  }
  .mb-md-8 {
    margin-bottom: 8rem !important;
  }
  .mb-md-9 {
    margin-bottom: 10rem !important;
  }
  .mb-md-95 {
    margin-bottom: 12rem !important;
  }
  .mb-md-10 {
    margin-bottom: 14rem !important;
  }
  .mb-md-11 {
    margin-bottom: 21rem !important;
  }
  .mb-md-12 {
    margin-bottom: 16.5rem !important;
  }
  .mb-md-13 {
    margin-bottom: 17rem !important;
  }
  .mb-md-14 {
    margin-bottom: 18rem !important;
  }
  .mb-md-15 {
    margin-bottom: 19rem !important;
  }
  .mb-md-16 {
    margin-bottom: 20rem !important;
  }
  .mb-md-17 {
    margin-bottom: 21rem !important;
  }
  .mb-md-18 {
    margin-bottom: 22rem !important;
  }
  .mb-md-19 {
    margin-bottom: 23rem !important;
  }
  .mb-md-20 {
    margin-bottom: 24rem !important;
  }
  .mb-md-21 {
    margin-bottom: 25rem !important;
  }
  .mb-md-22 {
    margin-bottom: 26rem !important;
  }
  .mb-md-23 {
    margin-bottom: 27rem !important;
  }
  .mb-md-24 {
    margin-bottom: 28rem !important;
  }
  .mb-md-25 {
    margin-bottom: 29rem !important;
  }
  .mb-md-26 {
    margin-bottom: 30rem !important;
  }
  .mb-md-27 {
    margin-bottom: 31rem !important;
  }
  .mb-md-28 {
    margin-bottom: 32rem !important;
  }
  .mb-md-29 {
    margin-bottom: 33rem !important;
  }
  .mb-md-30 {
    margin-bottom: 34rem !important;
  }
  .mb-md-nav {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.4rem !important;
  }
  .ms-md-3 {
    margin-left: 0.8rem !important;
  }
  .ms-md-35 {
    margin-left: 1.25rem !important;
  }
  .ms-md-4 {
    margin-left: 1.875rem !important;
  }
  .ms-md-45 {
    margin-left: 2.25rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-55 {
    margin-left: 3.5rem !important;
  }
  .ms-md-6 {
    margin-left: 3.85rem !important;
  }
  .ms-md-65 {
    margin-left: 5rem !important;
  }
  .ms-md-68 {
    margin-left: 5.5rem !important;
  }
  .ms-md-7 {
    margin-left: 6.5rem !important;
  }
  .ms-md-75 {
    margin-left: 7rem !important;
  }
  .ms-md-8 {
    margin-left: 8rem !important;
  }
  .ms-md-9 {
    margin-left: 10rem !important;
  }
  .ms-md-95 {
    margin-left: 12rem !important;
  }
  .ms-md-10 {
    margin-left: 14rem !important;
  }
  .ms-md-11 {
    margin-left: 21rem !important;
  }
  .ms-md-12 {
    margin-left: 16.5rem !important;
  }
  .ms-md-13 {
    margin-left: 17rem !important;
  }
  .ms-md-14 {
    margin-left: 18rem !important;
  }
  .ms-md-15 {
    margin-left: 19rem !important;
  }
  .ms-md-16 {
    margin-left: 20rem !important;
  }
  .ms-md-17 {
    margin-left: 21rem !important;
  }
  .ms-md-18 {
    margin-left: 22rem !important;
  }
  .ms-md-19 {
    margin-left: 23rem !important;
  }
  .ms-md-20 {
    margin-left: 24rem !important;
  }
  .ms-md-21 {
    margin-left: 25rem !important;
  }
  .ms-md-22 {
    margin-left: 26rem !important;
  }
  .ms-md-23 {
    margin-left: 27rem !important;
  }
  .ms-md-24 {
    margin-left: 28rem !important;
  }
  .ms-md-25 {
    margin-left: 29rem !important;
  }
  .ms-md-26 {
    margin-left: 30rem !important;
  }
  .ms-md-27 {
    margin-left: 31rem !important;
  }
  .ms-md-28 {
    margin-left: 32rem !important;
  }
  .ms-md-29 {
    margin-left: 33rem !important;
  }
  .ms-md-30 {
    margin-left: 34rem !important;
  }
  .ms-md-nav {
    margin-left: 0.5rem !important;
  }
  .ms-md-100 {
    margin-left: 6.25rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.4rem !important;
  }
  .p-md-3 {
    padding: 0.8rem !important;
  }
  .p-md-35 {
    padding: 1.25rem !important;
  }
  .p-md-4 {
    padding: 1.875rem !important;
  }
  .p-md-45 {
    padding: 2.25rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-55 {
    padding: 3.5rem !important;
  }
  .p-md-6 {
    padding: 3.85rem !important;
  }
  .p-md-65 {
    padding: 5rem !important;
  }
  .p-md-68 {
    padding: 5.5rem !important;
  }
  .p-md-7 {
    padding: 6.5rem !important;
  }
  .p-md-75 {
    padding: 7rem !important;
  }
  .p-md-8 {
    padding: 8rem !important;
  }
  .p-md-9 {
    padding: 10rem !important;
  }
  .p-md-95 {
    padding: 12rem !important;
  }
  .p-md-10 {
    padding: 14rem !important;
  }
  .p-md-11 {
    padding: 21rem !important;
  }
  .p-md-12 {
    padding: 16.5rem !important;
  }
  .p-md-13 {
    padding: 17rem !important;
  }
  .p-md-14 {
    padding: 18rem !important;
  }
  .p-md-15 {
    padding: 19rem !important;
  }
  .p-md-16 {
    padding: 20rem !important;
  }
  .p-md-17 {
    padding: 21rem !important;
  }
  .p-md-18 {
    padding: 22rem !important;
  }
  .p-md-19 {
    padding: 23rem !important;
  }
  .p-md-20 {
    padding: 24rem !important;
  }
  .p-md-21 {
    padding: 25rem !important;
  }
  .p-md-22 {
    padding: 26rem !important;
  }
  .p-md-23 {
    padding: 27rem !important;
  }
  .p-md-24 {
    padding: 28rem !important;
  }
  .p-md-25 {
    padding: 29rem !important;
  }
  .p-md-26 {
    padding: 30rem !important;
  }
  .p-md-27 {
    padding: 31rem !important;
  }
  .p-md-28 {
    padding: 32rem !important;
  }
  .p-md-29 {
    padding: 33rem !important;
  }
  .p-md-30 {
    padding: 34rem !important;
  }
  .p-md-nav {
    padding: 0.5rem !important;
  }
  .p-md-100 {
    padding: 6.25rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
  }
  .px-md-3 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important;
  }
  .px-md-35 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-md-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-md-45 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-55 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-md-6 {
    padding-right: 3.85rem !important;
    padding-left: 3.85rem !important;
  }
  .px-md-65 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-68 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-md-7 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-md-75 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-md-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-md-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-md-95 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-md-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-md-11 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-md-12 {
    padding-right: 16.5rem !important;
    padding-left: 16.5rem !important;
  }
  .px-md-13 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-md-14 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-md-15 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-md-16 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-md-17 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-md-18 {
    padding-right: 22rem !important;
    padding-left: 22rem !important;
  }
  .px-md-19 {
    padding-right: 23rem !important;
    padding-left: 23rem !important;
  }
  .px-md-20 {
    padding-right: 24rem !important;
    padding-left: 24rem !important;
  }
  .px-md-21 {
    padding-right: 25rem !important;
    padding-left: 25rem !important;
  }
  .px-md-22 {
    padding-right: 26rem !important;
    padding-left: 26rem !important;
  }
  .px-md-23 {
    padding-right: 27rem !important;
    padding-left: 27rem !important;
  }
  .px-md-24 {
    padding-right: 28rem !important;
    padding-left: 28rem !important;
  }
  .px-md-25 {
    padding-right: 29rem !important;
    padding-left: 29rem !important;
  }
  .px-md-26 {
    padding-right: 30rem !important;
    padding-left: 30rem !important;
  }
  .px-md-27 {
    padding-right: 31rem !important;
    padding-left: 31rem !important;
  }
  .px-md-28 {
    padding-right: 32rem !important;
    padding-left: 32rem !important;
  }
  .px-md-29 {
    padding-right: 33rem !important;
    padding-left: 33rem !important;
  }
  .px-md-30 {
    padding-right: 34rem !important;
    padding-left: 34rem !important;
  }
  .px-md-nav {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
  }
  .py-md-3 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }
  .py-md-35 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-md-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-md-45 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-55 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-md-6 {
    padding-top: 3.85rem !important;
    padding-bottom: 3.85rem !important;
  }
  .py-md-65 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-68 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-md-7 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-md-75 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-md-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-md-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-md-95 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-md-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-md-11 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-md-12 {
    padding-top: 16.5rem !important;
    padding-bottom: 16.5rem !important;
  }
  .py-md-13 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-md-14 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-md-15 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-md-16 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-md-17 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-md-18 {
    padding-top: 22rem !important;
    padding-bottom: 22rem !important;
  }
  .py-md-19 {
    padding-top: 23rem !important;
    padding-bottom: 23rem !important;
  }
  .py-md-20 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
  .py-md-21 {
    padding-top: 25rem !important;
    padding-bottom: 25rem !important;
  }
  .py-md-22 {
    padding-top: 26rem !important;
    padding-bottom: 26rem !important;
  }
  .py-md-23 {
    padding-top: 27rem !important;
    padding-bottom: 27rem !important;
  }
  .py-md-24 {
    padding-top: 28rem !important;
    padding-bottom: 28rem !important;
  }
  .py-md-25 {
    padding-top: 29rem !important;
    padding-bottom: 29rem !important;
  }
  .py-md-26 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important;
  }
  .py-md-27 {
    padding-top: 31rem !important;
    padding-bottom: 31rem !important;
  }
  .py-md-28 {
    padding-top: 32rem !important;
    padding-bottom: 32rem !important;
  }
  .py-md-29 {
    padding-top: 33rem !important;
    padding-bottom: 33rem !important;
  }
  .py-md-30 {
    padding-top: 34rem !important;
    padding-bottom: 34rem !important;
  }
  .py-md-nav {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.4rem !important;
  }
  .pt-md-3 {
    padding-top: 0.8rem !important;
  }
  .pt-md-35 {
    padding-top: 1.25rem !important;
  }
  .pt-md-4 {
    padding-top: 1.875rem !important;
  }
  .pt-md-45 {
    padding-top: 2.25rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-55 {
    padding-top: 3.5rem !important;
  }
  .pt-md-6 {
    padding-top: 3.85rem !important;
  }
  .pt-md-65 {
    padding-top: 5rem !important;
  }
  .pt-md-68 {
    padding-top: 5.5rem !important;
  }
  .pt-md-7 {
    padding-top: 6.5rem !important;
  }
  .pt-md-75 {
    padding-top: 7rem !important;
  }
  .pt-md-8 {
    padding-top: 8rem !important;
  }
  .pt-md-9 {
    padding-top: 10rem !important;
  }
  .pt-md-95 {
    padding-top: 12rem !important;
  }
  .pt-md-10 {
    padding-top: 14rem !important;
  }
  .pt-md-11 {
    padding-top: 21rem !important;
  }
  .pt-md-12 {
    padding-top: 16.5rem !important;
  }
  .pt-md-13 {
    padding-top: 17rem !important;
  }
  .pt-md-14 {
    padding-top: 18rem !important;
  }
  .pt-md-15 {
    padding-top: 19rem !important;
  }
  .pt-md-16 {
    padding-top: 20rem !important;
  }
  .pt-md-17 {
    padding-top: 21rem !important;
  }
  .pt-md-18 {
    padding-top: 22rem !important;
  }
  .pt-md-19 {
    padding-top: 23rem !important;
  }
  .pt-md-20 {
    padding-top: 24rem !important;
  }
  .pt-md-21 {
    padding-top: 25rem !important;
  }
  .pt-md-22 {
    padding-top: 26rem !important;
  }
  .pt-md-23 {
    padding-top: 27rem !important;
  }
  .pt-md-24 {
    padding-top: 28rem !important;
  }
  .pt-md-25 {
    padding-top: 29rem !important;
  }
  .pt-md-26 {
    padding-top: 30rem !important;
  }
  .pt-md-27 {
    padding-top: 31rem !important;
  }
  .pt-md-28 {
    padding-top: 32rem !important;
  }
  .pt-md-29 {
    padding-top: 33rem !important;
  }
  .pt-md-30 {
    padding-top: 34rem !important;
  }
  .pt-md-nav {
    padding-top: 0.5rem !important;
  }
  .pt-md-100 {
    padding-top: 6.25rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.4rem !important;
  }
  .pe-md-3 {
    padding-right: 0.8rem !important;
  }
  .pe-md-35 {
    padding-right: 1.25rem !important;
  }
  .pe-md-4 {
    padding-right: 1.875rem !important;
  }
  .pe-md-45 {
    padding-right: 2.25rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-55 {
    padding-right: 3.5rem !important;
  }
  .pe-md-6 {
    padding-right: 3.85rem !important;
  }
  .pe-md-65 {
    padding-right: 5rem !important;
  }
  .pe-md-68 {
    padding-right: 5.5rem !important;
  }
  .pe-md-7 {
    padding-right: 6.5rem !important;
  }
  .pe-md-75 {
    padding-right: 7rem !important;
  }
  .pe-md-8 {
    padding-right: 8rem !important;
  }
  .pe-md-9 {
    padding-right: 10rem !important;
  }
  .pe-md-95 {
    padding-right: 12rem !important;
  }
  .pe-md-10 {
    padding-right: 14rem !important;
  }
  .pe-md-11 {
    padding-right: 21rem !important;
  }
  .pe-md-12 {
    padding-right: 16.5rem !important;
  }
  .pe-md-13 {
    padding-right: 17rem !important;
  }
  .pe-md-14 {
    padding-right: 18rem !important;
  }
  .pe-md-15 {
    padding-right: 19rem !important;
  }
  .pe-md-16 {
    padding-right: 20rem !important;
  }
  .pe-md-17 {
    padding-right: 21rem !important;
  }
  .pe-md-18 {
    padding-right: 22rem !important;
  }
  .pe-md-19 {
    padding-right: 23rem !important;
  }
  .pe-md-20 {
    padding-right: 24rem !important;
  }
  .pe-md-21 {
    padding-right: 25rem !important;
  }
  .pe-md-22 {
    padding-right: 26rem !important;
  }
  .pe-md-23 {
    padding-right: 27rem !important;
  }
  .pe-md-24 {
    padding-right: 28rem !important;
  }
  .pe-md-25 {
    padding-right: 29rem !important;
  }
  .pe-md-26 {
    padding-right: 30rem !important;
  }
  .pe-md-27 {
    padding-right: 31rem !important;
  }
  .pe-md-28 {
    padding-right: 32rem !important;
  }
  .pe-md-29 {
    padding-right: 33rem !important;
  }
  .pe-md-30 {
    padding-right: 34rem !important;
  }
  .pe-md-nav {
    padding-right: 0.5rem !important;
  }
  .pe-md-100 {
    padding-right: 6.25rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.4rem !important;
  }
  .pb-md-3 {
    padding-bottom: 0.8rem !important;
  }
  .pb-md-35 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-md-45 {
    padding-bottom: 2.25rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-55 {
    padding-bottom: 3.5rem !important;
  }
  .pb-md-6 {
    padding-bottom: 3.85rem !important;
  }
  .pb-md-65 {
    padding-bottom: 5rem !important;
  }
  .pb-md-68 {
    padding-bottom: 5.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 6.5rem !important;
  }
  .pb-md-75 {
    padding-bottom: 7rem !important;
  }
  .pb-md-8 {
    padding-bottom: 8rem !important;
  }
  .pb-md-9 {
    padding-bottom: 10rem !important;
  }
  .pb-md-95 {
    padding-bottom: 12rem !important;
  }
  .pb-md-10 {
    padding-bottom: 14rem !important;
  }
  .pb-md-11 {
    padding-bottom: 21rem !important;
  }
  .pb-md-12 {
    padding-bottom: 16.5rem !important;
  }
  .pb-md-13 {
    padding-bottom: 17rem !important;
  }
  .pb-md-14 {
    padding-bottom: 18rem !important;
  }
  .pb-md-15 {
    padding-bottom: 19rem !important;
  }
  .pb-md-16 {
    padding-bottom: 20rem !important;
  }
  .pb-md-17 {
    padding-bottom: 21rem !important;
  }
  .pb-md-18 {
    padding-bottom: 22rem !important;
  }
  .pb-md-19 {
    padding-bottom: 23rem !important;
  }
  .pb-md-20 {
    padding-bottom: 24rem !important;
  }
  .pb-md-21 {
    padding-bottom: 25rem !important;
  }
  .pb-md-22 {
    padding-bottom: 26rem !important;
  }
  .pb-md-23 {
    padding-bottom: 27rem !important;
  }
  .pb-md-24 {
    padding-bottom: 28rem !important;
  }
  .pb-md-25 {
    padding-bottom: 29rem !important;
  }
  .pb-md-26 {
    padding-bottom: 30rem !important;
  }
  .pb-md-27 {
    padding-bottom: 31rem !important;
  }
  .pb-md-28 {
    padding-bottom: 32rem !important;
  }
  .pb-md-29 {
    padding-bottom: 33rem !important;
  }
  .pb-md-30 {
    padding-bottom: 34rem !important;
  }
  .pb-md-nav {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-100 {
    padding-bottom: 6.25rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.4rem !important;
  }
  .ps-md-3 {
    padding-left: 0.8rem !important;
  }
  .ps-md-35 {
    padding-left: 1.25rem !important;
  }
  .ps-md-4 {
    padding-left: 1.875rem !important;
  }
  .ps-md-45 {
    padding-left: 2.25rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-55 {
    padding-left: 3.5rem !important;
  }
  .ps-md-6 {
    padding-left: 3.85rem !important;
  }
  .ps-md-65 {
    padding-left: 5rem !important;
  }
  .ps-md-68 {
    padding-left: 5.5rem !important;
  }
  .ps-md-7 {
    padding-left: 6.5rem !important;
  }
  .ps-md-75 {
    padding-left: 7rem !important;
  }
  .ps-md-8 {
    padding-left: 8rem !important;
  }
  .ps-md-9 {
    padding-left: 10rem !important;
  }
  .ps-md-95 {
    padding-left: 12rem !important;
  }
  .ps-md-10 {
    padding-left: 14rem !important;
  }
  .ps-md-11 {
    padding-left: 21rem !important;
  }
  .ps-md-12 {
    padding-left: 16.5rem !important;
  }
  .ps-md-13 {
    padding-left: 17rem !important;
  }
  .ps-md-14 {
    padding-left: 18rem !important;
  }
  .ps-md-15 {
    padding-left: 19rem !important;
  }
  .ps-md-16 {
    padding-left: 20rem !important;
  }
  .ps-md-17 {
    padding-left: 21rem !important;
  }
  .ps-md-18 {
    padding-left: 22rem !important;
  }
  .ps-md-19 {
    padding-left: 23rem !important;
  }
  .ps-md-20 {
    padding-left: 24rem !important;
  }
  .ps-md-21 {
    padding-left: 25rem !important;
  }
  .ps-md-22 {
    padding-left: 26rem !important;
  }
  .ps-md-23 {
    padding-left: 27rem !important;
  }
  .ps-md-24 {
    padding-left: 28rem !important;
  }
  .ps-md-25 {
    padding-left: 29rem !important;
  }
  .ps-md-26 {
    padding-left: 30rem !important;
  }
  .ps-md-27 {
    padding-left: 31rem !important;
  }
  .ps-md-28 {
    padding-left: 32rem !important;
  }
  .ps-md-29 {
    padding-left: 33rem !important;
  }
  .ps-md-30 {
    padding-left: 34rem !important;
  }
  .ps-md-nav {
    padding-left: 0.5rem !important;
  }
  .ps-md-100 {
    padding-left: 6.25rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.4rem !important;
  }
  .gap-md-3 {
    gap: 0.8rem !important;
  }
  .gap-md-35 {
    gap: 1.25rem !important;
  }
  .gap-md-4 {
    gap: 1.875rem !important;
  }
  .gap-md-45 {
    gap: 2.25rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-md-55 {
    gap: 3.5rem !important;
  }
  .gap-md-6 {
    gap: 3.85rem !important;
  }
  .gap-md-65 {
    gap: 5rem !important;
  }
  .gap-md-68 {
    gap: 5.5rem !important;
  }
  .gap-md-7 {
    gap: 6.5rem !important;
  }
  .gap-md-75 {
    gap: 7rem !important;
  }
  .gap-md-8 {
    gap: 8rem !important;
  }
  .gap-md-9 {
    gap: 10rem !important;
  }
  .gap-md-95 {
    gap: 12rem !important;
  }
  .gap-md-10 {
    gap: 14rem !important;
  }
  .gap-md-11 {
    gap: 21rem !important;
  }
  .gap-md-12 {
    gap: 16.5rem !important;
  }
  .gap-md-13 {
    gap: 17rem !important;
  }
  .gap-md-14 {
    gap: 18rem !important;
  }
  .gap-md-15 {
    gap: 19rem !important;
  }
  .gap-md-16 {
    gap: 20rem !important;
  }
  .gap-md-17 {
    gap: 21rem !important;
  }
  .gap-md-18 {
    gap: 22rem !important;
  }
  .gap-md-19 {
    gap: 23rem !important;
  }
  .gap-md-20 {
    gap: 24rem !important;
  }
  .gap-md-21 {
    gap: 25rem !important;
  }
  .gap-md-22 {
    gap: 26rem !important;
  }
  .gap-md-23 {
    gap: 27rem !important;
  }
  .gap-md-24 {
    gap: 28rem !important;
  }
  .gap-md-25 {
    gap: 29rem !important;
  }
  .gap-md-26 {
    gap: 30rem !important;
  }
  .gap-md-27 {
    gap: 31rem !important;
  }
  .gap-md-28 {
    gap: 32rem !important;
  }
  .gap-md-29 {
    gap: 33rem !important;
  }
  .gap-md-30 {
    gap: 34rem !important;
  }
  .gap-md-nav {
    gap: 0.5rem !important;
  }
  .gap-md-100 {
    gap: 6.25rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.4rem !important;
  }
  .row-gap-md-3 {
    row-gap: 0.8rem !important;
  }
  .row-gap-md-35 {
    row-gap: 1.25rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.875rem !important;
  }
  .row-gap-md-45 {
    row-gap: 2.25rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .row-gap-md-55 {
    row-gap: 3.5rem !important;
  }
  .row-gap-md-6 {
    row-gap: 3.85rem !important;
  }
  .row-gap-md-65 {
    row-gap: 5rem !important;
  }
  .row-gap-md-68 {
    row-gap: 5.5rem !important;
  }
  .row-gap-md-7 {
    row-gap: 6.5rem !important;
  }
  .row-gap-md-75 {
    row-gap: 7rem !important;
  }
  .row-gap-md-8 {
    row-gap: 8rem !important;
  }
  .row-gap-md-9 {
    row-gap: 10rem !important;
  }
  .row-gap-md-95 {
    row-gap: 12rem !important;
  }
  .row-gap-md-10 {
    row-gap: 14rem !important;
  }
  .row-gap-md-11 {
    row-gap: 21rem !important;
  }
  .row-gap-md-12 {
    row-gap: 16.5rem !important;
  }
  .row-gap-md-13 {
    row-gap: 17rem !important;
  }
  .row-gap-md-14 {
    row-gap: 18rem !important;
  }
  .row-gap-md-15 {
    row-gap: 19rem !important;
  }
  .row-gap-md-16 {
    row-gap: 20rem !important;
  }
  .row-gap-md-17 {
    row-gap: 21rem !important;
  }
  .row-gap-md-18 {
    row-gap: 22rem !important;
  }
  .row-gap-md-19 {
    row-gap: 23rem !important;
  }
  .row-gap-md-20 {
    row-gap: 24rem !important;
  }
  .row-gap-md-21 {
    row-gap: 25rem !important;
  }
  .row-gap-md-22 {
    row-gap: 26rem !important;
  }
  .row-gap-md-23 {
    row-gap: 27rem !important;
  }
  .row-gap-md-24 {
    row-gap: 28rem !important;
  }
  .row-gap-md-25 {
    row-gap: 29rem !important;
  }
  .row-gap-md-26 {
    row-gap: 30rem !important;
  }
  .row-gap-md-27 {
    row-gap: 31rem !important;
  }
  .row-gap-md-28 {
    row-gap: 32rem !important;
  }
  .row-gap-md-29 {
    row-gap: 33rem !important;
  }
  .row-gap-md-30 {
    row-gap: 34rem !important;
  }
  .row-gap-md-nav {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-100 {
    row-gap: 6.25rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.4rem !important;
  }
  .column-gap-md-3 {
    column-gap: 0.8rem !important;
  }
  .column-gap-md-35 {
    column-gap: 1.25rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.875rem !important;
  }
  .column-gap-md-45 {
    column-gap: 2.25rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .column-gap-md-55 {
    column-gap: 3.5rem !important;
  }
  .column-gap-md-6 {
    column-gap: 3.85rem !important;
  }
  .column-gap-md-65 {
    column-gap: 5rem !important;
  }
  .column-gap-md-68 {
    column-gap: 5.5rem !important;
  }
  .column-gap-md-7 {
    column-gap: 6.5rem !important;
  }
  .column-gap-md-75 {
    column-gap: 7rem !important;
  }
  .column-gap-md-8 {
    column-gap: 8rem !important;
  }
  .column-gap-md-9 {
    column-gap: 10rem !important;
  }
  .column-gap-md-95 {
    column-gap: 12rem !important;
  }
  .column-gap-md-10 {
    column-gap: 14rem !important;
  }
  .column-gap-md-11 {
    column-gap: 21rem !important;
  }
  .column-gap-md-12 {
    column-gap: 16.5rem !important;
  }
  .column-gap-md-13 {
    column-gap: 17rem !important;
  }
  .column-gap-md-14 {
    column-gap: 18rem !important;
  }
  .column-gap-md-15 {
    column-gap: 19rem !important;
  }
  .column-gap-md-16 {
    column-gap: 20rem !important;
  }
  .column-gap-md-17 {
    column-gap: 21rem !important;
  }
  .column-gap-md-18 {
    column-gap: 22rem !important;
  }
  .column-gap-md-19 {
    column-gap: 23rem !important;
  }
  .column-gap-md-20 {
    column-gap: 24rem !important;
  }
  .column-gap-md-21 {
    column-gap: 25rem !important;
  }
  .column-gap-md-22 {
    column-gap: 26rem !important;
  }
  .column-gap-md-23 {
    column-gap: 27rem !important;
  }
  .column-gap-md-24 {
    column-gap: 28rem !important;
  }
  .column-gap-md-25 {
    column-gap: 29rem !important;
  }
  .column-gap-md-26 {
    column-gap: 30rem !important;
  }
  .column-gap-md-27 {
    column-gap: 31rem !important;
  }
  .column-gap-md-28 {
    column-gap: 32rem !important;
  }
  .column-gap-md-29 {
    column-gap: 33rem !important;
  }
  .column-gap-md-30 {
    column-gap: 34rem !important;
  }
  .column-gap-md-nav {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-100 {
    column-gap: 6.25rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.4rem !important;
  }
  .m-lg-3 {
    margin: 0.8rem !important;
  }
  .m-lg-35 {
    margin: 1.25rem !important;
  }
  .m-lg-4 {
    margin: 1.875rem !important;
  }
  .m-lg-45 {
    margin: 2.25rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-55 {
    margin: 3.5rem !important;
  }
  .m-lg-6 {
    margin: 3.85rem !important;
  }
  .m-lg-65 {
    margin: 5rem !important;
  }
  .m-lg-68 {
    margin: 5.5rem !important;
  }
  .m-lg-7 {
    margin: 6.5rem !important;
  }
  .m-lg-75 {
    margin: 7rem !important;
  }
  .m-lg-8 {
    margin: 8rem !important;
  }
  .m-lg-9 {
    margin: 10rem !important;
  }
  .m-lg-95 {
    margin: 12rem !important;
  }
  .m-lg-10 {
    margin: 14rem !important;
  }
  .m-lg-11 {
    margin: 21rem !important;
  }
  .m-lg-12 {
    margin: 16.5rem !important;
  }
  .m-lg-13 {
    margin: 17rem !important;
  }
  .m-lg-14 {
    margin: 18rem !important;
  }
  .m-lg-15 {
    margin: 19rem !important;
  }
  .m-lg-16 {
    margin: 20rem !important;
  }
  .m-lg-17 {
    margin: 21rem !important;
  }
  .m-lg-18 {
    margin: 22rem !important;
  }
  .m-lg-19 {
    margin: 23rem !important;
  }
  .m-lg-20 {
    margin: 24rem !important;
  }
  .m-lg-21 {
    margin: 25rem !important;
  }
  .m-lg-22 {
    margin: 26rem !important;
  }
  .m-lg-23 {
    margin: 27rem !important;
  }
  .m-lg-24 {
    margin: 28rem !important;
  }
  .m-lg-25 {
    margin: 29rem !important;
  }
  .m-lg-26 {
    margin: 30rem !important;
  }
  .m-lg-27 {
    margin: 31rem !important;
  }
  .m-lg-28 {
    margin: 32rem !important;
  }
  .m-lg-29 {
    margin: 33rem !important;
  }
  .m-lg-30 {
    margin: 34rem !important;
  }
  .m-lg-nav {
    margin: 0.5rem !important;
  }
  .m-lg-100 {
    margin: 6.25rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important;
  }
  .mx-lg-3 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important;
  }
  .mx-lg-35 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-lg-45 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-55 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-lg-6 {
    margin-right: 3.85rem !important;
    margin-left: 3.85rem !important;
  }
  .mx-lg-65 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-68 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-lg-7 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-lg-75 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-lg-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-lg-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-lg-95 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-lg-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-lg-11 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-lg-12 {
    margin-right: 16.5rem !important;
    margin-left: 16.5rem !important;
  }
  .mx-lg-13 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-lg-14 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-lg-15 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-lg-16 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-lg-17 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-lg-18 {
    margin-right: 22rem !important;
    margin-left: 22rem !important;
  }
  .mx-lg-19 {
    margin-right: 23rem !important;
    margin-left: 23rem !important;
  }
  .mx-lg-20 {
    margin-right: 24rem !important;
    margin-left: 24rem !important;
  }
  .mx-lg-21 {
    margin-right: 25rem !important;
    margin-left: 25rem !important;
  }
  .mx-lg-22 {
    margin-right: 26rem !important;
    margin-left: 26rem !important;
  }
  .mx-lg-23 {
    margin-right: 27rem !important;
    margin-left: 27rem !important;
  }
  .mx-lg-24 {
    margin-right: 28rem !important;
    margin-left: 28rem !important;
  }
  .mx-lg-25 {
    margin-right: 29rem !important;
    margin-left: 29rem !important;
  }
  .mx-lg-26 {
    margin-right: 30rem !important;
    margin-left: 30rem !important;
  }
  .mx-lg-27 {
    margin-right: 31rem !important;
    margin-left: 31rem !important;
  }
  .mx-lg-28 {
    margin-right: 32rem !important;
    margin-left: 32rem !important;
  }
  .mx-lg-29 {
    margin-right: 33rem !important;
    margin-left: 33rem !important;
  }
  .mx-lg-30 {
    margin-right: 34rem !important;
    margin-left: 34rem !important;
  }
  .mx-lg-nav {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  .my-lg-3 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important;
  }
  .my-lg-35 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-lg-45 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-55 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-lg-6 {
    margin-top: 3.85rem !important;
    margin-bottom: 3.85rem !important;
  }
  .my-lg-65 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-68 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-lg-7 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-lg-75 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-lg-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-lg-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-lg-95 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-lg-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-lg-11 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-lg-12 {
    margin-top: 16.5rem !important;
    margin-bottom: 16.5rem !important;
  }
  .my-lg-13 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-lg-14 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-lg-15 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-lg-16 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-lg-17 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-lg-18 {
    margin-top: 22rem !important;
    margin-bottom: 22rem !important;
  }
  .my-lg-19 {
    margin-top: 23rem !important;
    margin-bottom: 23rem !important;
  }
  .my-lg-20 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }
  .my-lg-21 {
    margin-top: 25rem !important;
    margin-bottom: 25rem !important;
  }
  .my-lg-22 {
    margin-top: 26rem !important;
    margin-bottom: 26rem !important;
  }
  .my-lg-23 {
    margin-top: 27rem !important;
    margin-bottom: 27rem !important;
  }
  .my-lg-24 {
    margin-top: 28rem !important;
    margin-bottom: 28rem !important;
  }
  .my-lg-25 {
    margin-top: 29rem !important;
    margin-bottom: 29rem !important;
  }
  .my-lg-26 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important;
  }
  .my-lg-27 {
    margin-top: 31rem !important;
    margin-bottom: 31rem !important;
  }
  .my-lg-28 {
    margin-top: 32rem !important;
    margin-bottom: 32rem !important;
  }
  .my-lg-29 {
    margin-top: 33rem !important;
    margin-bottom: 33rem !important;
  }
  .my-lg-30 {
    margin-top: 34rem !important;
    margin-bottom: 34rem !important;
  }
  .my-lg-nav {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.4rem !important;
  }
  .mt-lg-3 {
    margin-top: 0.8rem !important;
  }
  .mt-lg-35 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.875rem !important;
  }
  .mt-lg-45 {
    margin-top: 2.25rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-55 {
    margin-top: 3.5rem !important;
  }
  .mt-lg-6 {
    margin-top: 3.85rem !important;
  }
  .mt-lg-65 {
    margin-top: 5rem !important;
  }
  .mt-lg-68 {
    margin-top: 5.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 6.5rem !important;
  }
  .mt-lg-75 {
    margin-top: 7rem !important;
  }
  .mt-lg-8 {
    margin-top: 8rem !important;
  }
  .mt-lg-9 {
    margin-top: 10rem !important;
  }
  .mt-lg-95 {
    margin-top: 12rem !important;
  }
  .mt-lg-10 {
    margin-top: 14rem !important;
  }
  .mt-lg-11 {
    margin-top: 21rem !important;
  }
  .mt-lg-12 {
    margin-top: 16.5rem !important;
  }
  .mt-lg-13 {
    margin-top: 17rem !important;
  }
  .mt-lg-14 {
    margin-top: 18rem !important;
  }
  .mt-lg-15 {
    margin-top: 19rem !important;
  }
  .mt-lg-16 {
    margin-top: 20rem !important;
  }
  .mt-lg-17 {
    margin-top: 21rem !important;
  }
  .mt-lg-18 {
    margin-top: 22rem !important;
  }
  .mt-lg-19 {
    margin-top: 23rem !important;
  }
  .mt-lg-20 {
    margin-top: 24rem !important;
  }
  .mt-lg-21 {
    margin-top: 25rem !important;
  }
  .mt-lg-22 {
    margin-top: 26rem !important;
  }
  .mt-lg-23 {
    margin-top: 27rem !important;
  }
  .mt-lg-24 {
    margin-top: 28rem !important;
  }
  .mt-lg-25 {
    margin-top: 29rem !important;
  }
  .mt-lg-26 {
    margin-top: 30rem !important;
  }
  .mt-lg-27 {
    margin-top: 31rem !important;
  }
  .mt-lg-28 {
    margin-top: 32rem !important;
  }
  .mt-lg-29 {
    margin-top: 33rem !important;
  }
  .mt-lg-30 {
    margin-top: 34rem !important;
  }
  .mt-lg-nav {
    margin-top: 0.5rem !important;
  }
  .mt-lg-100 {
    margin-top: 6.25rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.4rem !important;
  }
  .me-lg-3 {
    margin-right: 0.8rem !important;
  }
  .me-lg-35 {
    margin-right: 1.25rem !important;
  }
  .me-lg-4 {
    margin-right: 1.875rem !important;
  }
  .me-lg-45 {
    margin-right: 2.25rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-55 {
    margin-right: 3.5rem !important;
  }
  .me-lg-6 {
    margin-right: 3.85rem !important;
  }
  .me-lg-65 {
    margin-right: 5rem !important;
  }
  .me-lg-68 {
    margin-right: 5.5rem !important;
  }
  .me-lg-7 {
    margin-right: 6.5rem !important;
  }
  .me-lg-75 {
    margin-right: 7rem !important;
  }
  .me-lg-8 {
    margin-right: 8rem !important;
  }
  .me-lg-9 {
    margin-right: 10rem !important;
  }
  .me-lg-95 {
    margin-right: 12rem !important;
  }
  .me-lg-10 {
    margin-right: 14rem !important;
  }
  .me-lg-11 {
    margin-right: 21rem !important;
  }
  .me-lg-12 {
    margin-right: 16.5rem !important;
  }
  .me-lg-13 {
    margin-right: 17rem !important;
  }
  .me-lg-14 {
    margin-right: 18rem !important;
  }
  .me-lg-15 {
    margin-right: 19rem !important;
  }
  .me-lg-16 {
    margin-right: 20rem !important;
  }
  .me-lg-17 {
    margin-right: 21rem !important;
  }
  .me-lg-18 {
    margin-right: 22rem !important;
  }
  .me-lg-19 {
    margin-right: 23rem !important;
  }
  .me-lg-20 {
    margin-right: 24rem !important;
  }
  .me-lg-21 {
    margin-right: 25rem !important;
  }
  .me-lg-22 {
    margin-right: 26rem !important;
  }
  .me-lg-23 {
    margin-right: 27rem !important;
  }
  .me-lg-24 {
    margin-right: 28rem !important;
  }
  .me-lg-25 {
    margin-right: 29rem !important;
  }
  .me-lg-26 {
    margin-right: 30rem !important;
  }
  .me-lg-27 {
    margin-right: 31rem !important;
  }
  .me-lg-28 {
    margin-right: 32rem !important;
  }
  .me-lg-29 {
    margin-right: 33rem !important;
  }
  .me-lg-30 {
    margin-right: 34rem !important;
  }
  .me-lg-nav {
    margin-right: 0.5rem !important;
  }
  .me-lg-100 {
    margin-right: 6.25rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.4rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 0.8rem !important;
  }
  .mb-lg-35 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-lg-45 {
    margin-bottom: 2.25rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-55 {
    margin-bottom: 3.5rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 3.85rem !important;
  }
  .mb-lg-65 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-68 {
    margin-bottom: 5.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 6.5rem !important;
  }
  .mb-lg-75 {
    margin-bottom: 7rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 8rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-95 {
    margin-bottom: 12rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 14rem !important;
  }
  .mb-lg-11 {
    margin-bottom: 21rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 16.5rem !important;
  }
  .mb-lg-13 {
    margin-bottom: 17rem !important;
  }
  .mb-lg-14 {
    margin-bottom: 18rem !important;
  }
  .mb-lg-15 {
    margin-bottom: 19rem !important;
  }
  .mb-lg-16 {
    margin-bottom: 20rem !important;
  }
  .mb-lg-17 {
    margin-bottom: 21rem !important;
  }
  .mb-lg-18 {
    margin-bottom: 22rem !important;
  }
  .mb-lg-19 {
    margin-bottom: 23rem !important;
  }
  .mb-lg-20 {
    margin-bottom: 24rem !important;
  }
  .mb-lg-21 {
    margin-bottom: 25rem !important;
  }
  .mb-lg-22 {
    margin-bottom: 26rem !important;
  }
  .mb-lg-23 {
    margin-bottom: 27rem !important;
  }
  .mb-lg-24 {
    margin-bottom: 28rem !important;
  }
  .mb-lg-25 {
    margin-bottom: 29rem !important;
  }
  .mb-lg-26 {
    margin-bottom: 30rem !important;
  }
  .mb-lg-27 {
    margin-bottom: 31rem !important;
  }
  .mb-lg-28 {
    margin-bottom: 32rem !important;
  }
  .mb-lg-29 {
    margin-bottom: 33rem !important;
  }
  .mb-lg-30 {
    margin-bottom: 34rem !important;
  }
  .mb-lg-nav {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.4rem !important;
  }
  .ms-lg-3 {
    margin-left: 0.8rem !important;
  }
  .ms-lg-35 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.875rem !important;
  }
  .ms-lg-45 {
    margin-left: 2.25rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-55 {
    margin-left: 3.5rem !important;
  }
  .ms-lg-6 {
    margin-left: 3.85rem !important;
  }
  .ms-lg-65 {
    margin-left: 5rem !important;
  }
  .ms-lg-68 {
    margin-left: 5.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 6.5rem !important;
  }
  .ms-lg-75 {
    margin-left: 7rem !important;
  }
  .ms-lg-8 {
    margin-left: 8rem !important;
  }
  .ms-lg-9 {
    margin-left: 10rem !important;
  }
  .ms-lg-95 {
    margin-left: 12rem !important;
  }
  .ms-lg-10 {
    margin-left: 14rem !important;
  }
  .ms-lg-11 {
    margin-left: 21rem !important;
  }
  .ms-lg-12 {
    margin-left: 16.5rem !important;
  }
  .ms-lg-13 {
    margin-left: 17rem !important;
  }
  .ms-lg-14 {
    margin-left: 18rem !important;
  }
  .ms-lg-15 {
    margin-left: 19rem !important;
  }
  .ms-lg-16 {
    margin-left: 20rem !important;
  }
  .ms-lg-17 {
    margin-left: 21rem !important;
  }
  .ms-lg-18 {
    margin-left: 22rem !important;
  }
  .ms-lg-19 {
    margin-left: 23rem !important;
  }
  .ms-lg-20 {
    margin-left: 24rem !important;
  }
  .ms-lg-21 {
    margin-left: 25rem !important;
  }
  .ms-lg-22 {
    margin-left: 26rem !important;
  }
  .ms-lg-23 {
    margin-left: 27rem !important;
  }
  .ms-lg-24 {
    margin-left: 28rem !important;
  }
  .ms-lg-25 {
    margin-left: 29rem !important;
  }
  .ms-lg-26 {
    margin-left: 30rem !important;
  }
  .ms-lg-27 {
    margin-left: 31rem !important;
  }
  .ms-lg-28 {
    margin-left: 32rem !important;
  }
  .ms-lg-29 {
    margin-left: 33rem !important;
  }
  .ms-lg-30 {
    margin-left: 34rem !important;
  }
  .ms-lg-nav {
    margin-left: 0.5rem !important;
  }
  .ms-lg-100 {
    margin-left: 6.25rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.4rem !important;
  }
  .p-lg-3 {
    padding: 0.8rem !important;
  }
  .p-lg-35 {
    padding: 1.25rem !important;
  }
  .p-lg-4 {
    padding: 1.875rem !important;
  }
  .p-lg-45 {
    padding: 2.25rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-55 {
    padding: 3.5rem !important;
  }
  .p-lg-6 {
    padding: 3.85rem !important;
  }
  .p-lg-65 {
    padding: 5rem !important;
  }
  .p-lg-68 {
    padding: 5.5rem !important;
  }
  .p-lg-7 {
    padding: 6.5rem !important;
  }
  .p-lg-75 {
    padding: 7rem !important;
  }
  .p-lg-8 {
    padding: 8rem !important;
  }
  .p-lg-9 {
    padding: 10rem !important;
  }
  .p-lg-95 {
    padding: 12rem !important;
  }
  .p-lg-10 {
    padding: 14rem !important;
  }
  .p-lg-11 {
    padding: 21rem !important;
  }
  .p-lg-12 {
    padding: 16.5rem !important;
  }
  .p-lg-13 {
    padding: 17rem !important;
  }
  .p-lg-14 {
    padding: 18rem !important;
  }
  .p-lg-15 {
    padding: 19rem !important;
  }
  .p-lg-16 {
    padding: 20rem !important;
  }
  .p-lg-17 {
    padding: 21rem !important;
  }
  .p-lg-18 {
    padding: 22rem !important;
  }
  .p-lg-19 {
    padding: 23rem !important;
  }
  .p-lg-20 {
    padding: 24rem !important;
  }
  .p-lg-21 {
    padding: 25rem !important;
  }
  .p-lg-22 {
    padding: 26rem !important;
  }
  .p-lg-23 {
    padding: 27rem !important;
  }
  .p-lg-24 {
    padding: 28rem !important;
  }
  .p-lg-25 {
    padding: 29rem !important;
  }
  .p-lg-26 {
    padding: 30rem !important;
  }
  .p-lg-27 {
    padding: 31rem !important;
  }
  .p-lg-28 {
    padding: 32rem !important;
  }
  .p-lg-29 {
    padding: 33rem !important;
  }
  .p-lg-30 {
    padding: 34rem !important;
  }
  .p-lg-nav {
    padding: 0.5rem !important;
  }
  .p-lg-100 {
    padding: 6.25rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
  }
  .px-lg-3 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important;
  }
  .px-lg-35 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-lg-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-lg-45 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-55 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-lg-6 {
    padding-right: 3.85rem !important;
    padding-left: 3.85rem !important;
  }
  .px-lg-65 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-68 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-lg-7 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-lg-75 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-lg-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-lg-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-lg-95 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-lg-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-lg-11 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-lg-12 {
    padding-right: 16.5rem !important;
    padding-left: 16.5rem !important;
  }
  .px-lg-13 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-lg-14 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-lg-15 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-lg-16 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-lg-17 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-lg-18 {
    padding-right: 22rem !important;
    padding-left: 22rem !important;
  }
  .px-lg-19 {
    padding-right: 23rem !important;
    padding-left: 23rem !important;
  }
  .px-lg-20 {
    padding-right: 24rem !important;
    padding-left: 24rem !important;
  }
  .px-lg-21 {
    padding-right: 25rem !important;
    padding-left: 25rem !important;
  }
  .px-lg-22 {
    padding-right: 26rem !important;
    padding-left: 26rem !important;
  }
  .px-lg-23 {
    padding-right: 27rem !important;
    padding-left: 27rem !important;
  }
  .px-lg-24 {
    padding-right: 28rem !important;
    padding-left: 28rem !important;
  }
  .px-lg-25 {
    padding-right: 29rem !important;
    padding-left: 29rem !important;
  }
  .px-lg-26 {
    padding-right: 30rem !important;
    padding-left: 30rem !important;
  }
  .px-lg-27 {
    padding-right: 31rem !important;
    padding-left: 31rem !important;
  }
  .px-lg-28 {
    padding-right: 32rem !important;
    padding-left: 32rem !important;
  }
  .px-lg-29 {
    padding-right: 33rem !important;
    padding-left: 33rem !important;
  }
  .px-lg-30 {
    padding-right: 34rem !important;
    padding-left: 34rem !important;
  }
  .px-lg-nav {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
  }
  .py-lg-3 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }
  .py-lg-35 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-lg-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-lg-45 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-55 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-lg-6 {
    padding-top: 3.85rem !important;
    padding-bottom: 3.85rem !important;
  }
  .py-lg-65 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-68 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-lg-7 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-lg-75 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-lg-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-lg-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-lg-95 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-lg-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-lg-11 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-lg-12 {
    padding-top: 16.5rem !important;
    padding-bottom: 16.5rem !important;
  }
  .py-lg-13 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-lg-14 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-lg-15 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-lg-16 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-lg-17 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-lg-18 {
    padding-top: 22rem !important;
    padding-bottom: 22rem !important;
  }
  .py-lg-19 {
    padding-top: 23rem !important;
    padding-bottom: 23rem !important;
  }
  .py-lg-20 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
  .py-lg-21 {
    padding-top: 25rem !important;
    padding-bottom: 25rem !important;
  }
  .py-lg-22 {
    padding-top: 26rem !important;
    padding-bottom: 26rem !important;
  }
  .py-lg-23 {
    padding-top: 27rem !important;
    padding-bottom: 27rem !important;
  }
  .py-lg-24 {
    padding-top: 28rem !important;
    padding-bottom: 28rem !important;
  }
  .py-lg-25 {
    padding-top: 29rem !important;
    padding-bottom: 29rem !important;
  }
  .py-lg-26 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important;
  }
  .py-lg-27 {
    padding-top: 31rem !important;
    padding-bottom: 31rem !important;
  }
  .py-lg-28 {
    padding-top: 32rem !important;
    padding-bottom: 32rem !important;
  }
  .py-lg-29 {
    padding-top: 33rem !important;
    padding-bottom: 33rem !important;
  }
  .py-lg-30 {
    padding-top: 34rem !important;
    padding-bottom: 34rem !important;
  }
  .py-lg-nav {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.4rem !important;
  }
  .pt-lg-3 {
    padding-top: 0.8rem !important;
  }
  .pt-lg-35 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.875rem !important;
  }
  .pt-lg-45 {
    padding-top: 2.25rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-55 {
    padding-top: 3.5rem !important;
  }
  .pt-lg-6 {
    padding-top: 3.85rem !important;
  }
  .pt-lg-65 {
    padding-top: 5rem !important;
  }
  .pt-lg-68 {
    padding-top: 5.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 6.5rem !important;
  }
  .pt-lg-75 {
    padding-top: 7rem !important;
  }
  .pt-lg-8 {
    padding-top: 8rem !important;
  }
  .pt-lg-9 {
    padding-top: 10rem !important;
  }
  .pt-lg-95 {
    padding-top: 12rem !important;
  }
  .pt-lg-10 {
    padding-top: 14rem !important;
  }
  .pt-lg-11 {
    padding-top: 21rem !important;
  }
  .pt-lg-12 {
    padding-top: 16.5rem !important;
  }
  .pt-lg-13 {
    padding-top: 17rem !important;
  }
  .pt-lg-14 {
    padding-top: 18rem !important;
  }
  .pt-lg-15 {
    padding-top: 19rem !important;
  }
  .pt-lg-16 {
    padding-top: 20rem !important;
  }
  .pt-lg-17 {
    padding-top: 21rem !important;
  }
  .pt-lg-18 {
    padding-top: 22rem !important;
  }
  .pt-lg-19 {
    padding-top: 23rem !important;
  }
  .pt-lg-20 {
    padding-top: 24rem !important;
  }
  .pt-lg-21 {
    padding-top: 25rem !important;
  }
  .pt-lg-22 {
    padding-top: 26rem !important;
  }
  .pt-lg-23 {
    padding-top: 27rem !important;
  }
  .pt-lg-24 {
    padding-top: 28rem !important;
  }
  .pt-lg-25 {
    padding-top: 29rem !important;
  }
  .pt-lg-26 {
    padding-top: 30rem !important;
  }
  .pt-lg-27 {
    padding-top: 31rem !important;
  }
  .pt-lg-28 {
    padding-top: 32rem !important;
  }
  .pt-lg-29 {
    padding-top: 33rem !important;
  }
  .pt-lg-30 {
    padding-top: 34rem !important;
  }
  .pt-lg-nav {
    padding-top: 0.5rem !important;
  }
  .pt-lg-100 {
    padding-top: 6.25rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.4rem !important;
  }
  .pe-lg-3 {
    padding-right: 0.8rem !important;
  }
  .pe-lg-35 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.875rem !important;
  }
  .pe-lg-45 {
    padding-right: 2.25rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-55 {
    padding-right: 3.5rem !important;
  }
  .pe-lg-6 {
    padding-right: 3.85rem !important;
  }
  .pe-lg-65 {
    padding-right: 5rem !important;
  }
  .pe-lg-68 {
    padding-right: 5.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 6.5rem !important;
  }
  .pe-lg-75 {
    padding-right: 7rem !important;
  }
  .pe-lg-8 {
    padding-right: 8rem !important;
  }
  .pe-lg-9 {
    padding-right: 10rem !important;
  }
  .pe-lg-95 {
    padding-right: 12rem !important;
  }
  .pe-lg-10 {
    padding-right: 14rem !important;
  }
  .pe-lg-11 {
    padding-right: 21rem !important;
  }
  .pe-lg-12 {
    padding-right: 16.5rem !important;
  }
  .pe-lg-13 {
    padding-right: 17rem !important;
  }
  .pe-lg-14 {
    padding-right: 18rem !important;
  }
  .pe-lg-15 {
    padding-right: 19rem !important;
  }
  .pe-lg-16 {
    padding-right: 20rem !important;
  }
  .pe-lg-17 {
    padding-right: 21rem !important;
  }
  .pe-lg-18 {
    padding-right: 22rem !important;
  }
  .pe-lg-19 {
    padding-right: 23rem !important;
  }
  .pe-lg-20 {
    padding-right: 24rem !important;
  }
  .pe-lg-21 {
    padding-right: 25rem !important;
  }
  .pe-lg-22 {
    padding-right: 26rem !important;
  }
  .pe-lg-23 {
    padding-right: 27rem !important;
  }
  .pe-lg-24 {
    padding-right: 28rem !important;
  }
  .pe-lg-25 {
    padding-right: 29rem !important;
  }
  .pe-lg-26 {
    padding-right: 30rem !important;
  }
  .pe-lg-27 {
    padding-right: 31rem !important;
  }
  .pe-lg-28 {
    padding-right: 32rem !important;
  }
  .pe-lg-29 {
    padding-right: 33rem !important;
  }
  .pe-lg-30 {
    padding-right: 34rem !important;
  }
  .pe-lg-nav {
    padding-right: 0.5rem !important;
  }
  .pe-lg-100 {
    padding-right: 6.25rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.4rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 0.8rem !important;
  }
  .pb-lg-35 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-lg-45 {
    padding-bottom: 2.25rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-55 {
    padding-bottom: 3.5rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 3.85rem !important;
  }
  .pb-lg-65 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-68 {
    padding-bottom: 5.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 6.5rem !important;
  }
  .pb-lg-75 {
    padding-bottom: 7rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 8rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 10rem !important;
  }
  .pb-lg-95 {
    padding-bottom: 12rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 14rem !important;
  }
  .pb-lg-11 {
    padding-bottom: 21rem !important;
  }
  .pb-lg-12 {
    padding-bottom: 16.5rem !important;
  }
  .pb-lg-13 {
    padding-bottom: 17rem !important;
  }
  .pb-lg-14 {
    padding-bottom: 18rem !important;
  }
  .pb-lg-15 {
    padding-bottom: 19rem !important;
  }
  .pb-lg-16 {
    padding-bottom: 20rem !important;
  }
  .pb-lg-17 {
    padding-bottom: 21rem !important;
  }
  .pb-lg-18 {
    padding-bottom: 22rem !important;
  }
  .pb-lg-19 {
    padding-bottom: 23rem !important;
  }
  .pb-lg-20 {
    padding-bottom: 24rem !important;
  }
  .pb-lg-21 {
    padding-bottom: 25rem !important;
  }
  .pb-lg-22 {
    padding-bottom: 26rem !important;
  }
  .pb-lg-23 {
    padding-bottom: 27rem !important;
  }
  .pb-lg-24 {
    padding-bottom: 28rem !important;
  }
  .pb-lg-25 {
    padding-bottom: 29rem !important;
  }
  .pb-lg-26 {
    padding-bottom: 30rem !important;
  }
  .pb-lg-27 {
    padding-bottom: 31rem !important;
  }
  .pb-lg-28 {
    padding-bottom: 32rem !important;
  }
  .pb-lg-29 {
    padding-bottom: 33rem !important;
  }
  .pb-lg-30 {
    padding-bottom: 34rem !important;
  }
  .pb-lg-nav {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-100 {
    padding-bottom: 6.25rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.4rem !important;
  }
  .ps-lg-3 {
    padding-left: 0.8rem !important;
  }
  .ps-lg-35 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.875rem !important;
  }
  .ps-lg-45 {
    padding-left: 2.25rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-55 {
    padding-left: 3.5rem !important;
  }
  .ps-lg-6 {
    padding-left: 3.85rem !important;
  }
  .ps-lg-65 {
    padding-left: 5rem !important;
  }
  .ps-lg-68 {
    padding-left: 5.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 6.5rem !important;
  }
  .ps-lg-75 {
    padding-left: 7rem !important;
  }
  .ps-lg-8 {
    padding-left: 8rem !important;
  }
  .ps-lg-9 {
    padding-left: 10rem !important;
  }
  .ps-lg-95 {
    padding-left: 12rem !important;
  }
  .ps-lg-10 {
    padding-left: 14rem !important;
  }
  .ps-lg-11 {
    padding-left: 21rem !important;
  }
  .ps-lg-12 {
    padding-left: 16.5rem !important;
  }
  .ps-lg-13 {
    padding-left: 17rem !important;
  }
  .ps-lg-14 {
    padding-left: 18rem !important;
  }
  .ps-lg-15 {
    padding-left: 19rem !important;
  }
  .ps-lg-16 {
    padding-left: 20rem !important;
  }
  .ps-lg-17 {
    padding-left: 21rem !important;
  }
  .ps-lg-18 {
    padding-left: 22rem !important;
  }
  .ps-lg-19 {
    padding-left: 23rem !important;
  }
  .ps-lg-20 {
    padding-left: 24rem !important;
  }
  .ps-lg-21 {
    padding-left: 25rem !important;
  }
  .ps-lg-22 {
    padding-left: 26rem !important;
  }
  .ps-lg-23 {
    padding-left: 27rem !important;
  }
  .ps-lg-24 {
    padding-left: 28rem !important;
  }
  .ps-lg-25 {
    padding-left: 29rem !important;
  }
  .ps-lg-26 {
    padding-left: 30rem !important;
  }
  .ps-lg-27 {
    padding-left: 31rem !important;
  }
  .ps-lg-28 {
    padding-left: 32rem !important;
  }
  .ps-lg-29 {
    padding-left: 33rem !important;
  }
  .ps-lg-30 {
    padding-left: 34rem !important;
  }
  .ps-lg-nav {
    padding-left: 0.5rem !important;
  }
  .ps-lg-100 {
    padding-left: 6.25rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.4rem !important;
  }
  .gap-lg-3 {
    gap: 0.8rem !important;
  }
  .gap-lg-35 {
    gap: 1.25rem !important;
  }
  .gap-lg-4 {
    gap: 1.875rem !important;
  }
  .gap-lg-45 {
    gap: 2.25rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-lg-55 {
    gap: 3.5rem !important;
  }
  .gap-lg-6 {
    gap: 3.85rem !important;
  }
  .gap-lg-65 {
    gap: 5rem !important;
  }
  .gap-lg-68 {
    gap: 5.5rem !important;
  }
  .gap-lg-7 {
    gap: 6.5rem !important;
  }
  .gap-lg-75 {
    gap: 7rem !important;
  }
  .gap-lg-8 {
    gap: 8rem !important;
  }
  .gap-lg-9 {
    gap: 10rem !important;
  }
  .gap-lg-95 {
    gap: 12rem !important;
  }
  .gap-lg-10 {
    gap: 14rem !important;
  }
  .gap-lg-11 {
    gap: 21rem !important;
  }
  .gap-lg-12 {
    gap: 16.5rem !important;
  }
  .gap-lg-13 {
    gap: 17rem !important;
  }
  .gap-lg-14 {
    gap: 18rem !important;
  }
  .gap-lg-15 {
    gap: 19rem !important;
  }
  .gap-lg-16 {
    gap: 20rem !important;
  }
  .gap-lg-17 {
    gap: 21rem !important;
  }
  .gap-lg-18 {
    gap: 22rem !important;
  }
  .gap-lg-19 {
    gap: 23rem !important;
  }
  .gap-lg-20 {
    gap: 24rem !important;
  }
  .gap-lg-21 {
    gap: 25rem !important;
  }
  .gap-lg-22 {
    gap: 26rem !important;
  }
  .gap-lg-23 {
    gap: 27rem !important;
  }
  .gap-lg-24 {
    gap: 28rem !important;
  }
  .gap-lg-25 {
    gap: 29rem !important;
  }
  .gap-lg-26 {
    gap: 30rem !important;
  }
  .gap-lg-27 {
    gap: 31rem !important;
  }
  .gap-lg-28 {
    gap: 32rem !important;
  }
  .gap-lg-29 {
    gap: 33rem !important;
  }
  .gap-lg-30 {
    gap: 34rem !important;
  }
  .gap-lg-nav {
    gap: 0.5rem !important;
  }
  .gap-lg-100 {
    gap: 6.25rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.4rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 0.8rem !important;
  }
  .row-gap-lg-35 {
    row-gap: 1.25rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.875rem !important;
  }
  .row-gap-lg-45 {
    row-gap: 2.25rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .row-gap-lg-55 {
    row-gap: 3.5rem !important;
  }
  .row-gap-lg-6 {
    row-gap: 3.85rem !important;
  }
  .row-gap-lg-65 {
    row-gap: 5rem !important;
  }
  .row-gap-lg-68 {
    row-gap: 5.5rem !important;
  }
  .row-gap-lg-7 {
    row-gap: 6.5rem !important;
  }
  .row-gap-lg-75 {
    row-gap: 7rem !important;
  }
  .row-gap-lg-8 {
    row-gap: 8rem !important;
  }
  .row-gap-lg-9 {
    row-gap: 10rem !important;
  }
  .row-gap-lg-95 {
    row-gap: 12rem !important;
  }
  .row-gap-lg-10 {
    row-gap: 14rem !important;
  }
  .row-gap-lg-11 {
    row-gap: 21rem !important;
  }
  .row-gap-lg-12 {
    row-gap: 16.5rem !important;
  }
  .row-gap-lg-13 {
    row-gap: 17rem !important;
  }
  .row-gap-lg-14 {
    row-gap: 18rem !important;
  }
  .row-gap-lg-15 {
    row-gap: 19rem !important;
  }
  .row-gap-lg-16 {
    row-gap: 20rem !important;
  }
  .row-gap-lg-17 {
    row-gap: 21rem !important;
  }
  .row-gap-lg-18 {
    row-gap: 22rem !important;
  }
  .row-gap-lg-19 {
    row-gap: 23rem !important;
  }
  .row-gap-lg-20 {
    row-gap: 24rem !important;
  }
  .row-gap-lg-21 {
    row-gap: 25rem !important;
  }
  .row-gap-lg-22 {
    row-gap: 26rem !important;
  }
  .row-gap-lg-23 {
    row-gap: 27rem !important;
  }
  .row-gap-lg-24 {
    row-gap: 28rem !important;
  }
  .row-gap-lg-25 {
    row-gap: 29rem !important;
  }
  .row-gap-lg-26 {
    row-gap: 30rem !important;
  }
  .row-gap-lg-27 {
    row-gap: 31rem !important;
  }
  .row-gap-lg-28 {
    row-gap: 32rem !important;
  }
  .row-gap-lg-29 {
    row-gap: 33rem !important;
  }
  .row-gap-lg-30 {
    row-gap: 34rem !important;
  }
  .row-gap-lg-nav {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-100 {
    row-gap: 6.25rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.4rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 0.8rem !important;
  }
  .column-gap-lg-35 {
    column-gap: 1.25rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.875rem !important;
  }
  .column-gap-lg-45 {
    column-gap: 2.25rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .column-gap-lg-55 {
    column-gap: 3.5rem !important;
  }
  .column-gap-lg-6 {
    column-gap: 3.85rem !important;
  }
  .column-gap-lg-65 {
    column-gap: 5rem !important;
  }
  .column-gap-lg-68 {
    column-gap: 5.5rem !important;
  }
  .column-gap-lg-7 {
    column-gap: 6.5rem !important;
  }
  .column-gap-lg-75 {
    column-gap: 7rem !important;
  }
  .column-gap-lg-8 {
    column-gap: 8rem !important;
  }
  .column-gap-lg-9 {
    column-gap: 10rem !important;
  }
  .column-gap-lg-95 {
    column-gap: 12rem !important;
  }
  .column-gap-lg-10 {
    column-gap: 14rem !important;
  }
  .column-gap-lg-11 {
    column-gap: 21rem !important;
  }
  .column-gap-lg-12 {
    column-gap: 16.5rem !important;
  }
  .column-gap-lg-13 {
    column-gap: 17rem !important;
  }
  .column-gap-lg-14 {
    column-gap: 18rem !important;
  }
  .column-gap-lg-15 {
    column-gap: 19rem !important;
  }
  .column-gap-lg-16 {
    column-gap: 20rem !important;
  }
  .column-gap-lg-17 {
    column-gap: 21rem !important;
  }
  .column-gap-lg-18 {
    column-gap: 22rem !important;
  }
  .column-gap-lg-19 {
    column-gap: 23rem !important;
  }
  .column-gap-lg-20 {
    column-gap: 24rem !important;
  }
  .column-gap-lg-21 {
    column-gap: 25rem !important;
  }
  .column-gap-lg-22 {
    column-gap: 26rem !important;
  }
  .column-gap-lg-23 {
    column-gap: 27rem !important;
  }
  .column-gap-lg-24 {
    column-gap: 28rem !important;
  }
  .column-gap-lg-25 {
    column-gap: 29rem !important;
  }
  .column-gap-lg-26 {
    column-gap: 30rem !important;
  }
  .column-gap-lg-27 {
    column-gap: 31rem !important;
  }
  .column-gap-lg-28 {
    column-gap: 32rem !important;
  }
  .column-gap-lg-29 {
    column-gap: 33rem !important;
  }
  .column-gap-lg-30 {
    column-gap: 34rem !important;
  }
  .column-gap-lg-nav {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-100 {
    column-gap: 6.25rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.4rem !important;
  }
  .m-xl-3 {
    margin: 0.8rem !important;
  }
  .m-xl-35 {
    margin: 1.25rem !important;
  }
  .m-xl-4 {
    margin: 1.875rem !important;
  }
  .m-xl-45 {
    margin: 2.25rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-55 {
    margin: 3.5rem !important;
  }
  .m-xl-6 {
    margin: 3.85rem !important;
  }
  .m-xl-65 {
    margin: 5rem !important;
  }
  .m-xl-68 {
    margin: 5.5rem !important;
  }
  .m-xl-7 {
    margin: 6.5rem !important;
  }
  .m-xl-75 {
    margin: 7rem !important;
  }
  .m-xl-8 {
    margin: 8rem !important;
  }
  .m-xl-9 {
    margin: 10rem !important;
  }
  .m-xl-95 {
    margin: 12rem !important;
  }
  .m-xl-10 {
    margin: 14rem !important;
  }
  .m-xl-11 {
    margin: 21rem !important;
  }
  .m-xl-12 {
    margin: 16.5rem !important;
  }
  .m-xl-13 {
    margin: 17rem !important;
  }
  .m-xl-14 {
    margin: 18rem !important;
  }
  .m-xl-15 {
    margin: 19rem !important;
  }
  .m-xl-16 {
    margin: 20rem !important;
  }
  .m-xl-17 {
    margin: 21rem !important;
  }
  .m-xl-18 {
    margin: 22rem !important;
  }
  .m-xl-19 {
    margin: 23rem !important;
  }
  .m-xl-20 {
    margin: 24rem !important;
  }
  .m-xl-21 {
    margin: 25rem !important;
  }
  .m-xl-22 {
    margin: 26rem !important;
  }
  .m-xl-23 {
    margin: 27rem !important;
  }
  .m-xl-24 {
    margin: 28rem !important;
  }
  .m-xl-25 {
    margin: 29rem !important;
  }
  .m-xl-26 {
    margin: 30rem !important;
  }
  .m-xl-27 {
    margin: 31rem !important;
  }
  .m-xl-28 {
    margin: 32rem !important;
  }
  .m-xl-29 {
    margin: 33rem !important;
  }
  .m-xl-30 {
    margin: 34rem !important;
  }
  .m-xl-nav {
    margin: 0.5rem !important;
  }
  .m-xl-100 {
    margin: 6.25rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important;
  }
  .mx-xl-3 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important;
  }
  .mx-xl-35 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xl-45 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-55 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xl-6 {
    margin-right: 3.85rem !important;
    margin-left: 3.85rem !important;
  }
  .mx-xl-65 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-68 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-xl-7 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-xl-75 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xl-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xl-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xl-95 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-xl-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-xl-11 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-xl-12 {
    margin-right: 16.5rem !important;
    margin-left: 16.5rem !important;
  }
  .mx-xl-13 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-xl-14 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-xl-15 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-xl-16 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-xl-17 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-xl-18 {
    margin-right: 22rem !important;
    margin-left: 22rem !important;
  }
  .mx-xl-19 {
    margin-right: 23rem !important;
    margin-left: 23rem !important;
  }
  .mx-xl-20 {
    margin-right: 24rem !important;
    margin-left: 24rem !important;
  }
  .mx-xl-21 {
    margin-right: 25rem !important;
    margin-left: 25rem !important;
  }
  .mx-xl-22 {
    margin-right: 26rem !important;
    margin-left: 26rem !important;
  }
  .mx-xl-23 {
    margin-right: 27rem !important;
    margin-left: 27rem !important;
  }
  .mx-xl-24 {
    margin-right: 28rem !important;
    margin-left: 28rem !important;
  }
  .mx-xl-25 {
    margin-right: 29rem !important;
    margin-left: 29rem !important;
  }
  .mx-xl-26 {
    margin-right: 30rem !important;
    margin-left: 30rem !important;
  }
  .mx-xl-27 {
    margin-right: 31rem !important;
    margin-left: 31rem !important;
  }
  .mx-xl-28 {
    margin-right: 32rem !important;
    margin-left: 32rem !important;
  }
  .mx-xl-29 {
    margin-right: 33rem !important;
    margin-left: 33rem !important;
  }
  .mx-xl-30 {
    margin-right: 34rem !important;
    margin-left: 34rem !important;
  }
  .mx-xl-nav {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  .my-xl-3 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important;
  }
  .my-xl-35 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xl-45 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-55 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xl-6 {
    margin-top: 3.85rem !important;
    margin-bottom: 3.85rem !important;
  }
  .my-xl-65 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-68 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-xl-7 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-xl-75 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xl-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xl-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xl-95 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-xl-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-xl-11 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-xl-12 {
    margin-top: 16.5rem !important;
    margin-bottom: 16.5rem !important;
  }
  .my-xl-13 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-xl-14 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-xl-15 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-xl-16 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-xl-17 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-xl-18 {
    margin-top: 22rem !important;
    margin-bottom: 22rem !important;
  }
  .my-xl-19 {
    margin-top: 23rem !important;
    margin-bottom: 23rem !important;
  }
  .my-xl-20 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }
  .my-xl-21 {
    margin-top: 25rem !important;
    margin-bottom: 25rem !important;
  }
  .my-xl-22 {
    margin-top: 26rem !important;
    margin-bottom: 26rem !important;
  }
  .my-xl-23 {
    margin-top: 27rem !important;
    margin-bottom: 27rem !important;
  }
  .my-xl-24 {
    margin-top: 28rem !important;
    margin-bottom: 28rem !important;
  }
  .my-xl-25 {
    margin-top: 29rem !important;
    margin-bottom: 29rem !important;
  }
  .my-xl-26 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important;
  }
  .my-xl-27 {
    margin-top: 31rem !important;
    margin-bottom: 31rem !important;
  }
  .my-xl-28 {
    margin-top: 32rem !important;
    margin-bottom: 32rem !important;
  }
  .my-xl-29 {
    margin-top: 33rem !important;
    margin-bottom: 33rem !important;
  }
  .my-xl-30 {
    margin-top: 34rem !important;
    margin-bottom: 34rem !important;
  }
  .my-xl-nav {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.4rem !important;
  }
  .mt-xl-3 {
    margin-top: 0.8rem !important;
  }
  .mt-xl-35 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.875rem !important;
  }
  .mt-xl-45 {
    margin-top: 2.25rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-55 {
    margin-top: 3.5rem !important;
  }
  .mt-xl-6 {
    margin-top: 3.85rem !important;
  }
  .mt-xl-65 {
    margin-top: 5rem !important;
  }
  .mt-xl-68 {
    margin-top: 5.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 6.5rem !important;
  }
  .mt-xl-75 {
    margin-top: 7rem !important;
  }
  .mt-xl-8 {
    margin-top: 8rem !important;
  }
  .mt-xl-9 {
    margin-top: 10rem !important;
  }
  .mt-xl-95 {
    margin-top: 12rem !important;
  }
  .mt-xl-10 {
    margin-top: 14rem !important;
  }
  .mt-xl-11 {
    margin-top: 21rem !important;
  }
  .mt-xl-12 {
    margin-top: 16.5rem !important;
  }
  .mt-xl-13 {
    margin-top: 17rem !important;
  }
  .mt-xl-14 {
    margin-top: 18rem !important;
  }
  .mt-xl-15 {
    margin-top: 19rem !important;
  }
  .mt-xl-16 {
    margin-top: 20rem !important;
  }
  .mt-xl-17 {
    margin-top: 21rem !important;
  }
  .mt-xl-18 {
    margin-top: 22rem !important;
  }
  .mt-xl-19 {
    margin-top: 23rem !important;
  }
  .mt-xl-20 {
    margin-top: 24rem !important;
  }
  .mt-xl-21 {
    margin-top: 25rem !important;
  }
  .mt-xl-22 {
    margin-top: 26rem !important;
  }
  .mt-xl-23 {
    margin-top: 27rem !important;
  }
  .mt-xl-24 {
    margin-top: 28rem !important;
  }
  .mt-xl-25 {
    margin-top: 29rem !important;
  }
  .mt-xl-26 {
    margin-top: 30rem !important;
  }
  .mt-xl-27 {
    margin-top: 31rem !important;
  }
  .mt-xl-28 {
    margin-top: 32rem !important;
  }
  .mt-xl-29 {
    margin-top: 33rem !important;
  }
  .mt-xl-30 {
    margin-top: 34rem !important;
  }
  .mt-xl-nav {
    margin-top: 0.5rem !important;
  }
  .mt-xl-100 {
    margin-top: 6.25rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.4rem !important;
  }
  .me-xl-3 {
    margin-right: 0.8rem !important;
  }
  .me-xl-35 {
    margin-right: 1.25rem !important;
  }
  .me-xl-4 {
    margin-right: 1.875rem !important;
  }
  .me-xl-45 {
    margin-right: 2.25rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-55 {
    margin-right: 3.5rem !important;
  }
  .me-xl-6 {
    margin-right: 3.85rem !important;
  }
  .me-xl-65 {
    margin-right: 5rem !important;
  }
  .me-xl-68 {
    margin-right: 5.5rem !important;
  }
  .me-xl-7 {
    margin-right: 6.5rem !important;
  }
  .me-xl-75 {
    margin-right: 7rem !important;
  }
  .me-xl-8 {
    margin-right: 8rem !important;
  }
  .me-xl-9 {
    margin-right: 10rem !important;
  }
  .me-xl-95 {
    margin-right: 12rem !important;
  }
  .me-xl-10 {
    margin-right: 14rem !important;
  }
  .me-xl-11 {
    margin-right: 21rem !important;
  }
  .me-xl-12 {
    margin-right: 16.5rem !important;
  }
  .me-xl-13 {
    margin-right: 17rem !important;
  }
  .me-xl-14 {
    margin-right: 18rem !important;
  }
  .me-xl-15 {
    margin-right: 19rem !important;
  }
  .me-xl-16 {
    margin-right: 20rem !important;
  }
  .me-xl-17 {
    margin-right: 21rem !important;
  }
  .me-xl-18 {
    margin-right: 22rem !important;
  }
  .me-xl-19 {
    margin-right: 23rem !important;
  }
  .me-xl-20 {
    margin-right: 24rem !important;
  }
  .me-xl-21 {
    margin-right: 25rem !important;
  }
  .me-xl-22 {
    margin-right: 26rem !important;
  }
  .me-xl-23 {
    margin-right: 27rem !important;
  }
  .me-xl-24 {
    margin-right: 28rem !important;
  }
  .me-xl-25 {
    margin-right: 29rem !important;
  }
  .me-xl-26 {
    margin-right: 30rem !important;
  }
  .me-xl-27 {
    margin-right: 31rem !important;
  }
  .me-xl-28 {
    margin-right: 32rem !important;
  }
  .me-xl-29 {
    margin-right: 33rem !important;
  }
  .me-xl-30 {
    margin-right: 34rem !important;
  }
  .me-xl-nav {
    margin-right: 0.5rem !important;
  }
  .me-xl-100 {
    margin-right: 6.25rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.4rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 0.8rem !important;
  }
  .mb-xl-35 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xl-45 {
    margin-bottom: 2.25rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-55 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 3.85rem !important;
  }
  .mb-xl-65 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-68 {
    margin-bottom: 5.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 6.5rem !important;
  }
  .mb-xl-75 {
    margin-bottom: 7rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 10rem !important;
  }
  .mb-xl-95 {
    margin-bottom: 12rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 14rem !important;
  }
  .mb-xl-11 {
    margin-bottom: 21rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 16.5rem !important;
  }
  .mb-xl-13 {
    margin-bottom: 17rem !important;
  }
  .mb-xl-14 {
    margin-bottom: 18rem !important;
  }
  .mb-xl-15 {
    margin-bottom: 19rem !important;
  }
  .mb-xl-16 {
    margin-bottom: 20rem !important;
  }
  .mb-xl-17 {
    margin-bottom: 21rem !important;
  }
  .mb-xl-18 {
    margin-bottom: 22rem !important;
  }
  .mb-xl-19 {
    margin-bottom: 23rem !important;
  }
  .mb-xl-20 {
    margin-bottom: 24rem !important;
  }
  .mb-xl-21 {
    margin-bottom: 25rem !important;
  }
  .mb-xl-22 {
    margin-bottom: 26rem !important;
  }
  .mb-xl-23 {
    margin-bottom: 27rem !important;
  }
  .mb-xl-24 {
    margin-bottom: 28rem !important;
  }
  .mb-xl-25 {
    margin-bottom: 29rem !important;
  }
  .mb-xl-26 {
    margin-bottom: 30rem !important;
  }
  .mb-xl-27 {
    margin-bottom: 31rem !important;
  }
  .mb-xl-28 {
    margin-bottom: 32rem !important;
  }
  .mb-xl-29 {
    margin-bottom: 33rem !important;
  }
  .mb-xl-30 {
    margin-bottom: 34rem !important;
  }
  .mb-xl-nav {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.4rem !important;
  }
  .ms-xl-3 {
    margin-left: 0.8rem !important;
  }
  .ms-xl-35 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.875rem !important;
  }
  .ms-xl-45 {
    margin-left: 2.25rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-55 {
    margin-left: 3.5rem !important;
  }
  .ms-xl-6 {
    margin-left: 3.85rem !important;
  }
  .ms-xl-65 {
    margin-left: 5rem !important;
  }
  .ms-xl-68 {
    margin-left: 5.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 6.5rem !important;
  }
  .ms-xl-75 {
    margin-left: 7rem !important;
  }
  .ms-xl-8 {
    margin-left: 8rem !important;
  }
  .ms-xl-9 {
    margin-left: 10rem !important;
  }
  .ms-xl-95 {
    margin-left: 12rem !important;
  }
  .ms-xl-10 {
    margin-left: 14rem !important;
  }
  .ms-xl-11 {
    margin-left: 21rem !important;
  }
  .ms-xl-12 {
    margin-left: 16.5rem !important;
  }
  .ms-xl-13 {
    margin-left: 17rem !important;
  }
  .ms-xl-14 {
    margin-left: 18rem !important;
  }
  .ms-xl-15 {
    margin-left: 19rem !important;
  }
  .ms-xl-16 {
    margin-left: 20rem !important;
  }
  .ms-xl-17 {
    margin-left: 21rem !important;
  }
  .ms-xl-18 {
    margin-left: 22rem !important;
  }
  .ms-xl-19 {
    margin-left: 23rem !important;
  }
  .ms-xl-20 {
    margin-left: 24rem !important;
  }
  .ms-xl-21 {
    margin-left: 25rem !important;
  }
  .ms-xl-22 {
    margin-left: 26rem !important;
  }
  .ms-xl-23 {
    margin-left: 27rem !important;
  }
  .ms-xl-24 {
    margin-left: 28rem !important;
  }
  .ms-xl-25 {
    margin-left: 29rem !important;
  }
  .ms-xl-26 {
    margin-left: 30rem !important;
  }
  .ms-xl-27 {
    margin-left: 31rem !important;
  }
  .ms-xl-28 {
    margin-left: 32rem !important;
  }
  .ms-xl-29 {
    margin-left: 33rem !important;
  }
  .ms-xl-30 {
    margin-left: 34rem !important;
  }
  .ms-xl-nav {
    margin-left: 0.5rem !important;
  }
  .ms-xl-100 {
    margin-left: 6.25rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.4rem !important;
  }
  .p-xl-3 {
    padding: 0.8rem !important;
  }
  .p-xl-35 {
    padding: 1.25rem !important;
  }
  .p-xl-4 {
    padding: 1.875rem !important;
  }
  .p-xl-45 {
    padding: 2.25rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-55 {
    padding: 3.5rem !important;
  }
  .p-xl-6 {
    padding: 3.85rem !important;
  }
  .p-xl-65 {
    padding: 5rem !important;
  }
  .p-xl-68 {
    padding: 5.5rem !important;
  }
  .p-xl-7 {
    padding: 6.5rem !important;
  }
  .p-xl-75 {
    padding: 7rem !important;
  }
  .p-xl-8 {
    padding: 8rem !important;
  }
  .p-xl-9 {
    padding: 10rem !important;
  }
  .p-xl-95 {
    padding: 12rem !important;
  }
  .p-xl-10 {
    padding: 14rem !important;
  }
  .p-xl-11 {
    padding: 21rem !important;
  }
  .p-xl-12 {
    padding: 16.5rem !important;
  }
  .p-xl-13 {
    padding: 17rem !important;
  }
  .p-xl-14 {
    padding: 18rem !important;
  }
  .p-xl-15 {
    padding: 19rem !important;
  }
  .p-xl-16 {
    padding: 20rem !important;
  }
  .p-xl-17 {
    padding: 21rem !important;
  }
  .p-xl-18 {
    padding: 22rem !important;
  }
  .p-xl-19 {
    padding: 23rem !important;
  }
  .p-xl-20 {
    padding: 24rem !important;
  }
  .p-xl-21 {
    padding: 25rem !important;
  }
  .p-xl-22 {
    padding: 26rem !important;
  }
  .p-xl-23 {
    padding: 27rem !important;
  }
  .p-xl-24 {
    padding: 28rem !important;
  }
  .p-xl-25 {
    padding: 29rem !important;
  }
  .p-xl-26 {
    padding: 30rem !important;
  }
  .p-xl-27 {
    padding: 31rem !important;
  }
  .p-xl-28 {
    padding: 32rem !important;
  }
  .p-xl-29 {
    padding: 33rem !important;
  }
  .p-xl-30 {
    padding: 34rem !important;
  }
  .p-xl-nav {
    padding: 0.5rem !important;
  }
  .p-xl-100 {
    padding: 6.25rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
  }
  .px-xl-3 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important;
  }
  .px-xl-35 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xl-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xl-45 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-55 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xl-6 {
    padding-right: 3.85rem !important;
    padding-left: 3.85rem !important;
  }
  .px-xl-65 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-68 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-xl-7 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-xl-75 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-xl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xl-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xl-95 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-xl-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-xl-11 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-xl-12 {
    padding-right: 16.5rem !important;
    padding-left: 16.5rem !important;
  }
  .px-xl-13 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-xl-14 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-xl-15 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-xl-16 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-xl-17 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-xl-18 {
    padding-right: 22rem !important;
    padding-left: 22rem !important;
  }
  .px-xl-19 {
    padding-right: 23rem !important;
    padding-left: 23rem !important;
  }
  .px-xl-20 {
    padding-right: 24rem !important;
    padding-left: 24rem !important;
  }
  .px-xl-21 {
    padding-right: 25rem !important;
    padding-left: 25rem !important;
  }
  .px-xl-22 {
    padding-right: 26rem !important;
    padding-left: 26rem !important;
  }
  .px-xl-23 {
    padding-right: 27rem !important;
    padding-left: 27rem !important;
  }
  .px-xl-24 {
    padding-right: 28rem !important;
    padding-left: 28rem !important;
  }
  .px-xl-25 {
    padding-right: 29rem !important;
    padding-left: 29rem !important;
  }
  .px-xl-26 {
    padding-right: 30rem !important;
    padding-left: 30rem !important;
  }
  .px-xl-27 {
    padding-right: 31rem !important;
    padding-left: 31rem !important;
  }
  .px-xl-28 {
    padding-right: 32rem !important;
    padding-left: 32rem !important;
  }
  .px-xl-29 {
    padding-right: 33rem !important;
    padding-left: 33rem !important;
  }
  .px-xl-30 {
    padding-right: 34rem !important;
    padding-left: 34rem !important;
  }
  .px-xl-nav {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
  }
  .py-xl-3 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }
  .py-xl-35 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xl-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xl-45 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-55 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xl-6 {
    padding-top: 3.85rem !important;
    padding-bottom: 3.85rem !important;
  }
  .py-xl-65 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-68 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-xl-7 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-xl-75 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-xl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xl-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xl-95 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-xl-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-xl-11 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-xl-12 {
    padding-top: 16.5rem !important;
    padding-bottom: 16.5rem !important;
  }
  .py-xl-13 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-xl-14 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-xl-15 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-xl-16 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-xl-17 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-xl-18 {
    padding-top: 22rem !important;
    padding-bottom: 22rem !important;
  }
  .py-xl-19 {
    padding-top: 23rem !important;
    padding-bottom: 23rem !important;
  }
  .py-xl-20 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
  .py-xl-21 {
    padding-top: 25rem !important;
    padding-bottom: 25rem !important;
  }
  .py-xl-22 {
    padding-top: 26rem !important;
    padding-bottom: 26rem !important;
  }
  .py-xl-23 {
    padding-top: 27rem !important;
    padding-bottom: 27rem !important;
  }
  .py-xl-24 {
    padding-top: 28rem !important;
    padding-bottom: 28rem !important;
  }
  .py-xl-25 {
    padding-top: 29rem !important;
    padding-bottom: 29rem !important;
  }
  .py-xl-26 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important;
  }
  .py-xl-27 {
    padding-top: 31rem !important;
    padding-bottom: 31rem !important;
  }
  .py-xl-28 {
    padding-top: 32rem !important;
    padding-bottom: 32rem !important;
  }
  .py-xl-29 {
    padding-top: 33rem !important;
    padding-bottom: 33rem !important;
  }
  .py-xl-30 {
    padding-top: 34rem !important;
    padding-bottom: 34rem !important;
  }
  .py-xl-nav {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.4rem !important;
  }
  .pt-xl-3 {
    padding-top: 0.8rem !important;
  }
  .pt-xl-35 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.875rem !important;
  }
  .pt-xl-45 {
    padding-top: 2.25rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-55 {
    padding-top: 3.5rem !important;
  }
  .pt-xl-6 {
    padding-top: 3.85rem !important;
  }
  .pt-xl-65 {
    padding-top: 5rem !important;
  }
  .pt-xl-68 {
    padding-top: 5.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 6.5rem !important;
  }
  .pt-xl-75 {
    padding-top: 7rem !important;
  }
  .pt-xl-8 {
    padding-top: 8rem !important;
  }
  .pt-xl-9 {
    padding-top: 10rem !important;
  }
  .pt-xl-95 {
    padding-top: 12rem !important;
  }
  .pt-xl-10 {
    padding-top: 14rem !important;
  }
  .pt-xl-11 {
    padding-top: 21rem !important;
  }
  .pt-xl-12 {
    padding-top: 16.5rem !important;
  }
  .pt-xl-13 {
    padding-top: 17rem !important;
  }
  .pt-xl-14 {
    padding-top: 18rem !important;
  }
  .pt-xl-15 {
    padding-top: 19rem !important;
  }
  .pt-xl-16 {
    padding-top: 20rem !important;
  }
  .pt-xl-17 {
    padding-top: 21rem !important;
  }
  .pt-xl-18 {
    padding-top: 22rem !important;
  }
  .pt-xl-19 {
    padding-top: 23rem !important;
  }
  .pt-xl-20 {
    padding-top: 24rem !important;
  }
  .pt-xl-21 {
    padding-top: 25rem !important;
  }
  .pt-xl-22 {
    padding-top: 26rem !important;
  }
  .pt-xl-23 {
    padding-top: 27rem !important;
  }
  .pt-xl-24 {
    padding-top: 28rem !important;
  }
  .pt-xl-25 {
    padding-top: 29rem !important;
  }
  .pt-xl-26 {
    padding-top: 30rem !important;
  }
  .pt-xl-27 {
    padding-top: 31rem !important;
  }
  .pt-xl-28 {
    padding-top: 32rem !important;
  }
  .pt-xl-29 {
    padding-top: 33rem !important;
  }
  .pt-xl-30 {
    padding-top: 34rem !important;
  }
  .pt-xl-nav {
    padding-top: 0.5rem !important;
  }
  .pt-xl-100 {
    padding-top: 6.25rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.4rem !important;
  }
  .pe-xl-3 {
    padding-right: 0.8rem !important;
  }
  .pe-xl-35 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.875rem !important;
  }
  .pe-xl-45 {
    padding-right: 2.25rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-55 {
    padding-right: 3.5rem !important;
  }
  .pe-xl-6 {
    padding-right: 3.85rem !important;
  }
  .pe-xl-65 {
    padding-right: 5rem !important;
  }
  .pe-xl-68 {
    padding-right: 5.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 6.5rem !important;
  }
  .pe-xl-75 {
    padding-right: 7rem !important;
  }
  .pe-xl-8 {
    padding-right: 8rem !important;
  }
  .pe-xl-9 {
    padding-right: 10rem !important;
  }
  .pe-xl-95 {
    padding-right: 12rem !important;
  }
  .pe-xl-10 {
    padding-right: 14rem !important;
  }
  .pe-xl-11 {
    padding-right: 21rem !important;
  }
  .pe-xl-12 {
    padding-right: 16.5rem !important;
  }
  .pe-xl-13 {
    padding-right: 17rem !important;
  }
  .pe-xl-14 {
    padding-right: 18rem !important;
  }
  .pe-xl-15 {
    padding-right: 19rem !important;
  }
  .pe-xl-16 {
    padding-right: 20rem !important;
  }
  .pe-xl-17 {
    padding-right: 21rem !important;
  }
  .pe-xl-18 {
    padding-right: 22rem !important;
  }
  .pe-xl-19 {
    padding-right: 23rem !important;
  }
  .pe-xl-20 {
    padding-right: 24rem !important;
  }
  .pe-xl-21 {
    padding-right: 25rem !important;
  }
  .pe-xl-22 {
    padding-right: 26rem !important;
  }
  .pe-xl-23 {
    padding-right: 27rem !important;
  }
  .pe-xl-24 {
    padding-right: 28rem !important;
  }
  .pe-xl-25 {
    padding-right: 29rem !important;
  }
  .pe-xl-26 {
    padding-right: 30rem !important;
  }
  .pe-xl-27 {
    padding-right: 31rem !important;
  }
  .pe-xl-28 {
    padding-right: 32rem !important;
  }
  .pe-xl-29 {
    padding-right: 33rem !important;
  }
  .pe-xl-30 {
    padding-right: 34rem !important;
  }
  .pe-xl-nav {
    padding-right: 0.5rem !important;
  }
  .pe-xl-100 {
    padding-right: 6.25rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.4rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 0.8rem !important;
  }
  .pb-xl-35 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xl-45 {
    padding-bottom: 2.25rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-55 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 3.85rem !important;
  }
  .pb-xl-65 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-68 {
    padding-bottom: 5.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 6.5rem !important;
  }
  .pb-xl-75 {
    padding-bottom: 7rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 8rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 10rem !important;
  }
  .pb-xl-95 {
    padding-bottom: 12rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 14rem !important;
  }
  .pb-xl-11 {
    padding-bottom: 21rem !important;
  }
  .pb-xl-12 {
    padding-bottom: 16.5rem !important;
  }
  .pb-xl-13 {
    padding-bottom: 17rem !important;
  }
  .pb-xl-14 {
    padding-bottom: 18rem !important;
  }
  .pb-xl-15 {
    padding-bottom: 19rem !important;
  }
  .pb-xl-16 {
    padding-bottom: 20rem !important;
  }
  .pb-xl-17 {
    padding-bottom: 21rem !important;
  }
  .pb-xl-18 {
    padding-bottom: 22rem !important;
  }
  .pb-xl-19 {
    padding-bottom: 23rem !important;
  }
  .pb-xl-20 {
    padding-bottom: 24rem !important;
  }
  .pb-xl-21 {
    padding-bottom: 25rem !important;
  }
  .pb-xl-22 {
    padding-bottom: 26rem !important;
  }
  .pb-xl-23 {
    padding-bottom: 27rem !important;
  }
  .pb-xl-24 {
    padding-bottom: 28rem !important;
  }
  .pb-xl-25 {
    padding-bottom: 29rem !important;
  }
  .pb-xl-26 {
    padding-bottom: 30rem !important;
  }
  .pb-xl-27 {
    padding-bottom: 31rem !important;
  }
  .pb-xl-28 {
    padding-bottom: 32rem !important;
  }
  .pb-xl-29 {
    padding-bottom: 33rem !important;
  }
  .pb-xl-30 {
    padding-bottom: 34rem !important;
  }
  .pb-xl-nav {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-100 {
    padding-bottom: 6.25rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.4rem !important;
  }
  .ps-xl-3 {
    padding-left: 0.8rem !important;
  }
  .ps-xl-35 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.875rem !important;
  }
  .ps-xl-45 {
    padding-left: 2.25rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-55 {
    padding-left: 3.5rem !important;
  }
  .ps-xl-6 {
    padding-left: 3.85rem !important;
  }
  .ps-xl-65 {
    padding-left: 5rem !important;
  }
  .ps-xl-68 {
    padding-left: 5.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 6.5rem !important;
  }
  .ps-xl-75 {
    padding-left: 7rem !important;
  }
  .ps-xl-8 {
    padding-left: 8rem !important;
  }
  .ps-xl-9 {
    padding-left: 10rem !important;
  }
  .ps-xl-95 {
    padding-left: 12rem !important;
  }
  .ps-xl-10 {
    padding-left: 14rem !important;
  }
  .ps-xl-11 {
    padding-left: 21rem !important;
  }
  .ps-xl-12 {
    padding-left: 16.5rem !important;
  }
  .ps-xl-13 {
    padding-left: 17rem !important;
  }
  .ps-xl-14 {
    padding-left: 18rem !important;
  }
  .ps-xl-15 {
    padding-left: 19rem !important;
  }
  .ps-xl-16 {
    padding-left: 20rem !important;
  }
  .ps-xl-17 {
    padding-left: 21rem !important;
  }
  .ps-xl-18 {
    padding-left: 22rem !important;
  }
  .ps-xl-19 {
    padding-left: 23rem !important;
  }
  .ps-xl-20 {
    padding-left: 24rem !important;
  }
  .ps-xl-21 {
    padding-left: 25rem !important;
  }
  .ps-xl-22 {
    padding-left: 26rem !important;
  }
  .ps-xl-23 {
    padding-left: 27rem !important;
  }
  .ps-xl-24 {
    padding-left: 28rem !important;
  }
  .ps-xl-25 {
    padding-left: 29rem !important;
  }
  .ps-xl-26 {
    padding-left: 30rem !important;
  }
  .ps-xl-27 {
    padding-left: 31rem !important;
  }
  .ps-xl-28 {
    padding-left: 32rem !important;
  }
  .ps-xl-29 {
    padding-left: 33rem !important;
  }
  .ps-xl-30 {
    padding-left: 34rem !important;
  }
  .ps-xl-nav {
    padding-left: 0.5rem !important;
  }
  .ps-xl-100 {
    padding-left: 6.25rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.4rem !important;
  }
  .gap-xl-3 {
    gap: 0.8rem !important;
  }
  .gap-xl-35 {
    gap: 1.25rem !important;
  }
  .gap-xl-4 {
    gap: 1.875rem !important;
  }
  .gap-xl-45 {
    gap: 2.25rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-xl-55 {
    gap: 3.5rem !important;
  }
  .gap-xl-6 {
    gap: 3.85rem !important;
  }
  .gap-xl-65 {
    gap: 5rem !important;
  }
  .gap-xl-68 {
    gap: 5.5rem !important;
  }
  .gap-xl-7 {
    gap: 6.5rem !important;
  }
  .gap-xl-75 {
    gap: 7rem !important;
  }
  .gap-xl-8 {
    gap: 8rem !important;
  }
  .gap-xl-9 {
    gap: 10rem !important;
  }
  .gap-xl-95 {
    gap: 12rem !important;
  }
  .gap-xl-10 {
    gap: 14rem !important;
  }
  .gap-xl-11 {
    gap: 21rem !important;
  }
  .gap-xl-12 {
    gap: 16.5rem !important;
  }
  .gap-xl-13 {
    gap: 17rem !important;
  }
  .gap-xl-14 {
    gap: 18rem !important;
  }
  .gap-xl-15 {
    gap: 19rem !important;
  }
  .gap-xl-16 {
    gap: 20rem !important;
  }
  .gap-xl-17 {
    gap: 21rem !important;
  }
  .gap-xl-18 {
    gap: 22rem !important;
  }
  .gap-xl-19 {
    gap: 23rem !important;
  }
  .gap-xl-20 {
    gap: 24rem !important;
  }
  .gap-xl-21 {
    gap: 25rem !important;
  }
  .gap-xl-22 {
    gap: 26rem !important;
  }
  .gap-xl-23 {
    gap: 27rem !important;
  }
  .gap-xl-24 {
    gap: 28rem !important;
  }
  .gap-xl-25 {
    gap: 29rem !important;
  }
  .gap-xl-26 {
    gap: 30rem !important;
  }
  .gap-xl-27 {
    gap: 31rem !important;
  }
  .gap-xl-28 {
    gap: 32rem !important;
  }
  .gap-xl-29 {
    gap: 33rem !important;
  }
  .gap-xl-30 {
    gap: 34rem !important;
  }
  .gap-xl-nav {
    gap: 0.5rem !important;
  }
  .gap-xl-100 {
    gap: 6.25rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.4rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 0.8rem !important;
  }
  .row-gap-xl-35 {
    row-gap: 1.25rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.875rem !important;
  }
  .row-gap-xl-45 {
    row-gap: 2.25rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xl-55 {
    row-gap: 3.5rem !important;
  }
  .row-gap-xl-6 {
    row-gap: 3.85rem !important;
  }
  .row-gap-xl-65 {
    row-gap: 5rem !important;
  }
  .row-gap-xl-68 {
    row-gap: 5.5rem !important;
  }
  .row-gap-xl-7 {
    row-gap: 6.5rem !important;
  }
  .row-gap-xl-75 {
    row-gap: 7rem !important;
  }
  .row-gap-xl-8 {
    row-gap: 8rem !important;
  }
  .row-gap-xl-9 {
    row-gap: 10rem !important;
  }
  .row-gap-xl-95 {
    row-gap: 12rem !important;
  }
  .row-gap-xl-10 {
    row-gap: 14rem !important;
  }
  .row-gap-xl-11 {
    row-gap: 21rem !important;
  }
  .row-gap-xl-12 {
    row-gap: 16.5rem !important;
  }
  .row-gap-xl-13 {
    row-gap: 17rem !important;
  }
  .row-gap-xl-14 {
    row-gap: 18rem !important;
  }
  .row-gap-xl-15 {
    row-gap: 19rem !important;
  }
  .row-gap-xl-16 {
    row-gap: 20rem !important;
  }
  .row-gap-xl-17 {
    row-gap: 21rem !important;
  }
  .row-gap-xl-18 {
    row-gap: 22rem !important;
  }
  .row-gap-xl-19 {
    row-gap: 23rem !important;
  }
  .row-gap-xl-20 {
    row-gap: 24rem !important;
  }
  .row-gap-xl-21 {
    row-gap: 25rem !important;
  }
  .row-gap-xl-22 {
    row-gap: 26rem !important;
  }
  .row-gap-xl-23 {
    row-gap: 27rem !important;
  }
  .row-gap-xl-24 {
    row-gap: 28rem !important;
  }
  .row-gap-xl-25 {
    row-gap: 29rem !important;
  }
  .row-gap-xl-26 {
    row-gap: 30rem !important;
  }
  .row-gap-xl-27 {
    row-gap: 31rem !important;
  }
  .row-gap-xl-28 {
    row-gap: 32rem !important;
  }
  .row-gap-xl-29 {
    row-gap: 33rem !important;
  }
  .row-gap-xl-30 {
    row-gap: 34rem !important;
  }
  .row-gap-xl-nav {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-100 {
    row-gap: 6.25rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.4rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 0.8rem !important;
  }
  .column-gap-xl-35 {
    column-gap: 1.25rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.875rem !important;
  }
  .column-gap-xl-45 {
    column-gap: 2.25rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .column-gap-xl-55 {
    column-gap: 3.5rem !important;
  }
  .column-gap-xl-6 {
    column-gap: 3.85rem !important;
  }
  .column-gap-xl-65 {
    column-gap: 5rem !important;
  }
  .column-gap-xl-68 {
    column-gap: 5.5rem !important;
  }
  .column-gap-xl-7 {
    column-gap: 6.5rem !important;
  }
  .column-gap-xl-75 {
    column-gap: 7rem !important;
  }
  .column-gap-xl-8 {
    column-gap: 8rem !important;
  }
  .column-gap-xl-9 {
    column-gap: 10rem !important;
  }
  .column-gap-xl-95 {
    column-gap: 12rem !important;
  }
  .column-gap-xl-10 {
    column-gap: 14rem !important;
  }
  .column-gap-xl-11 {
    column-gap: 21rem !important;
  }
  .column-gap-xl-12 {
    column-gap: 16.5rem !important;
  }
  .column-gap-xl-13 {
    column-gap: 17rem !important;
  }
  .column-gap-xl-14 {
    column-gap: 18rem !important;
  }
  .column-gap-xl-15 {
    column-gap: 19rem !important;
  }
  .column-gap-xl-16 {
    column-gap: 20rem !important;
  }
  .column-gap-xl-17 {
    column-gap: 21rem !important;
  }
  .column-gap-xl-18 {
    column-gap: 22rem !important;
  }
  .column-gap-xl-19 {
    column-gap: 23rem !important;
  }
  .column-gap-xl-20 {
    column-gap: 24rem !important;
  }
  .column-gap-xl-21 {
    column-gap: 25rem !important;
  }
  .column-gap-xl-22 {
    column-gap: 26rem !important;
  }
  .column-gap-xl-23 {
    column-gap: 27rem !important;
  }
  .column-gap-xl-24 {
    column-gap: 28rem !important;
  }
  .column-gap-xl-25 {
    column-gap: 29rem !important;
  }
  .column-gap-xl-26 {
    column-gap: 30rem !important;
  }
  .column-gap-xl-27 {
    column-gap: 31rem !important;
  }
  .column-gap-xl-28 {
    column-gap: 32rem !important;
  }
  .column-gap-xl-29 {
    column-gap: 33rem !important;
  }
  .column-gap-xl-30 {
    column-gap: 34rem !important;
  }
  .column-gap-xl-nav {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-100 {
    column-gap: 6.25rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1440px) {
  .float-laptop-start {
    float: left !important;
  }
  .float-laptop-end {
    float: right !important;
  }
  .float-laptop-none {
    float: none !important;
  }
  .object-fit-laptop-contain {
    object-fit: contain !important;
  }
  .object-fit-laptop-cover {
    object-fit: cover !important;
  }
  .object-fit-laptop-fill {
    object-fit: fill !important;
  }
  .object-fit-laptop-scale {
    object-fit: scale-down !important;
  }
  .object-fit-laptop-none {
    object-fit: none !important;
  }
  .d-laptop-inline {
    display: inline !important;
  }
  .d-laptop-inline-block {
    display: inline-block !important;
  }
  .d-laptop-block {
    display: block !important;
  }
  .d-laptop-grid {
    display: grid !important;
  }
  .d-laptop-inline-grid {
    display: inline-grid !important;
  }
  .d-laptop-table {
    display: table !important;
  }
  .d-laptop-table-row {
    display: table-row !important;
  }
  .d-laptop-table-cell {
    display: table-cell !important;
  }
  .d-laptop-flex {
    display: flex !important;
  }
  .d-laptop-inline-flex {
    display: inline-flex !important;
  }
  .d-laptop-none {
    display: none !important;
  }
  .flex-laptop-fill {
    flex: 1 1 auto !important;
  }
  .flex-laptop-row {
    flex-direction: row !important;
  }
  .flex-laptop-column {
    flex-direction: column !important;
  }
  .flex-laptop-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-laptop-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-laptop-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-laptop-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-laptop-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-laptop-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-laptop-wrap {
    flex-wrap: wrap !important;
  }
  .flex-laptop-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-laptop-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-laptop-start {
    justify-content: flex-start !important;
  }
  .justify-content-laptop-end {
    justify-content: flex-end !important;
  }
  .justify-content-laptop-center {
    justify-content: center !important;
  }
  .justify-content-laptop-between {
    justify-content: space-between !important;
  }
  .justify-content-laptop-around {
    justify-content: space-around !important;
  }
  .justify-content-laptop-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-laptop-start {
    align-items: flex-start !important;
  }
  .align-items-laptop-end {
    align-items: flex-end !important;
  }
  .align-items-laptop-center {
    align-items: center !important;
  }
  .align-items-laptop-baseline {
    align-items: baseline !important;
  }
  .align-items-laptop-stretch {
    align-items: stretch !important;
  }
  .align-content-laptop-start {
    align-content: flex-start !important;
  }
  .align-content-laptop-end {
    align-content: flex-end !important;
  }
  .align-content-laptop-center {
    align-content: center !important;
  }
  .align-content-laptop-between {
    align-content: space-between !important;
  }
  .align-content-laptop-around {
    align-content: space-around !important;
  }
  .align-content-laptop-stretch {
    align-content: stretch !important;
  }
  .align-self-laptop-auto {
    align-self: auto !important;
  }
  .align-self-laptop-start {
    align-self: flex-start !important;
  }
  .align-self-laptop-end {
    align-self: flex-end !important;
  }
  .align-self-laptop-center {
    align-self: center !important;
  }
  .align-self-laptop-baseline {
    align-self: baseline !important;
  }
  .align-self-laptop-stretch {
    align-self: stretch !important;
  }
  .order-laptop-first {
    order: -1 !important;
  }
  .order-laptop-0 {
    order: 0 !important;
  }
  .order-laptop-1 {
    order: 1 !important;
  }
  .order-laptop-2 {
    order: 2 !important;
  }
  .order-laptop-3 {
    order: 3 !important;
  }
  .order-laptop-4 {
    order: 4 !important;
  }
  .order-laptop-5 {
    order: 5 !important;
  }
  .order-laptop-last {
    order: 6 !important;
  }
  .m-laptop-0 {
    margin: 0 !important;
  }
  .m-laptop-1 {
    margin: 0.25rem !important;
  }
  .m-laptop-2 {
    margin: 0.4rem !important;
  }
  .m-laptop-3 {
    margin: 0.8rem !important;
  }
  .m-laptop-35 {
    margin: 1.25rem !important;
  }
  .m-laptop-4 {
    margin: 1.875rem !important;
  }
  .m-laptop-45 {
    margin: 2.25rem !important;
  }
  .m-laptop-5 {
    margin: 3rem !important;
  }
  .m-laptop-55 {
    margin: 3.5rem !important;
  }
  .m-laptop-6 {
    margin: 3.85rem !important;
  }
  .m-laptop-65 {
    margin: 5rem !important;
  }
  .m-laptop-68 {
    margin: 5.5rem !important;
  }
  .m-laptop-7 {
    margin: 6.5rem !important;
  }
  .m-laptop-75 {
    margin: 7rem !important;
  }
  .m-laptop-8 {
    margin: 8rem !important;
  }
  .m-laptop-9 {
    margin: 10rem !important;
  }
  .m-laptop-95 {
    margin: 12rem !important;
  }
  .m-laptop-10 {
    margin: 14rem !important;
  }
  .m-laptop-11 {
    margin: 21rem !important;
  }
  .m-laptop-12 {
    margin: 16.5rem !important;
  }
  .m-laptop-13 {
    margin: 17rem !important;
  }
  .m-laptop-14 {
    margin: 18rem !important;
  }
  .m-laptop-15 {
    margin: 19rem !important;
  }
  .m-laptop-16 {
    margin: 20rem !important;
  }
  .m-laptop-17 {
    margin: 21rem !important;
  }
  .m-laptop-18 {
    margin: 22rem !important;
  }
  .m-laptop-19 {
    margin: 23rem !important;
  }
  .m-laptop-20 {
    margin: 24rem !important;
  }
  .m-laptop-21 {
    margin: 25rem !important;
  }
  .m-laptop-22 {
    margin: 26rem !important;
  }
  .m-laptop-23 {
    margin: 27rem !important;
  }
  .m-laptop-24 {
    margin: 28rem !important;
  }
  .m-laptop-25 {
    margin: 29rem !important;
  }
  .m-laptop-26 {
    margin: 30rem !important;
  }
  .m-laptop-27 {
    margin: 31rem !important;
  }
  .m-laptop-28 {
    margin: 32rem !important;
  }
  .m-laptop-29 {
    margin: 33rem !important;
  }
  .m-laptop-30 {
    margin: 34rem !important;
  }
  .m-laptop-nav {
    margin: 0.5rem !important;
  }
  .m-laptop-100 {
    margin: 6.25rem !important;
  }
  .m-laptop-auto {
    margin: auto !important;
  }
  .mx-laptop-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-laptop-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-laptop-2 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important;
  }
  .mx-laptop-3 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important;
  }
  .mx-laptop-35 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-laptop-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-laptop-45 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-laptop-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-laptop-55 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-laptop-6 {
    margin-right: 3.85rem !important;
    margin-left: 3.85rem !important;
  }
  .mx-laptop-65 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-laptop-68 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-laptop-7 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-laptop-75 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-laptop-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-laptop-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-laptop-95 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-laptop-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-laptop-11 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-laptop-12 {
    margin-right: 16.5rem !important;
    margin-left: 16.5rem !important;
  }
  .mx-laptop-13 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-laptop-14 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-laptop-15 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-laptop-16 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-laptop-17 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-laptop-18 {
    margin-right: 22rem !important;
    margin-left: 22rem !important;
  }
  .mx-laptop-19 {
    margin-right: 23rem !important;
    margin-left: 23rem !important;
  }
  .mx-laptop-20 {
    margin-right: 24rem !important;
    margin-left: 24rem !important;
  }
  .mx-laptop-21 {
    margin-right: 25rem !important;
    margin-left: 25rem !important;
  }
  .mx-laptop-22 {
    margin-right: 26rem !important;
    margin-left: 26rem !important;
  }
  .mx-laptop-23 {
    margin-right: 27rem !important;
    margin-left: 27rem !important;
  }
  .mx-laptop-24 {
    margin-right: 28rem !important;
    margin-left: 28rem !important;
  }
  .mx-laptop-25 {
    margin-right: 29rem !important;
    margin-left: 29rem !important;
  }
  .mx-laptop-26 {
    margin-right: 30rem !important;
    margin-left: 30rem !important;
  }
  .mx-laptop-27 {
    margin-right: 31rem !important;
    margin-left: 31rem !important;
  }
  .mx-laptop-28 {
    margin-right: 32rem !important;
    margin-left: 32rem !important;
  }
  .mx-laptop-29 {
    margin-right: 33rem !important;
    margin-left: 33rem !important;
  }
  .mx-laptop-30 {
    margin-right: 34rem !important;
    margin-left: 34rem !important;
  }
  .mx-laptop-nav {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-laptop-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-laptop-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-laptop-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-laptop-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-laptop-2 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  .my-laptop-3 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important;
  }
  .my-laptop-35 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-laptop-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-laptop-45 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-laptop-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-laptop-55 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-laptop-6 {
    margin-top: 3.85rem !important;
    margin-bottom: 3.85rem !important;
  }
  .my-laptop-65 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-laptop-68 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-laptop-7 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-laptop-75 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-laptop-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-laptop-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-laptop-95 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-laptop-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-laptop-11 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-laptop-12 {
    margin-top: 16.5rem !important;
    margin-bottom: 16.5rem !important;
  }
  .my-laptop-13 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-laptop-14 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-laptop-15 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-laptop-16 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-laptop-17 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-laptop-18 {
    margin-top: 22rem !important;
    margin-bottom: 22rem !important;
  }
  .my-laptop-19 {
    margin-top: 23rem !important;
    margin-bottom: 23rem !important;
  }
  .my-laptop-20 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }
  .my-laptop-21 {
    margin-top: 25rem !important;
    margin-bottom: 25rem !important;
  }
  .my-laptop-22 {
    margin-top: 26rem !important;
    margin-bottom: 26rem !important;
  }
  .my-laptop-23 {
    margin-top: 27rem !important;
    margin-bottom: 27rem !important;
  }
  .my-laptop-24 {
    margin-top: 28rem !important;
    margin-bottom: 28rem !important;
  }
  .my-laptop-25 {
    margin-top: 29rem !important;
    margin-bottom: 29rem !important;
  }
  .my-laptop-26 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important;
  }
  .my-laptop-27 {
    margin-top: 31rem !important;
    margin-bottom: 31rem !important;
  }
  .my-laptop-28 {
    margin-top: 32rem !important;
    margin-bottom: 32rem !important;
  }
  .my-laptop-29 {
    margin-top: 33rem !important;
    margin-bottom: 33rem !important;
  }
  .my-laptop-30 {
    margin-top: 34rem !important;
    margin-bottom: 34rem !important;
  }
  .my-laptop-nav {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-laptop-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-laptop-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-laptop-0 {
    margin-top: 0 !important;
  }
  .mt-laptop-1 {
    margin-top: 0.25rem !important;
  }
  .mt-laptop-2 {
    margin-top: 0.4rem !important;
  }
  .mt-laptop-3 {
    margin-top: 0.8rem !important;
  }
  .mt-laptop-35 {
    margin-top: 1.25rem !important;
  }
  .mt-laptop-4 {
    margin-top: 1.875rem !important;
  }
  .mt-laptop-45 {
    margin-top: 2.25rem !important;
  }
  .mt-laptop-5 {
    margin-top: 3rem !important;
  }
  .mt-laptop-55 {
    margin-top: 3.5rem !important;
  }
  .mt-laptop-6 {
    margin-top: 3.85rem !important;
  }
  .mt-laptop-65 {
    margin-top: 5rem !important;
  }
  .mt-laptop-68 {
    margin-top: 5.5rem !important;
  }
  .mt-laptop-7 {
    margin-top: 6.5rem !important;
  }
  .mt-laptop-75 {
    margin-top: 7rem !important;
  }
  .mt-laptop-8 {
    margin-top: 8rem !important;
  }
  .mt-laptop-9 {
    margin-top: 10rem !important;
  }
  .mt-laptop-95 {
    margin-top: 12rem !important;
  }
  .mt-laptop-10 {
    margin-top: 14rem !important;
  }
  .mt-laptop-11 {
    margin-top: 21rem !important;
  }
  .mt-laptop-12 {
    margin-top: 16.5rem !important;
  }
  .mt-laptop-13 {
    margin-top: 17rem !important;
  }
  .mt-laptop-14 {
    margin-top: 18rem !important;
  }
  .mt-laptop-15 {
    margin-top: 19rem !important;
  }
  .mt-laptop-16 {
    margin-top: 20rem !important;
  }
  .mt-laptop-17 {
    margin-top: 21rem !important;
  }
  .mt-laptop-18 {
    margin-top: 22rem !important;
  }
  .mt-laptop-19 {
    margin-top: 23rem !important;
  }
  .mt-laptop-20 {
    margin-top: 24rem !important;
  }
  .mt-laptop-21 {
    margin-top: 25rem !important;
  }
  .mt-laptop-22 {
    margin-top: 26rem !important;
  }
  .mt-laptop-23 {
    margin-top: 27rem !important;
  }
  .mt-laptop-24 {
    margin-top: 28rem !important;
  }
  .mt-laptop-25 {
    margin-top: 29rem !important;
  }
  .mt-laptop-26 {
    margin-top: 30rem !important;
  }
  .mt-laptop-27 {
    margin-top: 31rem !important;
  }
  .mt-laptop-28 {
    margin-top: 32rem !important;
  }
  .mt-laptop-29 {
    margin-top: 33rem !important;
  }
  .mt-laptop-30 {
    margin-top: 34rem !important;
  }
  .mt-laptop-nav {
    margin-top: 0.5rem !important;
  }
  .mt-laptop-100 {
    margin-top: 6.25rem !important;
  }
  .mt-laptop-auto {
    margin-top: auto !important;
  }
  .me-laptop-0 {
    margin-right: 0 !important;
  }
  .me-laptop-1 {
    margin-right: 0.25rem !important;
  }
  .me-laptop-2 {
    margin-right: 0.4rem !important;
  }
  .me-laptop-3 {
    margin-right: 0.8rem !important;
  }
  .me-laptop-35 {
    margin-right: 1.25rem !important;
  }
  .me-laptop-4 {
    margin-right: 1.875rem !important;
  }
  .me-laptop-45 {
    margin-right: 2.25rem !important;
  }
  .me-laptop-5 {
    margin-right: 3rem !important;
  }
  .me-laptop-55 {
    margin-right: 3.5rem !important;
  }
  .me-laptop-6 {
    margin-right: 3.85rem !important;
  }
  .me-laptop-65 {
    margin-right: 5rem !important;
  }
  .me-laptop-68 {
    margin-right: 5.5rem !important;
  }
  .me-laptop-7 {
    margin-right: 6.5rem !important;
  }
  .me-laptop-75 {
    margin-right: 7rem !important;
  }
  .me-laptop-8 {
    margin-right: 8rem !important;
  }
  .me-laptop-9 {
    margin-right: 10rem !important;
  }
  .me-laptop-95 {
    margin-right: 12rem !important;
  }
  .me-laptop-10 {
    margin-right: 14rem !important;
  }
  .me-laptop-11 {
    margin-right: 21rem !important;
  }
  .me-laptop-12 {
    margin-right: 16.5rem !important;
  }
  .me-laptop-13 {
    margin-right: 17rem !important;
  }
  .me-laptop-14 {
    margin-right: 18rem !important;
  }
  .me-laptop-15 {
    margin-right: 19rem !important;
  }
  .me-laptop-16 {
    margin-right: 20rem !important;
  }
  .me-laptop-17 {
    margin-right: 21rem !important;
  }
  .me-laptop-18 {
    margin-right: 22rem !important;
  }
  .me-laptop-19 {
    margin-right: 23rem !important;
  }
  .me-laptop-20 {
    margin-right: 24rem !important;
  }
  .me-laptop-21 {
    margin-right: 25rem !important;
  }
  .me-laptop-22 {
    margin-right: 26rem !important;
  }
  .me-laptop-23 {
    margin-right: 27rem !important;
  }
  .me-laptop-24 {
    margin-right: 28rem !important;
  }
  .me-laptop-25 {
    margin-right: 29rem !important;
  }
  .me-laptop-26 {
    margin-right: 30rem !important;
  }
  .me-laptop-27 {
    margin-right: 31rem !important;
  }
  .me-laptop-28 {
    margin-right: 32rem !important;
  }
  .me-laptop-29 {
    margin-right: 33rem !important;
  }
  .me-laptop-30 {
    margin-right: 34rem !important;
  }
  .me-laptop-nav {
    margin-right: 0.5rem !important;
  }
  .me-laptop-100 {
    margin-right: 6.25rem !important;
  }
  .me-laptop-auto {
    margin-right: auto !important;
  }
  .mb-laptop-0 {
    margin-bottom: 0 !important;
  }
  .mb-laptop-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-laptop-2 {
    margin-bottom: 0.4rem !important;
  }
  .mb-laptop-3 {
    margin-bottom: 0.8rem !important;
  }
  .mb-laptop-35 {
    margin-bottom: 1.25rem !important;
  }
  .mb-laptop-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-laptop-45 {
    margin-bottom: 2.25rem !important;
  }
  .mb-laptop-5 {
    margin-bottom: 3rem !important;
  }
  .mb-laptop-55 {
    margin-bottom: 3.5rem !important;
  }
  .mb-laptop-6 {
    margin-bottom: 3.85rem !important;
  }
  .mb-laptop-65 {
    margin-bottom: 5rem !important;
  }
  .mb-laptop-68 {
    margin-bottom: 5.5rem !important;
  }
  .mb-laptop-7 {
    margin-bottom: 6.5rem !important;
  }
  .mb-laptop-75 {
    margin-bottom: 7rem !important;
  }
  .mb-laptop-8 {
    margin-bottom: 8rem !important;
  }
  .mb-laptop-9 {
    margin-bottom: 10rem !important;
  }
  .mb-laptop-95 {
    margin-bottom: 12rem !important;
  }
  .mb-laptop-10 {
    margin-bottom: 14rem !important;
  }
  .mb-laptop-11 {
    margin-bottom: 21rem !important;
  }
  .mb-laptop-12 {
    margin-bottom: 16.5rem !important;
  }
  .mb-laptop-13 {
    margin-bottom: 17rem !important;
  }
  .mb-laptop-14 {
    margin-bottom: 18rem !important;
  }
  .mb-laptop-15 {
    margin-bottom: 19rem !important;
  }
  .mb-laptop-16 {
    margin-bottom: 20rem !important;
  }
  .mb-laptop-17 {
    margin-bottom: 21rem !important;
  }
  .mb-laptop-18 {
    margin-bottom: 22rem !important;
  }
  .mb-laptop-19 {
    margin-bottom: 23rem !important;
  }
  .mb-laptop-20 {
    margin-bottom: 24rem !important;
  }
  .mb-laptop-21 {
    margin-bottom: 25rem !important;
  }
  .mb-laptop-22 {
    margin-bottom: 26rem !important;
  }
  .mb-laptop-23 {
    margin-bottom: 27rem !important;
  }
  .mb-laptop-24 {
    margin-bottom: 28rem !important;
  }
  .mb-laptop-25 {
    margin-bottom: 29rem !important;
  }
  .mb-laptop-26 {
    margin-bottom: 30rem !important;
  }
  .mb-laptop-27 {
    margin-bottom: 31rem !important;
  }
  .mb-laptop-28 {
    margin-bottom: 32rem !important;
  }
  .mb-laptop-29 {
    margin-bottom: 33rem !important;
  }
  .mb-laptop-30 {
    margin-bottom: 34rem !important;
  }
  .mb-laptop-nav {
    margin-bottom: 0.5rem !important;
  }
  .mb-laptop-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-laptop-auto {
    margin-bottom: auto !important;
  }
  .ms-laptop-0 {
    margin-left: 0 !important;
  }
  .ms-laptop-1 {
    margin-left: 0.25rem !important;
  }
  .ms-laptop-2 {
    margin-left: 0.4rem !important;
  }
  .ms-laptop-3 {
    margin-left: 0.8rem !important;
  }
  .ms-laptop-35 {
    margin-left: 1.25rem !important;
  }
  .ms-laptop-4 {
    margin-left: 1.875rem !important;
  }
  .ms-laptop-45 {
    margin-left: 2.25rem !important;
  }
  .ms-laptop-5 {
    margin-left: 3rem !important;
  }
  .ms-laptop-55 {
    margin-left: 3.5rem !important;
  }
  .ms-laptop-6 {
    margin-left: 3.85rem !important;
  }
  .ms-laptop-65 {
    margin-left: 5rem !important;
  }
  .ms-laptop-68 {
    margin-left: 5.5rem !important;
  }
  .ms-laptop-7 {
    margin-left: 6.5rem !important;
  }
  .ms-laptop-75 {
    margin-left: 7rem !important;
  }
  .ms-laptop-8 {
    margin-left: 8rem !important;
  }
  .ms-laptop-9 {
    margin-left: 10rem !important;
  }
  .ms-laptop-95 {
    margin-left: 12rem !important;
  }
  .ms-laptop-10 {
    margin-left: 14rem !important;
  }
  .ms-laptop-11 {
    margin-left: 21rem !important;
  }
  .ms-laptop-12 {
    margin-left: 16.5rem !important;
  }
  .ms-laptop-13 {
    margin-left: 17rem !important;
  }
  .ms-laptop-14 {
    margin-left: 18rem !important;
  }
  .ms-laptop-15 {
    margin-left: 19rem !important;
  }
  .ms-laptop-16 {
    margin-left: 20rem !important;
  }
  .ms-laptop-17 {
    margin-left: 21rem !important;
  }
  .ms-laptop-18 {
    margin-left: 22rem !important;
  }
  .ms-laptop-19 {
    margin-left: 23rem !important;
  }
  .ms-laptop-20 {
    margin-left: 24rem !important;
  }
  .ms-laptop-21 {
    margin-left: 25rem !important;
  }
  .ms-laptop-22 {
    margin-left: 26rem !important;
  }
  .ms-laptop-23 {
    margin-left: 27rem !important;
  }
  .ms-laptop-24 {
    margin-left: 28rem !important;
  }
  .ms-laptop-25 {
    margin-left: 29rem !important;
  }
  .ms-laptop-26 {
    margin-left: 30rem !important;
  }
  .ms-laptop-27 {
    margin-left: 31rem !important;
  }
  .ms-laptop-28 {
    margin-left: 32rem !important;
  }
  .ms-laptop-29 {
    margin-left: 33rem !important;
  }
  .ms-laptop-30 {
    margin-left: 34rem !important;
  }
  .ms-laptop-nav {
    margin-left: 0.5rem !important;
  }
  .ms-laptop-100 {
    margin-left: 6.25rem !important;
  }
  .ms-laptop-auto {
    margin-left: auto !important;
  }
  .p-laptop-0 {
    padding: 0 !important;
  }
  .p-laptop-1 {
    padding: 0.25rem !important;
  }
  .p-laptop-2 {
    padding: 0.4rem !important;
  }
  .p-laptop-3 {
    padding: 0.8rem !important;
  }
  .p-laptop-35 {
    padding: 1.25rem !important;
  }
  .p-laptop-4 {
    padding: 1.875rem !important;
  }
  .p-laptop-45 {
    padding: 2.25rem !important;
  }
  .p-laptop-5 {
    padding: 3rem !important;
  }
  .p-laptop-55 {
    padding: 3.5rem !important;
  }
  .p-laptop-6 {
    padding: 3.85rem !important;
  }
  .p-laptop-65 {
    padding: 5rem !important;
  }
  .p-laptop-68 {
    padding: 5.5rem !important;
  }
  .p-laptop-7 {
    padding: 6.5rem !important;
  }
  .p-laptop-75 {
    padding: 7rem !important;
  }
  .p-laptop-8 {
    padding: 8rem !important;
  }
  .p-laptop-9 {
    padding: 10rem !important;
  }
  .p-laptop-95 {
    padding: 12rem !important;
  }
  .p-laptop-10 {
    padding: 14rem !important;
  }
  .p-laptop-11 {
    padding: 21rem !important;
  }
  .p-laptop-12 {
    padding: 16.5rem !important;
  }
  .p-laptop-13 {
    padding: 17rem !important;
  }
  .p-laptop-14 {
    padding: 18rem !important;
  }
  .p-laptop-15 {
    padding: 19rem !important;
  }
  .p-laptop-16 {
    padding: 20rem !important;
  }
  .p-laptop-17 {
    padding: 21rem !important;
  }
  .p-laptop-18 {
    padding: 22rem !important;
  }
  .p-laptop-19 {
    padding: 23rem !important;
  }
  .p-laptop-20 {
    padding: 24rem !important;
  }
  .p-laptop-21 {
    padding: 25rem !important;
  }
  .p-laptop-22 {
    padding: 26rem !important;
  }
  .p-laptop-23 {
    padding: 27rem !important;
  }
  .p-laptop-24 {
    padding: 28rem !important;
  }
  .p-laptop-25 {
    padding: 29rem !important;
  }
  .p-laptop-26 {
    padding: 30rem !important;
  }
  .p-laptop-27 {
    padding: 31rem !important;
  }
  .p-laptop-28 {
    padding: 32rem !important;
  }
  .p-laptop-29 {
    padding: 33rem !important;
  }
  .p-laptop-30 {
    padding: 34rem !important;
  }
  .p-laptop-nav {
    padding: 0.5rem !important;
  }
  .p-laptop-100 {
    padding: 6.25rem !important;
  }
  .px-laptop-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-laptop-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-laptop-2 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
  }
  .px-laptop-3 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important;
  }
  .px-laptop-35 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-laptop-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-laptop-45 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-laptop-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-laptop-55 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-laptop-6 {
    padding-right: 3.85rem !important;
    padding-left: 3.85rem !important;
  }
  .px-laptop-65 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-laptop-68 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-laptop-7 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-laptop-75 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-laptop-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-laptop-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-laptop-95 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-laptop-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-laptop-11 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-laptop-12 {
    padding-right: 16.5rem !important;
    padding-left: 16.5rem !important;
  }
  .px-laptop-13 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-laptop-14 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-laptop-15 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-laptop-16 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-laptop-17 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-laptop-18 {
    padding-right: 22rem !important;
    padding-left: 22rem !important;
  }
  .px-laptop-19 {
    padding-right: 23rem !important;
    padding-left: 23rem !important;
  }
  .px-laptop-20 {
    padding-right: 24rem !important;
    padding-left: 24rem !important;
  }
  .px-laptop-21 {
    padding-right: 25rem !important;
    padding-left: 25rem !important;
  }
  .px-laptop-22 {
    padding-right: 26rem !important;
    padding-left: 26rem !important;
  }
  .px-laptop-23 {
    padding-right: 27rem !important;
    padding-left: 27rem !important;
  }
  .px-laptop-24 {
    padding-right: 28rem !important;
    padding-left: 28rem !important;
  }
  .px-laptop-25 {
    padding-right: 29rem !important;
    padding-left: 29rem !important;
  }
  .px-laptop-26 {
    padding-right: 30rem !important;
    padding-left: 30rem !important;
  }
  .px-laptop-27 {
    padding-right: 31rem !important;
    padding-left: 31rem !important;
  }
  .px-laptop-28 {
    padding-right: 32rem !important;
    padding-left: 32rem !important;
  }
  .px-laptop-29 {
    padding-right: 33rem !important;
    padding-left: 33rem !important;
  }
  .px-laptop-30 {
    padding-right: 34rem !important;
    padding-left: 34rem !important;
  }
  .px-laptop-nav {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-laptop-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .py-laptop-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-laptop-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-laptop-2 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
  }
  .py-laptop-3 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }
  .py-laptop-35 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-laptop-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-laptop-45 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-laptop-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-laptop-55 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-laptop-6 {
    padding-top: 3.85rem !important;
    padding-bottom: 3.85rem !important;
  }
  .py-laptop-65 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-laptop-68 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-laptop-7 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-laptop-75 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-laptop-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-laptop-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-laptop-95 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-laptop-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-laptop-11 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-laptop-12 {
    padding-top: 16.5rem !important;
    padding-bottom: 16.5rem !important;
  }
  .py-laptop-13 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-laptop-14 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-laptop-15 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-laptop-16 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-laptop-17 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-laptop-18 {
    padding-top: 22rem !important;
    padding-bottom: 22rem !important;
  }
  .py-laptop-19 {
    padding-top: 23rem !important;
    padding-bottom: 23rem !important;
  }
  .py-laptop-20 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
  .py-laptop-21 {
    padding-top: 25rem !important;
    padding-bottom: 25rem !important;
  }
  .py-laptop-22 {
    padding-top: 26rem !important;
    padding-bottom: 26rem !important;
  }
  .py-laptop-23 {
    padding-top: 27rem !important;
    padding-bottom: 27rem !important;
  }
  .py-laptop-24 {
    padding-top: 28rem !important;
    padding-bottom: 28rem !important;
  }
  .py-laptop-25 {
    padding-top: 29rem !important;
    padding-bottom: 29rem !important;
  }
  .py-laptop-26 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important;
  }
  .py-laptop-27 {
    padding-top: 31rem !important;
    padding-bottom: 31rem !important;
  }
  .py-laptop-28 {
    padding-top: 32rem !important;
    padding-bottom: 32rem !important;
  }
  .py-laptop-29 {
    padding-top: 33rem !important;
    padding-bottom: 33rem !important;
  }
  .py-laptop-30 {
    padding-top: 34rem !important;
    padding-bottom: 34rem !important;
  }
  .py-laptop-nav {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-laptop-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .pt-laptop-0 {
    padding-top: 0 !important;
  }
  .pt-laptop-1 {
    padding-top: 0.25rem !important;
  }
  .pt-laptop-2 {
    padding-top: 0.4rem !important;
  }
  .pt-laptop-3 {
    padding-top: 0.8rem !important;
  }
  .pt-laptop-35 {
    padding-top: 1.25rem !important;
  }
  .pt-laptop-4 {
    padding-top: 1.875rem !important;
  }
  .pt-laptop-45 {
    padding-top: 2.25rem !important;
  }
  .pt-laptop-5 {
    padding-top: 3rem !important;
  }
  .pt-laptop-55 {
    padding-top: 3.5rem !important;
  }
  .pt-laptop-6 {
    padding-top: 3.85rem !important;
  }
  .pt-laptop-65 {
    padding-top: 5rem !important;
  }
  .pt-laptop-68 {
    padding-top: 5.5rem !important;
  }
  .pt-laptop-7 {
    padding-top: 6.5rem !important;
  }
  .pt-laptop-75 {
    padding-top: 7rem !important;
  }
  .pt-laptop-8 {
    padding-top: 8rem !important;
  }
  .pt-laptop-9 {
    padding-top: 10rem !important;
  }
  .pt-laptop-95 {
    padding-top: 12rem !important;
  }
  .pt-laptop-10 {
    padding-top: 14rem !important;
  }
  .pt-laptop-11 {
    padding-top: 21rem !important;
  }
  .pt-laptop-12 {
    padding-top: 16.5rem !important;
  }
  .pt-laptop-13 {
    padding-top: 17rem !important;
  }
  .pt-laptop-14 {
    padding-top: 18rem !important;
  }
  .pt-laptop-15 {
    padding-top: 19rem !important;
  }
  .pt-laptop-16 {
    padding-top: 20rem !important;
  }
  .pt-laptop-17 {
    padding-top: 21rem !important;
  }
  .pt-laptop-18 {
    padding-top: 22rem !important;
  }
  .pt-laptop-19 {
    padding-top: 23rem !important;
  }
  .pt-laptop-20 {
    padding-top: 24rem !important;
  }
  .pt-laptop-21 {
    padding-top: 25rem !important;
  }
  .pt-laptop-22 {
    padding-top: 26rem !important;
  }
  .pt-laptop-23 {
    padding-top: 27rem !important;
  }
  .pt-laptop-24 {
    padding-top: 28rem !important;
  }
  .pt-laptop-25 {
    padding-top: 29rem !important;
  }
  .pt-laptop-26 {
    padding-top: 30rem !important;
  }
  .pt-laptop-27 {
    padding-top: 31rem !important;
  }
  .pt-laptop-28 {
    padding-top: 32rem !important;
  }
  .pt-laptop-29 {
    padding-top: 33rem !important;
  }
  .pt-laptop-30 {
    padding-top: 34rem !important;
  }
  .pt-laptop-nav {
    padding-top: 0.5rem !important;
  }
  .pt-laptop-100 {
    padding-top: 6.25rem !important;
  }
  .pe-laptop-0 {
    padding-right: 0 !important;
  }
  .pe-laptop-1 {
    padding-right: 0.25rem !important;
  }
  .pe-laptop-2 {
    padding-right: 0.4rem !important;
  }
  .pe-laptop-3 {
    padding-right: 0.8rem !important;
  }
  .pe-laptop-35 {
    padding-right: 1.25rem !important;
  }
  .pe-laptop-4 {
    padding-right: 1.875rem !important;
  }
  .pe-laptop-45 {
    padding-right: 2.25rem !important;
  }
  .pe-laptop-5 {
    padding-right: 3rem !important;
  }
  .pe-laptop-55 {
    padding-right: 3.5rem !important;
  }
  .pe-laptop-6 {
    padding-right: 3.85rem !important;
  }
  .pe-laptop-65 {
    padding-right: 5rem !important;
  }
  .pe-laptop-68 {
    padding-right: 5.5rem !important;
  }
  .pe-laptop-7 {
    padding-right: 6.5rem !important;
  }
  .pe-laptop-75 {
    padding-right: 7rem !important;
  }
  .pe-laptop-8 {
    padding-right: 8rem !important;
  }
  .pe-laptop-9 {
    padding-right: 10rem !important;
  }
  .pe-laptop-95 {
    padding-right: 12rem !important;
  }
  .pe-laptop-10 {
    padding-right: 14rem !important;
  }
  .pe-laptop-11 {
    padding-right: 21rem !important;
  }
  .pe-laptop-12 {
    padding-right: 16.5rem !important;
  }
  .pe-laptop-13 {
    padding-right: 17rem !important;
  }
  .pe-laptop-14 {
    padding-right: 18rem !important;
  }
  .pe-laptop-15 {
    padding-right: 19rem !important;
  }
  .pe-laptop-16 {
    padding-right: 20rem !important;
  }
  .pe-laptop-17 {
    padding-right: 21rem !important;
  }
  .pe-laptop-18 {
    padding-right: 22rem !important;
  }
  .pe-laptop-19 {
    padding-right: 23rem !important;
  }
  .pe-laptop-20 {
    padding-right: 24rem !important;
  }
  .pe-laptop-21 {
    padding-right: 25rem !important;
  }
  .pe-laptop-22 {
    padding-right: 26rem !important;
  }
  .pe-laptop-23 {
    padding-right: 27rem !important;
  }
  .pe-laptop-24 {
    padding-right: 28rem !important;
  }
  .pe-laptop-25 {
    padding-right: 29rem !important;
  }
  .pe-laptop-26 {
    padding-right: 30rem !important;
  }
  .pe-laptop-27 {
    padding-right: 31rem !important;
  }
  .pe-laptop-28 {
    padding-right: 32rem !important;
  }
  .pe-laptop-29 {
    padding-right: 33rem !important;
  }
  .pe-laptop-30 {
    padding-right: 34rem !important;
  }
  .pe-laptop-nav {
    padding-right: 0.5rem !important;
  }
  .pe-laptop-100 {
    padding-right: 6.25rem !important;
  }
  .pb-laptop-0 {
    padding-bottom: 0 !important;
  }
  .pb-laptop-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-laptop-2 {
    padding-bottom: 0.4rem !important;
  }
  .pb-laptop-3 {
    padding-bottom: 0.8rem !important;
  }
  .pb-laptop-35 {
    padding-bottom: 1.25rem !important;
  }
  .pb-laptop-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-laptop-45 {
    padding-bottom: 2.25rem !important;
  }
  .pb-laptop-5 {
    padding-bottom: 3rem !important;
  }
  .pb-laptop-55 {
    padding-bottom: 3.5rem !important;
  }
  .pb-laptop-6 {
    padding-bottom: 3.85rem !important;
  }
  .pb-laptop-65 {
    padding-bottom: 5rem !important;
  }
  .pb-laptop-68 {
    padding-bottom: 5.5rem !important;
  }
  .pb-laptop-7 {
    padding-bottom: 6.5rem !important;
  }
  .pb-laptop-75 {
    padding-bottom: 7rem !important;
  }
  .pb-laptop-8 {
    padding-bottom: 8rem !important;
  }
  .pb-laptop-9 {
    padding-bottom: 10rem !important;
  }
  .pb-laptop-95 {
    padding-bottom: 12rem !important;
  }
  .pb-laptop-10 {
    padding-bottom: 14rem !important;
  }
  .pb-laptop-11 {
    padding-bottom: 21rem !important;
  }
  .pb-laptop-12 {
    padding-bottom: 16.5rem !important;
  }
  .pb-laptop-13 {
    padding-bottom: 17rem !important;
  }
  .pb-laptop-14 {
    padding-bottom: 18rem !important;
  }
  .pb-laptop-15 {
    padding-bottom: 19rem !important;
  }
  .pb-laptop-16 {
    padding-bottom: 20rem !important;
  }
  .pb-laptop-17 {
    padding-bottom: 21rem !important;
  }
  .pb-laptop-18 {
    padding-bottom: 22rem !important;
  }
  .pb-laptop-19 {
    padding-bottom: 23rem !important;
  }
  .pb-laptop-20 {
    padding-bottom: 24rem !important;
  }
  .pb-laptop-21 {
    padding-bottom: 25rem !important;
  }
  .pb-laptop-22 {
    padding-bottom: 26rem !important;
  }
  .pb-laptop-23 {
    padding-bottom: 27rem !important;
  }
  .pb-laptop-24 {
    padding-bottom: 28rem !important;
  }
  .pb-laptop-25 {
    padding-bottom: 29rem !important;
  }
  .pb-laptop-26 {
    padding-bottom: 30rem !important;
  }
  .pb-laptop-27 {
    padding-bottom: 31rem !important;
  }
  .pb-laptop-28 {
    padding-bottom: 32rem !important;
  }
  .pb-laptop-29 {
    padding-bottom: 33rem !important;
  }
  .pb-laptop-30 {
    padding-bottom: 34rem !important;
  }
  .pb-laptop-nav {
    padding-bottom: 0.5rem !important;
  }
  .pb-laptop-100 {
    padding-bottom: 6.25rem !important;
  }
  .ps-laptop-0 {
    padding-left: 0 !important;
  }
  .ps-laptop-1 {
    padding-left: 0.25rem !important;
  }
  .ps-laptop-2 {
    padding-left: 0.4rem !important;
  }
  .ps-laptop-3 {
    padding-left: 0.8rem !important;
  }
  .ps-laptop-35 {
    padding-left: 1.25rem !important;
  }
  .ps-laptop-4 {
    padding-left: 1.875rem !important;
  }
  .ps-laptop-45 {
    padding-left: 2.25rem !important;
  }
  .ps-laptop-5 {
    padding-left: 3rem !important;
  }
  .ps-laptop-55 {
    padding-left: 3.5rem !important;
  }
  .ps-laptop-6 {
    padding-left: 3.85rem !important;
  }
  .ps-laptop-65 {
    padding-left: 5rem !important;
  }
  .ps-laptop-68 {
    padding-left: 5.5rem !important;
  }
  .ps-laptop-7 {
    padding-left: 6.5rem !important;
  }
  .ps-laptop-75 {
    padding-left: 7rem !important;
  }
  .ps-laptop-8 {
    padding-left: 8rem !important;
  }
  .ps-laptop-9 {
    padding-left: 10rem !important;
  }
  .ps-laptop-95 {
    padding-left: 12rem !important;
  }
  .ps-laptop-10 {
    padding-left: 14rem !important;
  }
  .ps-laptop-11 {
    padding-left: 21rem !important;
  }
  .ps-laptop-12 {
    padding-left: 16.5rem !important;
  }
  .ps-laptop-13 {
    padding-left: 17rem !important;
  }
  .ps-laptop-14 {
    padding-left: 18rem !important;
  }
  .ps-laptop-15 {
    padding-left: 19rem !important;
  }
  .ps-laptop-16 {
    padding-left: 20rem !important;
  }
  .ps-laptop-17 {
    padding-left: 21rem !important;
  }
  .ps-laptop-18 {
    padding-left: 22rem !important;
  }
  .ps-laptop-19 {
    padding-left: 23rem !important;
  }
  .ps-laptop-20 {
    padding-left: 24rem !important;
  }
  .ps-laptop-21 {
    padding-left: 25rem !important;
  }
  .ps-laptop-22 {
    padding-left: 26rem !important;
  }
  .ps-laptop-23 {
    padding-left: 27rem !important;
  }
  .ps-laptop-24 {
    padding-left: 28rem !important;
  }
  .ps-laptop-25 {
    padding-left: 29rem !important;
  }
  .ps-laptop-26 {
    padding-left: 30rem !important;
  }
  .ps-laptop-27 {
    padding-left: 31rem !important;
  }
  .ps-laptop-28 {
    padding-left: 32rem !important;
  }
  .ps-laptop-29 {
    padding-left: 33rem !important;
  }
  .ps-laptop-30 {
    padding-left: 34rem !important;
  }
  .ps-laptop-nav {
    padding-left: 0.5rem !important;
  }
  .ps-laptop-100 {
    padding-left: 6.25rem !important;
  }
  .gap-laptop-0 {
    gap: 0 !important;
  }
  .gap-laptop-1 {
    gap: 0.25rem !important;
  }
  .gap-laptop-2 {
    gap: 0.4rem !important;
  }
  .gap-laptop-3 {
    gap: 0.8rem !important;
  }
  .gap-laptop-35 {
    gap: 1.25rem !important;
  }
  .gap-laptop-4 {
    gap: 1.875rem !important;
  }
  .gap-laptop-45 {
    gap: 2.25rem !important;
  }
  .gap-laptop-5 {
    gap: 3rem !important;
  }
  .gap-laptop-55 {
    gap: 3.5rem !important;
  }
  .gap-laptop-6 {
    gap: 3.85rem !important;
  }
  .gap-laptop-65 {
    gap: 5rem !important;
  }
  .gap-laptop-68 {
    gap: 5.5rem !important;
  }
  .gap-laptop-7 {
    gap: 6.5rem !important;
  }
  .gap-laptop-75 {
    gap: 7rem !important;
  }
  .gap-laptop-8 {
    gap: 8rem !important;
  }
  .gap-laptop-9 {
    gap: 10rem !important;
  }
  .gap-laptop-95 {
    gap: 12rem !important;
  }
  .gap-laptop-10 {
    gap: 14rem !important;
  }
  .gap-laptop-11 {
    gap: 21rem !important;
  }
  .gap-laptop-12 {
    gap: 16.5rem !important;
  }
  .gap-laptop-13 {
    gap: 17rem !important;
  }
  .gap-laptop-14 {
    gap: 18rem !important;
  }
  .gap-laptop-15 {
    gap: 19rem !important;
  }
  .gap-laptop-16 {
    gap: 20rem !important;
  }
  .gap-laptop-17 {
    gap: 21rem !important;
  }
  .gap-laptop-18 {
    gap: 22rem !important;
  }
  .gap-laptop-19 {
    gap: 23rem !important;
  }
  .gap-laptop-20 {
    gap: 24rem !important;
  }
  .gap-laptop-21 {
    gap: 25rem !important;
  }
  .gap-laptop-22 {
    gap: 26rem !important;
  }
  .gap-laptop-23 {
    gap: 27rem !important;
  }
  .gap-laptop-24 {
    gap: 28rem !important;
  }
  .gap-laptop-25 {
    gap: 29rem !important;
  }
  .gap-laptop-26 {
    gap: 30rem !important;
  }
  .gap-laptop-27 {
    gap: 31rem !important;
  }
  .gap-laptop-28 {
    gap: 32rem !important;
  }
  .gap-laptop-29 {
    gap: 33rem !important;
  }
  .gap-laptop-30 {
    gap: 34rem !important;
  }
  .gap-laptop-nav {
    gap: 0.5rem !important;
  }
  .gap-laptop-100 {
    gap: 6.25rem !important;
  }
  .row-gap-laptop-0 {
    row-gap: 0 !important;
  }
  .row-gap-laptop-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-laptop-2 {
    row-gap: 0.4rem !important;
  }
  .row-gap-laptop-3 {
    row-gap: 0.8rem !important;
  }
  .row-gap-laptop-35 {
    row-gap: 1.25rem !important;
  }
  .row-gap-laptop-4 {
    row-gap: 1.875rem !important;
  }
  .row-gap-laptop-45 {
    row-gap: 2.25rem !important;
  }
  .row-gap-laptop-5 {
    row-gap: 3rem !important;
  }
  .row-gap-laptop-55 {
    row-gap: 3.5rem !important;
  }
  .row-gap-laptop-6 {
    row-gap: 3.85rem !important;
  }
  .row-gap-laptop-65 {
    row-gap: 5rem !important;
  }
  .row-gap-laptop-68 {
    row-gap: 5.5rem !important;
  }
  .row-gap-laptop-7 {
    row-gap: 6.5rem !important;
  }
  .row-gap-laptop-75 {
    row-gap: 7rem !important;
  }
  .row-gap-laptop-8 {
    row-gap: 8rem !important;
  }
  .row-gap-laptop-9 {
    row-gap: 10rem !important;
  }
  .row-gap-laptop-95 {
    row-gap: 12rem !important;
  }
  .row-gap-laptop-10 {
    row-gap: 14rem !important;
  }
  .row-gap-laptop-11 {
    row-gap: 21rem !important;
  }
  .row-gap-laptop-12 {
    row-gap: 16.5rem !important;
  }
  .row-gap-laptop-13 {
    row-gap: 17rem !important;
  }
  .row-gap-laptop-14 {
    row-gap: 18rem !important;
  }
  .row-gap-laptop-15 {
    row-gap: 19rem !important;
  }
  .row-gap-laptop-16 {
    row-gap: 20rem !important;
  }
  .row-gap-laptop-17 {
    row-gap: 21rem !important;
  }
  .row-gap-laptop-18 {
    row-gap: 22rem !important;
  }
  .row-gap-laptop-19 {
    row-gap: 23rem !important;
  }
  .row-gap-laptop-20 {
    row-gap: 24rem !important;
  }
  .row-gap-laptop-21 {
    row-gap: 25rem !important;
  }
  .row-gap-laptop-22 {
    row-gap: 26rem !important;
  }
  .row-gap-laptop-23 {
    row-gap: 27rem !important;
  }
  .row-gap-laptop-24 {
    row-gap: 28rem !important;
  }
  .row-gap-laptop-25 {
    row-gap: 29rem !important;
  }
  .row-gap-laptop-26 {
    row-gap: 30rem !important;
  }
  .row-gap-laptop-27 {
    row-gap: 31rem !important;
  }
  .row-gap-laptop-28 {
    row-gap: 32rem !important;
  }
  .row-gap-laptop-29 {
    row-gap: 33rem !important;
  }
  .row-gap-laptop-30 {
    row-gap: 34rem !important;
  }
  .row-gap-laptop-nav {
    row-gap: 0.5rem !important;
  }
  .row-gap-laptop-100 {
    row-gap: 6.25rem !important;
  }
  .column-gap-laptop-0 {
    column-gap: 0 !important;
  }
  .column-gap-laptop-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-laptop-2 {
    column-gap: 0.4rem !important;
  }
  .column-gap-laptop-3 {
    column-gap: 0.8rem !important;
  }
  .column-gap-laptop-35 {
    column-gap: 1.25rem !important;
  }
  .column-gap-laptop-4 {
    column-gap: 1.875rem !important;
  }
  .column-gap-laptop-45 {
    column-gap: 2.25rem !important;
  }
  .column-gap-laptop-5 {
    column-gap: 3rem !important;
  }
  .column-gap-laptop-55 {
    column-gap: 3.5rem !important;
  }
  .column-gap-laptop-6 {
    column-gap: 3.85rem !important;
  }
  .column-gap-laptop-65 {
    column-gap: 5rem !important;
  }
  .column-gap-laptop-68 {
    column-gap: 5.5rem !important;
  }
  .column-gap-laptop-7 {
    column-gap: 6.5rem !important;
  }
  .column-gap-laptop-75 {
    column-gap: 7rem !important;
  }
  .column-gap-laptop-8 {
    column-gap: 8rem !important;
  }
  .column-gap-laptop-9 {
    column-gap: 10rem !important;
  }
  .column-gap-laptop-95 {
    column-gap: 12rem !important;
  }
  .column-gap-laptop-10 {
    column-gap: 14rem !important;
  }
  .column-gap-laptop-11 {
    column-gap: 21rem !important;
  }
  .column-gap-laptop-12 {
    column-gap: 16.5rem !important;
  }
  .column-gap-laptop-13 {
    column-gap: 17rem !important;
  }
  .column-gap-laptop-14 {
    column-gap: 18rem !important;
  }
  .column-gap-laptop-15 {
    column-gap: 19rem !important;
  }
  .column-gap-laptop-16 {
    column-gap: 20rem !important;
  }
  .column-gap-laptop-17 {
    column-gap: 21rem !important;
  }
  .column-gap-laptop-18 {
    column-gap: 22rem !important;
  }
  .column-gap-laptop-19 {
    column-gap: 23rem !important;
  }
  .column-gap-laptop-20 {
    column-gap: 24rem !important;
  }
  .column-gap-laptop-21 {
    column-gap: 25rem !important;
  }
  .column-gap-laptop-22 {
    column-gap: 26rem !important;
  }
  .column-gap-laptop-23 {
    column-gap: 27rem !important;
  }
  .column-gap-laptop-24 {
    column-gap: 28rem !important;
  }
  .column-gap-laptop-25 {
    column-gap: 29rem !important;
  }
  .column-gap-laptop-26 {
    column-gap: 30rem !important;
  }
  .column-gap-laptop-27 {
    column-gap: 31rem !important;
  }
  .column-gap-laptop-28 {
    column-gap: 32rem !important;
  }
  .column-gap-laptop-29 {
    column-gap: 33rem !important;
  }
  .column-gap-laptop-30 {
    column-gap: 34rem !important;
  }
  .column-gap-laptop-nav {
    column-gap: 0.5rem !important;
  }
  .column-gap-laptop-100 {
    column-gap: 6.25rem !important;
  }
  .text-laptop-start {
    text-align: left !important;
  }
  .text-laptop-end {
    text-align: right !important;
  }
  .text-laptop-center {
    text-align: center !important;
  }
}
@media (min-width: 1441px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.4rem !important;
  }
  .m-xxl-3 {
    margin: 0.8rem !important;
  }
  .m-xxl-35 {
    margin: 1.25rem !important;
  }
  .m-xxl-4 {
    margin: 1.875rem !important;
  }
  .m-xxl-45 {
    margin: 2.25rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-55 {
    margin: 3.5rem !important;
  }
  .m-xxl-6 {
    margin: 3.85rem !important;
  }
  .m-xxl-65 {
    margin: 5rem !important;
  }
  .m-xxl-68 {
    margin: 5.5rem !important;
  }
  .m-xxl-7 {
    margin: 6.5rem !important;
  }
  .m-xxl-75 {
    margin: 7rem !important;
  }
  .m-xxl-8 {
    margin: 8rem !important;
  }
  .m-xxl-9 {
    margin: 10rem !important;
  }
  .m-xxl-95 {
    margin: 12rem !important;
  }
  .m-xxl-10 {
    margin: 14rem !important;
  }
  .m-xxl-11 {
    margin: 21rem !important;
  }
  .m-xxl-12 {
    margin: 16.5rem !important;
  }
  .m-xxl-13 {
    margin: 17rem !important;
  }
  .m-xxl-14 {
    margin: 18rem !important;
  }
  .m-xxl-15 {
    margin: 19rem !important;
  }
  .m-xxl-16 {
    margin: 20rem !important;
  }
  .m-xxl-17 {
    margin: 21rem !important;
  }
  .m-xxl-18 {
    margin: 22rem !important;
  }
  .m-xxl-19 {
    margin: 23rem !important;
  }
  .m-xxl-20 {
    margin: 24rem !important;
  }
  .m-xxl-21 {
    margin: 25rem !important;
  }
  .m-xxl-22 {
    margin: 26rem !important;
  }
  .m-xxl-23 {
    margin: 27rem !important;
  }
  .m-xxl-24 {
    margin: 28rem !important;
  }
  .m-xxl-25 {
    margin: 29rem !important;
  }
  .m-xxl-26 {
    margin: 30rem !important;
  }
  .m-xxl-27 {
    margin: 31rem !important;
  }
  .m-xxl-28 {
    margin: 32rem !important;
  }
  .m-xxl-29 {
    margin: 33rem !important;
  }
  .m-xxl-30 {
    margin: 34rem !important;
  }
  .m-xxl-nav {
    margin: 0.5rem !important;
  }
  .m-xxl-100 {
    margin: 6.25rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important;
  }
  .mx-xxl-3 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important;
  }
  .mx-xxl-35 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xxl-45 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-55 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xxl-6 {
    margin-right: 3.85rem !important;
    margin-left: 3.85rem !important;
  }
  .mx-xxl-65 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-68 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-xxl-7 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-xxl-75 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xxl-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xxl-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xxl-95 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-xxl-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-xxl-11 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-xxl-12 {
    margin-right: 16.5rem !important;
    margin-left: 16.5rem !important;
  }
  .mx-xxl-13 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-xxl-14 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-xxl-15 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-xxl-16 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-xxl-17 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-xxl-18 {
    margin-right: 22rem !important;
    margin-left: 22rem !important;
  }
  .mx-xxl-19 {
    margin-right: 23rem !important;
    margin-left: 23rem !important;
  }
  .mx-xxl-20 {
    margin-right: 24rem !important;
    margin-left: 24rem !important;
  }
  .mx-xxl-21 {
    margin-right: 25rem !important;
    margin-left: 25rem !important;
  }
  .mx-xxl-22 {
    margin-right: 26rem !important;
    margin-left: 26rem !important;
  }
  .mx-xxl-23 {
    margin-right: 27rem !important;
    margin-left: 27rem !important;
  }
  .mx-xxl-24 {
    margin-right: 28rem !important;
    margin-left: 28rem !important;
  }
  .mx-xxl-25 {
    margin-right: 29rem !important;
    margin-left: 29rem !important;
  }
  .mx-xxl-26 {
    margin-right: 30rem !important;
    margin-left: 30rem !important;
  }
  .mx-xxl-27 {
    margin-right: 31rem !important;
    margin-left: 31rem !important;
  }
  .mx-xxl-28 {
    margin-right: 32rem !important;
    margin-left: 32rem !important;
  }
  .mx-xxl-29 {
    margin-right: 33rem !important;
    margin-left: 33rem !important;
  }
  .mx-xxl-30 {
    margin-right: 34rem !important;
    margin-left: 34rem !important;
  }
  .mx-xxl-nav {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  .my-xxl-3 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important;
  }
  .my-xxl-35 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xxl-45 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-55 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xxl-6 {
    margin-top: 3.85rem !important;
    margin-bottom: 3.85rem !important;
  }
  .my-xxl-65 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-68 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-xxl-7 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-xxl-75 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xxl-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xxl-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xxl-95 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-xxl-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-xxl-11 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-xxl-12 {
    margin-top: 16.5rem !important;
    margin-bottom: 16.5rem !important;
  }
  .my-xxl-13 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-xxl-14 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-xxl-15 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-xxl-16 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-xxl-17 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-xxl-18 {
    margin-top: 22rem !important;
    margin-bottom: 22rem !important;
  }
  .my-xxl-19 {
    margin-top: 23rem !important;
    margin-bottom: 23rem !important;
  }
  .my-xxl-20 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }
  .my-xxl-21 {
    margin-top: 25rem !important;
    margin-bottom: 25rem !important;
  }
  .my-xxl-22 {
    margin-top: 26rem !important;
    margin-bottom: 26rem !important;
  }
  .my-xxl-23 {
    margin-top: 27rem !important;
    margin-bottom: 27rem !important;
  }
  .my-xxl-24 {
    margin-top: 28rem !important;
    margin-bottom: 28rem !important;
  }
  .my-xxl-25 {
    margin-top: 29rem !important;
    margin-bottom: 29rem !important;
  }
  .my-xxl-26 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important;
  }
  .my-xxl-27 {
    margin-top: 31rem !important;
    margin-bottom: 31rem !important;
  }
  .my-xxl-28 {
    margin-top: 32rem !important;
    margin-bottom: 32rem !important;
  }
  .my-xxl-29 {
    margin-top: 33rem !important;
    margin-bottom: 33rem !important;
  }
  .my-xxl-30 {
    margin-top: 34rem !important;
    margin-bottom: 34rem !important;
  }
  .my-xxl-nav {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.4rem !important;
  }
  .mt-xxl-3 {
    margin-top: 0.8rem !important;
  }
  .mt-xxl-35 {
    margin-top: 1.25rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.875rem !important;
  }
  .mt-xxl-45 {
    margin-top: 2.25rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-55 {
    margin-top: 3.5rem !important;
  }
  .mt-xxl-6 {
    margin-top: 3.85rem !important;
  }
  .mt-xxl-65 {
    margin-top: 5rem !important;
  }
  .mt-xxl-68 {
    margin-top: 5.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 6.5rem !important;
  }
  .mt-xxl-75 {
    margin-top: 7rem !important;
  }
  .mt-xxl-8 {
    margin-top: 8rem !important;
  }
  .mt-xxl-9 {
    margin-top: 10rem !important;
  }
  .mt-xxl-95 {
    margin-top: 12rem !important;
  }
  .mt-xxl-10 {
    margin-top: 14rem !important;
  }
  .mt-xxl-11 {
    margin-top: 21rem !important;
  }
  .mt-xxl-12 {
    margin-top: 16.5rem !important;
  }
  .mt-xxl-13 {
    margin-top: 17rem !important;
  }
  .mt-xxl-14 {
    margin-top: 18rem !important;
  }
  .mt-xxl-15 {
    margin-top: 19rem !important;
  }
  .mt-xxl-16 {
    margin-top: 20rem !important;
  }
  .mt-xxl-17 {
    margin-top: 21rem !important;
  }
  .mt-xxl-18 {
    margin-top: 22rem !important;
  }
  .mt-xxl-19 {
    margin-top: 23rem !important;
  }
  .mt-xxl-20 {
    margin-top: 24rem !important;
  }
  .mt-xxl-21 {
    margin-top: 25rem !important;
  }
  .mt-xxl-22 {
    margin-top: 26rem !important;
  }
  .mt-xxl-23 {
    margin-top: 27rem !important;
  }
  .mt-xxl-24 {
    margin-top: 28rem !important;
  }
  .mt-xxl-25 {
    margin-top: 29rem !important;
  }
  .mt-xxl-26 {
    margin-top: 30rem !important;
  }
  .mt-xxl-27 {
    margin-top: 31rem !important;
  }
  .mt-xxl-28 {
    margin-top: 32rem !important;
  }
  .mt-xxl-29 {
    margin-top: 33rem !important;
  }
  .mt-xxl-30 {
    margin-top: 34rem !important;
  }
  .mt-xxl-nav {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-100 {
    margin-top: 6.25rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.4rem !important;
  }
  .me-xxl-3 {
    margin-right: 0.8rem !important;
  }
  .me-xxl-35 {
    margin-right: 1.25rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.875rem !important;
  }
  .me-xxl-45 {
    margin-right: 2.25rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-55 {
    margin-right: 3.5rem !important;
  }
  .me-xxl-6 {
    margin-right: 3.85rem !important;
  }
  .me-xxl-65 {
    margin-right: 5rem !important;
  }
  .me-xxl-68 {
    margin-right: 5.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 6.5rem !important;
  }
  .me-xxl-75 {
    margin-right: 7rem !important;
  }
  .me-xxl-8 {
    margin-right: 8rem !important;
  }
  .me-xxl-9 {
    margin-right: 10rem !important;
  }
  .me-xxl-95 {
    margin-right: 12rem !important;
  }
  .me-xxl-10 {
    margin-right: 14rem !important;
  }
  .me-xxl-11 {
    margin-right: 21rem !important;
  }
  .me-xxl-12 {
    margin-right: 16.5rem !important;
  }
  .me-xxl-13 {
    margin-right: 17rem !important;
  }
  .me-xxl-14 {
    margin-right: 18rem !important;
  }
  .me-xxl-15 {
    margin-right: 19rem !important;
  }
  .me-xxl-16 {
    margin-right: 20rem !important;
  }
  .me-xxl-17 {
    margin-right: 21rem !important;
  }
  .me-xxl-18 {
    margin-right: 22rem !important;
  }
  .me-xxl-19 {
    margin-right: 23rem !important;
  }
  .me-xxl-20 {
    margin-right: 24rem !important;
  }
  .me-xxl-21 {
    margin-right: 25rem !important;
  }
  .me-xxl-22 {
    margin-right: 26rem !important;
  }
  .me-xxl-23 {
    margin-right: 27rem !important;
  }
  .me-xxl-24 {
    margin-right: 28rem !important;
  }
  .me-xxl-25 {
    margin-right: 29rem !important;
  }
  .me-xxl-26 {
    margin-right: 30rem !important;
  }
  .me-xxl-27 {
    margin-right: 31rem !important;
  }
  .me-xxl-28 {
    margin-right: 32rem !important;
  }
  .me-xxl-29 {
    margin-right: 33rem !important;
  }
  .me-xxl-30 {
    margin-right: 34rem !important;
  }
  .me-xxl-nav {
    margin-right: 0.5rem !important;
  }
  .me-xxl-100 {
    margin-right: 6.25rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.4rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 0.8rem !important;
  }
  .mb-xxl-35 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xxl-45 {
    margin-bottom: 2.25rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-55 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 3.85rem !important;
  }
  .mb-xxl-65 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-68 {
    margin-bottom: 5.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 6.5rem !important;
  }
  .mb-xxl-75 {
    margin-bottom: 7rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-95 {
    margin-bottom: 12rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 14rem !important;
  }
  .mb-xxl-11 {
    margin-bottom: 21rem !important;
  }
  .mb-xxl-12 {
    margin-bottom: 16.5rem !important;
  }
  .mb-xxl-13 {
    margin-bottom: 17rem !important;
  }
  .mb-xxl-14 {
    margin-bottom: 18rem !important;
  }
  .mb-xxl-15 {
    margin-bottom: 19rem !important;
  }
  .mb-xxl-16 {
    margin-bottom: 20rem !important;
  }
  .mb-xxl-17 {
    margin-bottom: 21rem !important;
  }
  .mb-xxl-18 {
    margin-bottom: 22rem !important;
  }
  .mb-xxl-19 {
    margin-bottom: 23rem !important;
  }
  .mb-xxl-20 {
    margin-bottom: 24rem !important;
  }
  .mb-xxl-21 {
    margin-bottom: 25rem !important;
  }
  .mb-xxl-22 {
    margin-bottom: 26rem !important;
  }
  .mb-xxl-23 {
    margin-bottom: 27rem !important;
  }
  .mb-xxl-24 {
    margin-bottom: 28rem !important;
  }
  .mb-xxl-25 {
    margin-bottom: 29rem !important;
  }
  .mb-xxl-26 {
    margin-bottom: 30rem !important;
  }
  .mb-xxl-27 {
    margin-bottom: 31rem !important;
  }
  .mb-xxl-28 {
    margin-bottom: 32rem !important;
  }
  .mb-xxl-29 {
    margin-bottom: 33rem !important;
  }
  .mb-xxl-30 {
    margin-bottom: 34rem !important;
  }
  .mb-xxl-nav {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.4rem !important;
  }
  .ms-xxl-3 {
    margin-left: 0.8rem !important;
  }
  .ms-xxl-35 {
    margin-left: 1.25rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.875rem !important;
  }
  .ms-xxl-45 {
    margin-left: 2.25rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-55 {
    margin-left: 3.5rem !important;
  }
  .ms-xxl-6 {
    margin-left: 3.85rem !important;
  }
  .ms-xxl-65 {
    margin-left: 5rem !important;
  }
  .ms-xxl-68 {
    margin-left: 5.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 6.5rem !important;
  }
  .ms-xxl-75 {
    margin-left: 7rem !important;
  }
  .ms-xxl-8 {
    margin-left: 8rem !important;
  }
  .ms-xxl-9 {
    margin-left: 10rem !important;
  }
  .ms-xxl-95 {
    margin-left: 12rem !important;
  }
  .ms-xxl-10 {
    margin-left: 14rem !important;
  }
  .ms-xxl-11 {
    margin-left: 21rem !important;
  }
  .ms-xxl-12 {
    margin-left: 16.5rem !important;
  }
  .ms-xxl-13 {
    margin-left: 17rem !important;
  }
  .ms-xxl-14 {
    margin-left: 18rem !important;
  }
  .ms-xxl-15 {
    margin-left: 19rem !important;
  }
  .ms-xxl-16 {
    margin-left: 20rem !important;
  }
  .ms-xxl-17 {
    margin-left: 21rem !important;
  }
  .ms-xxl-18 {
    margin-left: 22rem !important;
  }
  .ms-xxl-19 {
    margin-left: 23rem !important;
  }
  .ms-xxl-20 {
    margin-left: 24rem !important;
  }
  .ms-xxl-21 {
    margin-left: 25rem !important;
  }
  .ms-xxl-22 {
    margin-left: 26rem !important;
  }
  .ms-xxl-23 {
    margin-left: 27rem !important;
  }
  .ms-xxl-24 {
    margin-left: 28rem !important;
  }
  .ms-xxl-25 {
    margin-left: 29rem !important;
  }
  .ms-xxl-26 {
    margin-left: 30rem !important;
  }
  .ms-xxl-27 {
    margin-left: 31rem !important;
  }
  .ms-xxl-28 {
    margin-left: 32rem !important;
  }
  .ms-xxl-29 {
    margin-left: 33rem !important;
  }
  .ms-xxl-30 {
    margin-left: 34rem !important;
  }
  .ms-xxl-nav {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-100 {
    margin-left: 6.25rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.4rem !important;
  }
  .p-xxl-3 {
    padding: 0.8rem !important;
  }
  .p-xxl-35 {
    padding: 1.25rem !important;
  }
  .p-xxl-4 {
    padding: 1.875rem !important;
  }
  .p-xxl-45 {
    padding: 2.25rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-55 {
    padding: 3.5rem !important;
  }
  .p-xxl-6 {
    padding: 3.85rem !important;
  }
  .p-xxl-65 {
    padding: 5rem !important;
  }
  .p-xxl-68 {
    padding: 5.5rem !important;
  }
  .p-xxl-7 {
    padding: 6.5rem !important;
  }
  .p-xxl-75 {
    padding: 7rem !important;
  }
  .p-xxl-8 {
    padding: 8rem !important;
  }
  .p-xxl-9 {
    padding: 10rem !important;
  }
  .p-xxl-95 {
    padding: 12rem !important;
  }
  .p-xxl-10 {
    padding: 14rem !important;
  }
  .p-xxl-11 {
    padding: 21rem !important;
  }
  .p-xxl-12 {
    padding: 16.5rem !important;
  }
  .p-xxl-13 {
    padding: 17rem !important;
  }
  .p-xxl-14 {
    padding: 18rem !important;
  }
  .p-xxl-15 {
    padding: 19rem !important;
  }
  .p-xxl-16 {
    padding: 20rem !important;
  }
  .p-xxl-17 {
    padding: 21rem !important;
  }
  .p-xxl-18 {
    padding: 22rem !important;
  }
  .p-xxl-19 {
    padding: 23rem !important;
  }
  .p-xxl-20 {
    padding: 24rem !important;
  }
  .p-xxl-21 {
    padding: 25rem !important;
  }
  .p-xxl-22 {
    padding: 26rem !important;
  }
  .p-xxl-23 {
    padding: 27rem !important;
  }
  .p-xxl-24 {
    padding: 28rem !important;
  }
  .p-xxl-25 {
    padding: 29rem !important;
  }
  .p-xxl-26 {
    padding: 30rem !important;
  }
  .p-xxl-27 {
    padding: 31rem !important;
  }
  .p-xxl-28 {
    padding: 32rem !important;
  }
  .p-xxl-29 {
    padding: 33rem !important;
  }
  .p-xxl-30 {
    padding: 34rem !important;
  }
  .p-xxl-nav {
    padding: 0.5rem !important;
  }
  .p-xxl-100 {
    padding: 6.25rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
  }
  .px-xxl-3 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important;
  }
  .px-xxl-35 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xxl-45 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-55 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xxl-6 {
    padding-right: 3.85rem !important;
    padding-left: 3.85rem !important;
  }
  .px-xxl-65 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-68 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-xxl-7 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-xxl-75 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-xxl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xxl-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xxl-95 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-xxl-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-xxl-11 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-xxl-12 {
    padding-right: 16.5rem !important;
    padding-left: 16.5rem !important;
  }
  .px-xxl-13 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-xxl-14 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-xxl-15 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-xxl-16 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-xxl-17 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-xxl-18 {
    padding-right: 22rem !important;
    padding-left: 22rem !important;
  }
  .px-xxl-19 {
    padding-right: 23rem !important;
    padding-left: 23rem !important;
  }
  .px-xxl-20 {
    padding-right: 24rem !important;
    padding-left: 24rem !important;
  }
  .px-xxl-21 {
    padding-right: 25rem !important;
    padding-left: 25rem !important;
  }
  .px-xxl-22 {
    padding-right: 26rem !important;
    padding-left: 26rem !important;
  }
  .px-xxl-23 {
    padding-right: 27rem !important;
    padding-left: 27rem !important;
  }
  .px-xxl-24 {
    padding-right: 28rem !important;
    padding-left: 28rem !important;
  }
  .px-xxl-25 {
    padding-right: 29rem !important;
    padding-left: 29rem !important;
  }
  .px-xxl-26 {
    padding-right: 30rem !important;
    padding-left: 30rem !important;
  }
  .px-xxl-27 {
    padding-right: 31rem !important;
    padding-left: 31rem !important;
  }
  .px-xxl-28 {
    padding-right: 32rem !important;
    padding-left: 32rem !important;
  }
  .px-xxl-29 {
    padding-right: 33rem !important;
    padding-left: 33rem !important;
  }
  .px-xxl-30 {
    padding-right: 34rem !important;
    padding-left: 34rem !important;
  }
  .px-xxl-nav {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
  }
  .py-xxl-3 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }
  .py-xxl-35 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xxl-45 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-55 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xxl-6 {
    padding-top: 3.85rem !important;
    padding-bottom: 3.85rem !important;
  }
  .py-xxl-65 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-68 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-xxl-7 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-xxl-75 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-xxl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xxl-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xxl-95 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-xxl-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-xxl-11 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-xxl-12 {
    padding-top: 16.5rem !important;
    padding-bottom: 16.5rem !important;
  }
  .py-xxl-13 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-xxl-14 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-xxl-15 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-xxl-16 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-xxl-17 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-xxl-18 {
    padding-top: 22rem !important;
    padding-bottom: 22rem !important;
  }
  .py-xxl-19 {
    padding-top: 23rem !important;
    padding-bottom: 23rem !important;
  }
  .py-xxl-20 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
  .py-xxl-21 {
    padding-top: 25rem !important;
    padding-bottom: 25rem !important;
  }
  .py-xxl-22 {
    padding-top: 26rem !important;
    padding-bottom: 26rem !important;
  }
  .py-xxl-23 {
    padding-top: 27rem !important;
    padding-bottom: 27rem !important;
  }
  .py-xxl-24 {
    padding-top: 28rem !important;
    padding-bottom: 28rem !important;
  }
  .py-xxl-25 {
    padding-top: 29rem !important;
    padding-bottom: 29rem !important;
  }
  .py-xxl-26 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important;
  }
  .py-xxl-27 {
    padding-top: 31rem !important;
    padding-bottom: 31rem !important;
  }
  .py-xxl-28 {
    padding-top: 32rem !important;
    padding-bottom: 32rem !important;
  }
  .py-xxl-29 {
    padding-top: 33rem !important;
    padding-bottom: 33rem !important;
  }
  .py-xxl-30 {
    padding-top: 34rem !important;
    padding-bottom: 34rem !important;
  }
  .py-xxl-nav {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.4rem !important;
  }
  .pt-xxl-3 {
    padding-top: 0.8rem !important;
  }
  .pt-xxl-35 {
    padding-top: 1.25rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.875rem !important;
  }
  .pt-xxl-45 {
    padding-top: 2.25rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-55 {
    padding-top: 3.5rem !important;
  }
  .pt-xxl-6 {
    padding-top: 3.85rem !important;
  }
  .pt-xxl-65 {
    padding-top: 5rem !important;
  }
  .pt-xxl-68 {
    padding-top: 5.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 6.5rem !important;
  }
  .pt-xxl-75 {
    padding-top: 7rem !important;
  }
  .pt-xxl-8 {
    padding-top: 8rem !important;
  }
  .pt-xxl-9 {
    padding-top: 10rem !important;
  }
  .pt-xxl-95 {
    padding-top: 12rem !important;
  }
  .pt-xxl-10 {
    padding-top: 14rem !important;
  }
  .pt-xxl-11 {
    padding-top: 21rem !important;
  }
  .pt-xxl-12 {
    padding-top: 16.5rem !important;
  }
  .pt-xxl-13 {
    padding-top: 17rem !important;
  }
  .pt-xxl-14 {
    padding-top: 18rem !important;
  }
  .pt-xxl-15 {
    padding-top: 19rem !important;
  }
  .pt-xxl-16 {
    padding-top: 20rem !important;
  }
  .pt-xxl-17 {
    padding-top: 21rem !important;
  }
  .pt-xxl-18 {
    padding-top: 22rem !important;
  }
  .pt-xxl-19 {
    padding-top: 23rem !important;
  }
  .pt-xxl-20 {
    padding-top: 24rem !important;
  }
  .pt-xxl-21 {
    padding-top: 25rem !important;
  }
  .pt-xxl-22 {
    padding-top: 26rem !important;
  }
  .pt-xxl-23 {
    padding-top: 27rem !important;
  }
  .pt-xxl-24 {
    padding-top: 28rem !important;
  }
  .pt-xxl-25 {
    padding-top: 29rem !important;
  }
  .pt-xxl-26 {
    padding-top: 30rem !important;
  }
  .pt-xxl-27 {
    padding-top: 31rem !important;
  }
  .pt-xxl-28 {
    padding-top: 32rem !important;
  }
  .pt-xxl-29 {
    padding-top: 33rem !important;
  }
  .pt-xxl-30 {
    padding-top: 34rem !important;
  }
  .pt-xxl-nav {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-100 {
    padding-top: 6.25rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.4rem !important;
  }
  .pe-xxl-3 {
    padding-right: 0.8rem !important;
  }
  .pe-xxl-35 {
    padding-right: 1.25rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.875rem !important;
  }
  .pe-xxl-45 {
    padding-right: 2.25rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxl-55 {
    padding-right: 3.5rem !important;
  }
  .pe-xxl-6 {
    padding-right: 3.85rem !important;
  }
  .pe-xxl-65 {
    padding-right: 5rem !important;
  }
  .pe-xxl-68 {
    padding-right: 5.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 6.5rem !important;
  }
  .pe-xxl-75 {
    padding-right: 7rem !important;
  }
  .pe-xxl-8 {
    padding-right: 8rem !important;
  }
  .pe-xxl-9 {
    padding-right: 10rem !important;
  }
  .pe-xxl-95 {
    padding-right: 12rem !important;
  }
  .pe-xxl-10 {
    padding-right: 14rem !important;
  }
  .pe-xxl-11 {
    padding-right: 21rem !important;
  }
  .pe-xxl-12 {
    padding-right: 16.5rem !important;
  }
  .pe-xxl-13 {
    padding-right: 17rem !important;
  }
  .pe-xxl-14 {
    padding-right: 18rem !important;
  }
  .pe-xxl-15 {
    padding-right: 19rem !important;
  }
  .pe-xxl-16 {
    padding-right: 20rem !important;
  }
  .pe-xxl-17 {
    padding-right: 21rem !important;
  }
  .pe-xxl-18 {
    padding-right: 22rem !important;
  }
  .pe-xxl-19 {
    padding-right: 23rem !important;
  }
  .pe-xxl-20 {
    padding-right: 24rem !important;
  }
  .pe-xxl-21 {
    padding-right: 25rem !important;
  }
  .pe-xxl-22 {
    padding-right: 26rem !important;
  }
  .pe-xxl-23 {
    padding-right: 27rem !important;
  }
  .pe-xxl-24 {
    padding-right: 28rem !important;
  }
  .pe-xxl-25 {
    padding-right: 29rem !important;
  }
  .pe-xxl-26 {
    padding-right: 30rem !important;
  }
  .pe-xxl-27 {
    padding-right: 31rem !important;
  }
  .pe-xxl-28 {
    padding-right: 32rem !important;
  }
  .pe-xxl-29 {
    padding-right: 33rem !important;
  }
  .pe-xxl-30 {
    padding-right: 34rem !important;
  }
  .pe-xxl-nav {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-100 {
    padding-right: 6.25rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.4rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 0.8rem !important;
  }
  .pb-xxl-35 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xxl-45 {
    padding-bottom: 2.25rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-55 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 3.85rem !important;
  }
  .pb-xxl-65 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-68 {
    padding-bottom: 5.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 6.5rem !important;
  }
  .pb-xxl-75 {
    padding-bottom: 7rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 8rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 10rem !important;
  }
  .pb-xxl-95 {
    padding-bottom: 12rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 14rem !important;
  }
  .pb-xxl-11 {
    padding-bottom: 21rem !important;
  }
  .pb-xxl-12 {
    padding-bottom: 16.5rem !important;
  }
  .pb-xxl-13 {
    padding-bottom: 17rem !important;
  }
  .pb-xxl-14 {
    padding-bottom: 18rem !important;
  }
  .pb-xxl-15 {
    padding-bottom: 19rem !important;
  }
  .pb-xxl-16 {
    padding-bottom: 20rem !important;
  }
  .pb-xxl-17 {
    padding-bottom: 21rem !important;
  }
  .pb-xxl-18 {
    padding-bottom: 22rem !important;
  }
  .pb-xxl-19 {
    padding-bottom: 23rem !important;
  }
  .pb-xxl-20 {
    padding-bottom: 24rem !important;
  }
  .pb-xxl-21 {
    padding-bottom: 25rem !important;
  }
  .pb-xxl-22 {
    padding-bottom: 26rem !important;
  }
  .pb-xxl-23 {
    padding-bottom: 27rem !important;
  }
  .pb-xxl-24 {
    padding-bottom: 28rem !important;
  }
  .pb-xxl-25 {
    padding-bottom: 29rem !important;
  }
  .pb-xxl-26 {
    padding-bottom: 30rem !important;
  }
  .pb-xxl-27 {
    padding-bottom: 31rem !important;
  }
  .pb-xxl-28 {
    padding-bottom: 32rem !important;
  }
  .pb-xxl-29 {
    padding-bottom: 33rem !important;
  }
  .pb-xxl-30 {
    padding-bottom: 34rem !important;
  }
  .pb-xxl-nav {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-100 {
    padding-bottom: 6.25rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.4rem !important;
  }
  .ps-xxl-3 {
    padding-left: 0.8rem !important;
  }
  .ps-xxl-35 {
    padding-left: 1.25rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.875rem !important;
  }
  .ps-xxl-45 {
    padding-left: 2.25rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-55 {
    padding-left: 3.5rem !important;
  }
  .ps-xxl-6 {
    padding-left: 3.85rem !important;
  }
  .ps-xxl-65 {
    padding-left: 5rem !important;
  }
  .ps-xxl-68 {
    padding-left: 5.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 6.5rem !important;
  }
  .ps-xxl-75 {
    padding-left: 7rem !important;
  }
  .ps-xxl-8 {
    padding-left: 8rem !important;
  }
  .ps-xxl-9 {
    padding-left: 10rem !important;
  }
  .ps-xxl-95 {
    padding-left: 12rem !important;
  }
  .ps-xxl-10 {
    padding-left: 14rem !important;
  }
  .ps-xxl-11 {
    padding-left: 21rem !important;
  }
  .ps-xxl-12 {
    padding-left: 16.5rem !important;
  }
  .ps-xxl-13 {
    padding-left: 17rem !important;
  }
  .ps-xxl-14 {
    padding-left: 18rem !important;
  }
  .ps-xxl-15 {
    padding-left: 19rem !important;
  }
  .ps-xxl-16 {
    padding-left: 20rem !important;
  }
  .ps-xxl-17 {
    padding-left: 21rem !important;
  }
  .ps-xxl-18 {
    padding-left: 22rem !important;
  }
  .ps-xxl-19 {
    padding-left: 23rem !important;
  }
  .ps-xxl-20 {
    padding-left: 24rem !important;
  }
  .ps-xxl-21 {
    padding-left: 25rem !important;
  }
  .ps-xxl-22 {
    padding-left: 26rem !important;
  }
  .ps-xxl-23 {
    padding-left: 27rem !important;
  }
  .ps-xxl-24 {
    padding-left: 28rem !important;
  }
  .ps-xxl-25 {
    padding-left: 29rem !important;
  }
  .ps-xxl-26 {
    padding-left: 30rem !important;
  }
  .ps-xxl-27 {
    padding-left: 31rem !important;
  }
  .ps-xxl-28 {
    padding-left: 32rem !important;
  }
  .ps-xxl-29 {
    padding-left: 33rem !important;
  }
  .ps-xxl-30 {
    padding-left: 34rem !important;
  }
  .ps-xxl-nav {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-100 {
    padding-left: 6.25rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.4rem !important;
  }
  .gap-xxl-3 {
    gap: 0.8rem !important;
  }
  .gap-xxl-35 {
    gap: 1.25rem !important;
  }
  .gap-xxl-4 {
    gap: 1.875rem !important;
  }
  .gap-xxl-45 {
    gap: 2.25rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .gap-xxl-55 {
    gap: 3.5rem !important;
  }
  .gap-xxl-6 {
    gap: 3.85rem !important;
  }
  .gap-xxl-65 {
    gap: 5rem !important;
  }
  .gap-xxl-68 {
    gap: 5.5rem !important;
  }
  .gap-xxl-7 {
    gap: 6.5rem !important;
  }
  .gap-xxl-75 {
    gap: 7rem !important;
  }
  .gap-xxl-8 {
    gap: 8rem !important;
  }
  .gap-xxl-9 {
    gap: 10rem !important;
  }
  .gap-xxl-95 {
    gap: 12rem !important;
  }
  .gap-xxl-10 {
    gap: 14rem !important;
  }
  .gap-xxl-11 {
    gap: 21rem !important;
  }
  .gap-xxl-12 {
    gap: 16.5rem !important;
  }
  .gap-xxl-13 {
    gap: 17rem !important;
  }
  .gap-xxl-14 {
    gap: 18rem !important;
  }
  .gap-xxl-15 {
    gap: 19rem !important;
  }
  .gap-xxl-16 {
    gap: 20rem !important;
  }
  .gap-xxl-17 {
    gap: 21rem !important;
  }
  .gap-xxl-18 {
    gap: 22rem !important;
  }
  .gap-xxl-19 {
    gap: 23rem !important;
  }
  .gap-xxl-20 {
    gap: 24rem !important;
  }
  .gap-xxl-21 {
    gap: 25rem !important;
  }
  .gap-xxl-22 {
    gap: 26rem !important;
  }
  .gap-xxl-23 {
    gap: 27rem !important;
  }
  .gap-xxl-24 {
    gap: 28rem !important;
  }
  .gap-xxl-25 {
    gap: 29rem !important;
  }
  .gap-xxl-26 {
    gap: 30rem !important;
  }
  .gap-xxl-27 {
    gap: 31rem !important;
  }
  .gap-xxl-28 {
    gap: 32rem !important;
  }
  .gap-xxl-29 {
    gap: 33rem !important;
  }
  .gap-xxl-30 {
    gap: 34rem !important;
  }
  .gap-xxl-nav {
    gap: 0.5rem !important;
  }
  .gap-xxl-100 {
    gap: 6.25rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.4rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 0.8rem !important;
  }
  .row-gap-xxl-35 {
    row-gap: 1.25rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.875rem !important;
  }
  .row-gap-xxl-45 {
    row-gap: 2.25rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xxl-55 {
    row-gap: 3.5rem !important;
  }
  .row-gap-xxl-6 {
    row-gap: 3.85rem !important;
  }
  .row-gap-xxl-65 {
    row-gap: 5rem !important;
  }
  .row-gap-xxl-68 {
    row-gap: 5.5rem !important;
  }
  .row-gap-xxl-7 {
    row-gap: 6.5rem !important;
  }
  .row-gap-xxl-75 {
    row-gap: 7rem !important;
  }
  .row-gap-xxl-8 {
    row-gap: 8rem !important;
  }
  .row-gap-xxl-9 {
    row-gap: 10rem !important;
  }
  .row-gap-xxl-95 {
    row-gap: 12rem !important;
  }
  .row-gap-xxl-10 {
    row-gap: 14rem !important;
  }
  .row-gap-xxl-11 {
    row-gap: 21rem !important;
  }
  .row-gap-xxl-12 {
    row-gap: 16.5rem !important;
  }
  .row-gap-xxl-13 {
    row-gap: 17rem !important;
  }
  .row-gap-xxl-14 {
    row-gap: 18rem !important;
  }
  .row-gap-xxl-15 {
    row-gap: 19rem !important;
  }
  .row-gap-xxl-16 {
    row-gap: 20rem !important;
  }
  .row-gap-xxl-17 {
    row-gap: 21rem !important;
  }
  .row-gap-xxl-18 {
    row-gap: 22rem !important;
  }
  .row-gap-xxl-19 {
    row-gap: 23rem !important;
  }
  .row-gap-xxl-20 {
    row-gap: 24rem !important;
  }
  .row-gap-xxl-21 {
    row-gap: 25rem !important;
  }
  .row-gap-xxl-22 {
    row-gap: 26rem !important;
  }
  .row-gap-xxl-23 {
    row-gap: 27rem !important;
  }
  .row-gap-xxl-24 {
    row-gap: 28rem !important;
  }
  .row-gap-xxl-25 {
    row-gap: 29rem !important;
  }
  .row-gap-xxl-26 {
    row-gap: 30rem !important;
  }
  .row-gap-xxl-27 {
    row-gap: 31rem !important;
  }
  .row-gap-xxl-28 {
    row-gap: 32rem !important;
  }
  .row-gap-xxl-29 {
    row-gap: 33rem !important;
  }
  .row-gap-xxl-30 {
    row-gap: 34rem !important;
  }
  .row-gap-xxl-nav {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-100 {
    row-gap: 6.25rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.4rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 0.8rem !important;
  }
  .column-gap-xxl-35 {
    column-gap: 1.25rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.875rem !important;
  }
  .column-gap-xxl-45 {
    column-gap: 2.25rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .column-gap-xxl-55 {
    column-gap: 3.5rem !important;
  }
  .column-gap-xxl-6 {
    column-gap: 3.85rem !important;
  }
  .column-gap-xxl-65 {
    column-gap: 5rem !important;
  }
  .column-gap-xxl-68 {
    column-gap: 5.5rem !important;
  }
  .column-gap-xxl-7 {
    column-gap: 6.5rem !important;
  }
  .column-gap-xxl-75 {
    column-gap: 7rem !important;
  }
  .column-gap-xxl-8 {
    column-gap: 8rem !important;
  }
  .column-gap-xxl-9 {
    column-gap: 10rem !important;
  }
  .column-gap-xxl-95 {
    column-gap: 12rem !important;
  }
  .column-gap-xxl-10 {
    column-gap: 14rem !important;
  }
  .column-gap-xxl-11 {
    column-gap: 21rem !important;
  }
  .column-gap-xxl-12 {
    column-gap: 16.5rem !important;
  }
  .column-gap-xxl-13 {
    column-gap: 17rem !important;
  }
  .column-gap-xxl-14 {
    column-gap: 18rem !important;
  }
  .column-gap-xxl-15 {
    column-gap: 19rem !important;
  }
  .column-gap-xxl-16 {
    column-gap: 20rem !important;
  }
  .column-gap-xxl-17 {
    column-gap: 21rem !important;
  }
  .column-gap-xxl-18 {
    column-gap: 22rem !important;
  }
  .column-gap-xxl-19 {
    column-gap: 23rem !important;
  }
  .column-gap-xxl-20 {
    column-gap: 24rem !important;
  }
  .column-gap-xxl-21 {
    column-gap: 25rem !important;
  }
  .column-gap-xxl-22 {
    column-gap: 26rem !important;
  }
  .column-gap-xxl-23 {
    column-gap: 27rem !important;
  }
  .column-gap-xxl-24 {
    column-gap: 28rem !important;
  }
  .column-gap-xxl-25 {
    column-gap: 29rem !important;
  }
  .column-gap-xxl-26 {
    column-gap: 30rem !important;
  }
  .column-gap-xxl-27 {
    column-gap: 31rem !important;
  }
  .column-gap-xxl-28 {
    column-gap: 32rem !important;
  }
  .column-gap-xxl-29 {
    column-gap: 33rem !important;
  }
  .column-gap-xxl-30 {
    column-gap: 34rem !important;
  }
  .column-gap-xxl-nav {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-100 {
    column-gap: 6.25rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1280px;
  }
}
@media (min-width: 1441px) {
  .container-xxl, .container-laptop, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1440px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-laptop: 1440px;
  --bs-breakpoint-xxl: 1441px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 4rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 4rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 5rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 5rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 6rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 6rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 7rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 7rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 8rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 8rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 4rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 4rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 6rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 6rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 7rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 7rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 8rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 8rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 4rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 4rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 5rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 5rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 6rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 6rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 7rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 7rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 8rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 8rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 4rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 4rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 6rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 6rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 7rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 7rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 8rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 8rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 4rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 4rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 5rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 5rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 6rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 6rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 7rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 7rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 8rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 8rem;
  }
}
@media (min-width: 1440px) {
  .col-laptop {
    flex: 1 0 0%;
  }
  .row-cols-laptop-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-laptop-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-laptop-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-laptop-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-laptop-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-laptop-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-laptop-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-laptop-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-laptop-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-laptop-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-laptop-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-laptop-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-laptop-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-laptop-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-laptop-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-laptop-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-laptop-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-laptop-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-laptop-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-laptop-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-laptop-0 {
    margin-left: 0;
  }
  .offset-laptop-1 {
    margin-left: 8.33333333%;
  }
  .offset-laptop-2 {
    margin-left: 16.66666667%;
  }
  .offset-laptop-3 {
    margin-left: 25%;
  }
  .offset-laptop-4 {
    margin-left: 33.33333333%;
  }
  .offset-laptop-5 {
    margin-left: 41.66666667%;
  }
  .offset-laptop-6 {
    margin-left: 50%;
  }
  .offset-laptop-7 {
    margin-left: 58.33333333%;
  }
  .offset-laptop-8 {
    margin-left: 66.66666667%;
  }
  .offset-laptop-9 {
    margin-left: 75%;
  }
  .offset-laptop-10 {
    margin-left: 83.33333333%;
  }
  .offset-laptop-11 {
    margin-left: 91.66666667%;
  }
  .g-laptop-0,
  .gx-laptop-0 {
    --bs-gutter-x: 0;
  }
  .g-laptop-0,
  .gy-laptop-0 {
    --bs-gutter-y: 0;
  }
  .g-laptop-1,
  .gx-laptop-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-laptop-1,
  .gy-laptop-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-laptop-2,
  .gx-laptop-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-laptop-2,
  .gy-laptop-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-laptop-3,
  .gx-laptop-3 {
    --bs-gutter-x: 1rem;
  }
  .g-laptop-3,
  .gy-laptop-3 {
    --bs-gutter-y: 1rem;
  }
  .g-laptop-4,
  .gx-laptop-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-laptop-4,
  .gy-laptop-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-laptop-5,
  .gx-laptop-5 {
    --bs-gutter-x: 3rem;
  }
  .g-laptop-5,
  .gy-laptop-5 {
    --bs-gutter-y: 3rem;
  }
  .g-laptop-6,
  .gx-laptop-6 {
    --bs-gutter-x: 4rem;
  }
  .g-laptop-6,
  .gy-laptop-6 {
    --bs-gutter-y: 4rem;
  }
  .g-laptop-7,
  .gx-laptop-7 {
    --bs-gutter-x: 5rem;
  }
  .g-laptop-7,
  .gy-laptop-7 {
    --bs-gutter-y: 5rem;
  }
  .g-laptop-8,
  .gx-laptop-8 {
    --bs-gutter-x: 6rem;
  }
  .g-laptop-8,
  .gy-laptop-8 {
    --bs-gutter-y: 6rem;
  }
  .g-laptop-9,
  .gx-laptop-9 {
    --bs-gutter-x: 7rem;
  }
  .g-laptop-9,
  .gy-laptop-9 {
    --bs-gutter-y: 7rem;
  }
  .g-laptop-10,
  .gx-laptop-10 {
    --bs-gutter-x: 8rem;
  }
  .g-laptop-10,
  .gy-laptop-10 {
    --bs-gutter-y: 8rem;
  }
}
@media (min-width: 1441px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 4rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 6rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 6rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 7rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 7rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 8rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 8rem;
  }
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.51875rem + 3.225vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 3.9375rem;
  }
}

.display-4 {
  font-size: calc(1.46875rem + 2.625vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.4375rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.display-7 {
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-7 {
    font-size: 2rem;
  }
}

.display-8 {
  font-size: calc(1.28125rem + 0.375vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-8 {
    font-size: 1.5625rem;
  }
}

.display-9 {
  font-size: calc(1.26rem + 0.12vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-9 {
    font-size: 1.35rem;
  }
}

.display-10 {
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.2;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  -webkit-border-radius: var(--bs-card-border-radius);
  -moz-border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
  -webkit-border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
  -moz-border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
  -webkit-border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
  -moz-border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  -webkit-border-radius: var(--bs-card-inner-border-radius);
  -moz-border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

body {
  min-width: 320px;
  background-color: #fff;
  color: #001646;
  font-size: 1rem;
  line-height: 1.5625rem;
  font-family: "Raleway", sans-serif;
  overscroll-behavior: none;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}
body.open-menu {
  overflow: hidden;
}

strong {
  font-weight: 700;
}

input,
select,
button {
  appearance: none;
  box-shadow: none;
  border: 0;
  background: transparent;
  outline: 0;
}

#content > div {
  position: relative;
  z-index: 3;
}
#content > .header {
  position: relative;
  z-index: 4;
}

.main {
  position: relative;
  z-index: 1;
}

#app,
.site-container {
  overflow-x: hidden;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  padding-right: calc(var(--bs-gutter-x) * 0.75);
  padding-left: calc(var(--bs-gutter-x) * 0.75);
}

a:not(.btn):hover {
  color: #2DCEA3 !important;
}

.border-blue-1 {
  --bs-border-color: #001646;
}
.border-green-2 {
  --bs-border-color: #dff8f1;
}

.text-blue-1 {
  color: #001646;
}
.text-black {
  color: #000000;
}
.text-gray {
  color: #ACACAC;
}
.text-green-2 {
  color: #DFF8F1;
}
.text-green-4 {
  color: #2DCEA3;
}

.icon-svg svg path {
  -moz-transition: fill 225ms;
  -o-transition: fill 225ms;
  -webkit-transition: fill 225ms;
  transition: fill 225ms;
}
.icon-svg:hover svg path {
  fill: #dc3545;
}

.anim-scale-img {
  overflow: hidden;
}
.anim-scale-img img {
  -moz-transition: all 350ms;
  -o-transition: all 350ms;
  -webkit-transition: all 350ms;
  transition: all 350ms;
}
.anim-scale-img:hover img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  transform: scale(1.15);
}

.card {
  --bs-card-border-radius: 0;
}

.bg-gradient-green {
  background: rgb(223, 248, 241);
  background: linear-gradient(90deg, rgb(236, 248, 245) 0%, rgb(223, 248, 241) 100%);
}
.bg-gradient-blue {
  background: rgb(0, 22, 70);
  background: linear-gradient(90deg, rgb(0, 22, 70) 0%, rgb(11, 39, 100) 100%);
}
.bg-blue-1 {
  background-color: #001646;
}
.bg-green-1 {
  background-color: #ECF8F5;
}
.bg-green-2 {
  background-color: #DFF8F1;
}
.bg-orange {
  background-color: #F06325;
}

.field__title strong {
  font-weight: 800;
}
.field__text p:last-child {
  margin-bottom: 0;
}
.field__text ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.field__text ul li {
  position: relative;
  padding-left: 0.5rem;
}
.field__text ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 1rem;
  width: 0.125rem;
  height: 0.125rem;
  background-color: #000000;
  border-radius: 50%;
}

@media (min-width: 992px) {
  .column-count-3 {
    column-count: 3;
  }
}
.fs-15 {
  font-size: 0.9375rem;
}
.fs-16 {
  font-size: 1rem;
}
.fs-18 {
  font-size: 1.125rem;
}
.fs-20 {
  font-size: 1.25rem;
}
.fs-25 {
  font-size: 1rem;
}
@media (min-width: 1200px) {
  .fs-25 {
    font-size: 1.5625rem;
  }
}
.fs-32 {
  font-size: 2rem;
}
@media (min-width: 1200px) {
  .fs-32 {
    font-size: 2rem;
  }
}
.fs-35 {
  font-size: 1.875rem;
}
@media (min-width: 1200px) {
  .fs-35 {
    font-size: 2.1875rem;
  }
}
.fs-42 {
  font-size: 2.1875rem;
}
@media (min-width: 1200px) {
  .fs-42 {
    font-size: 2.625rem;
  }
}
.fs-45 {
  font-size: 2.375rem;
}
@media (min-width: 1200px) {
  .fs-45 {
    font-size: 2.8125rem;
  }
}
.fs-100 {
  font-size: 3.75rem;
}
@media (min-width: 1200px) {
  .fs-100 {
    font-size: 6.25rem;
  }
}

@media (min-width: 768px) {
  .column-count-2 {
    column-count: 2;
  }
}
@media (max-width: 1199.98px) {
  .vh-100 {
    height: auto !important;
  }
}
.plyr {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 0;
}
.plyr .plyr__control--overlaid {
  background: #2DCEA3;
  padding: calc(var(--plyr-control-spacing, 10px) * 1.5);
  color: var(--plyr-video-control-color, #000000);
}
.plyr .plyr__control--overlaid:hover {
  background: #000000 !important;
}
.plyr .plyr__control--overlaid svg {
  height: var(--plyr-control-icon-size, 20px);
  width: var(--plyr-control-icon-size, 20px);
}
.plyr .plyr__video-wrapper {
  position: relative;
  height: 100%;
}
.plyr .plyr__video-wrapper .plyr__poster {
  background-size: cover;
}
.plyr .plyr__video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fw-light {
  font-weight: 300;
}
.fw-light-italic {
  font-weight: 300;
  font-style: italic;
}
.fw-extra-bold {
  font-weight: 800;
}

.block-words-slider {
  z-index: 1;
}
@media (min-width: 1200px) {
  .block-words-slider {
    height: 43.75rem;
  }
}
@media (min-width: 1200px) {
  .block-words-slider .container > .row > .border-deco {
    position: relative;
  }
  .block-words-slider .container > .row > .border-deco:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: calc(50% - 102px);
    background-color: #fff;
  }
  .block-words-slider .container > .row > .border-deco:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 1px;
    height: calc(50% - 102px);
    background-color: #fff;
  }
}
.block-words-slider__title {
  line-height: 3.3125rem;
}
.block-words-slider__text {
  line-height: 1.75rem;
}
.block-words-slider__slider-nav {
  width: 1.125rem;
}
.block-words-slider__items-item {
  color: #fff;
  line-height: 2.1875rem;
}
@media (min-width: 1200px) {
  .block-words-slider__items-item {
    color: rgba(255, 255, 255, 0.18);
    margin-bottom: 4.375rem;
  }
  .block-words-slider__items-item.vl-slide-center {
    font-size: 2.1875rem;
    line-height: 3.125rem;
    font-weight: 800 !important;
    color: #fff;
  }
}
@media (min-width: 1200px) and (min-width: 1200px) {
  .block-words-slider__items-item.vl-slide-center {
    font-size: 2.8125rem;
  }
}
.block-words-slider .slider-button {
  width: 1.875rem;
  height: 1.875rem;
  box-shadow: none;
  border: 0;
  background-color: transparent;
  outline: 0;
}
.block-words-slider .slider-button svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.block-words-slider .slider-button svg path {
  -moz-transition: all 225ms;
  -o-transition: all 225ms;
  -webkit-transition: all 225ms;
  transition: all 225ms;
}
.block-words-slider .slider-button:hover svg path {
  fill: #F06325;
}
.block-words-slider__deco-circle {
  z-index: 2;
  width: 31.875rem;
  height: 31.875rem;
  top: 56.25rem;
  right: -18.75rem;
}
.block-words-slider__deco-circle svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.block-words-slider__deco-circle svg circle {
  stroke: #F06325;
}
@media (min-width: 1441px) {
  .block-words-slider__deco-circle {
    right: -18.75rem;
  }
}
.block-testimonials {
  z-index: 2;
}
.block-testimonials__bg {
  z-index: -1;
}
@media (min-width: 992px) {
  .template-who-are-we .block-testimonials {
    margin-top: -6.25rem;
  }
}
.block-testimonials__title {
  position: relative;
  z-index: 3;
}
@media (min-width: 768px) {
  .block-testimonials__items-item-author {
    margin-top: -3.75rem;
  }
}
@media (min-width: 1200px) {
  .block-testimonials__image, .block-testimonials__video {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  }
}
.block-testimonials__slider-nav {
  position: relative;
  z-index: 3;
}
@media (min-width: 1200px) {
  .block-testimonials__slider-nav {
    margin-top: -10.9375rem;
  }
}
.block-testimonials__deco-circle {
  z-index: -1;
  width: 8.625rem;
  height: 8.625rem;
  bottom: -4.375rem;
  left: -4.375rem;
}
.block-testimonials__deco-circle svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.block-testimonials__deco-circle svg circle {
  stroke: #F06325;
}
.block-testimonials .slider-button {
  width: 3.125rem;
  height: 3.125rem;
  box-shadow: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: 0;
}
.block-testimonials .slider-button svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.block-testimonials .slider-button svg rect,
.block-testimonials .slider-button svg path {
  -moz-transition: all 225ms;
  -o-transition: all 225ms;
  -webkit-transition: all 225ms;
  transition: all 225ms;
}
.block-testimonials .slider-button:hover svg rect {
  fill: #2DCEA3;
}
.block-testimonials .slider-button:hover svg path {
  fill: #fff;
}

.tab-pane {
  display: none !important;
}
.tab-pane.active {
  display: block !important;
}

.gm-style .gm-style-iw-c {
  border-radius: 0 !important;
  padding: 20px !important;
  outline: 0 !important;
}
.gm-style .gm-style-iw-d {
  overflow: auto !important;
}
.gm-style .gm-style-iw-c {
  background-color: #ECF8F5 !important;
}
.gm-style .gm-style-iw-tc::after {
  content: none !important;
}

.marker-position {
  bottom: 7px;
  left: 0;
  position: relative;
  font-weight: 700;
}

sup {
  top: -1px;
  font-size: 1.125rem;
  left: 2px;
}

.anim-rotate-infinite {
  animation: rotating 15s linear infinite;
}
.anim-rotate-infinite:hover {
  animation-play-state: paused;
}

@-webkit-keyframes rotating {
  /* Safari and Chrome */
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.on-top {
  z-index: 10000;
}
.on-top .icon svg path {
  -moz-transition: all 600ms;
  -o-transition: all 600ms;
  -webkit-transition: all 600ms;
  transition: all 600ms;
}
.on-top:hover .icon svg path {
  fill: #F06325;
  opacity: 1 !important;
}

.gm-style-cc {
  display: none;
}

a[href^="http://maps.google.com/maps"] {
  display: none !important;
}

a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

.wpcf7-response-output {
  position: absolute;
  bottom: calc(100% + 20px);
  left: 0;
  margin: 0 !important;
}

.plyr .plyr__control--overlaid {
  background: #001646;
  -moz-transition: background 225ms;
  -o-transition: background 225ms;
  -webkit-transition: background 225ms;
  transition: background 225ms;
}
.plyr .plyr__control--overlaid svg {
  fill: #8192a6;
  -moz-transition: fill 225ms;
  -o-transition: fill 225ms;
  -webkit-transition: fill 225ms;
  transition: fill 225ms;
}
.plyr .plyr__control--overlaid:hover {
  background: #2DCEA3 !important;
}
.plyr .plyr__control--overlaid:hover svg {
  fill: #001646;
}

.overflow-hidden-y {
  overflow-y: hidden;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.selectr-container {
  width: 100%;
}
.selectr-container .selectr-selected {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  border: 0;
}
.selectr-container .selectr-selected::before {
  content: none;
}
.selectr-container.inverted .selectr-options-container {
  bottom: calc(100% + 8px) !important;
  background-color: #ECF8F5 !important;
}
.selectr-container .selectr-options-container {
  top: calc(100% + 8px);
  border: 0;
  border-radius: 0;
  background-color: #ECF8F5;
}
.selectr-container .selectr-options-container .selectr-option {
  text-transform: lowercase;
  color: #001646;
}
.selectr-container .selectr-options-container .selectr-option:first-letter {
  text-transform: capitalize;
}
.selectr-container .selectr-options-container .selectr-option.active {
  color: #fff !important;
  background-color: #001646 !important;
}
.selectr-container .selectr-options-container .selectr-option.selected {
  color: #fff !important;
  background-color: #2DCEA3 !important;
}

.wpcf7-form .error-radio {
  color: #dc3545;
  font-size: 1.125rem;
  margin: 10px 0;
  font-weight: 700;
}
.wpcf7-form .error-radio.show {
  display: block !important;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ from Chrome */
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

.unaccepted .wpcf7-acceptance .wpcf7-list-item-label,
.unaccepted .wpcf7-acceptance .wpcf7-list-item-label a {
  color: #dc3545;
  font-weight: 700;
}

@media (min-width: 1200px) {
  [anim-fade-in] {
    opacity: 0;
  }
  [anim-fade-in].is-active {
    animation: fadeInUp 0.6s ease 0s forwards;
  }
}
.overlay {
  z-index: 10000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.overlay-logo {
  z-index: 10001;
}

.image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.image-container > img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.blockquote__text {
  line-height: 3.5625rem;
}

.double_column__bg {
  z-index: -1;
}

.our_strengths__items-item {
  width: calc(45% - 1.875rem);
}

.share__rich-text ul {
  list-style: none;
  margin: 0;
  padding: 0;
  column-count: 2;
}
.share__rich-text ul li {
  background-image: url("../img/icons/icon-arrow-right-orange-2.svg");
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 18px;
  display: flex;
  padding-left: 2.8125rem;
  margin-bottom: 0.5rem;
}

#site-navigations {
  position: relative;
  z-index: 1000;
  background-color: #fff;
}

.navigation-top-left .nav-desktop .nav-drop, .navigation-top-right .nav-desktop .nav-drop {
  padding: 1.25rem;
  background-color: #fff;
}
.navigation-top-left .nav-desktop .nav-drop li a, .navigation-top-right .nav-desktop .nav-drop li a {
  color: #001646;
}
.navigation-top-left .nav-desktop .nav-drop li a:hover, .navigation-top-right .nav-desktop .nav-drop li a:hover {
  color: #2DCEA3 !important;
}
.navigation-top-left .menu-item, .navigation-top-right .menu-item {
  display: flex;
  align-items: center;
}
.navigation-top-left .menu-item.with-icon:hover .item-link, .navigation-top-right .menu-item.with-icon:hover .item-link {
  color: #F06325 !important;
}
.navigation-top-left .menu-item.with-icon:hover .item-link > .icon, .navigation-top-right .menu-item.with-icon:hover .item-link > .icon {
  -webkit-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}
.navigation-top-left .menu-item.with-icon:hover .item-link > .icon svg rect, .navigation-top-right .menu-item.with-icon:hover .item-link > .icon svg rect {
  fill: #F06325;
}
.navigation-top-left .menu-item.with-icon .item-link > .icon, .navigation-top-right .menu-item.with-icon .item-link > .icon {
  margin-left: 0.75rem;
  -moz-transition: transform 600ms;
  -o-transition: transform 600ms;
  -webkit-transition: transform 600ms;
  transition: transform 600ms;
}
.navigation-top-left .menu-item.with-icon .item-link > .icon svg rect, .navigation-top-right .menu-item.with-icon .item-link > .icon svg rect {
  fill: #F06325;
  -moz-transition: all 225ms;
  -o-transition: all 225ms;
  -webkit-transition: all 225ms;
  transition: all 225ms;
}
.navigation-top-left .menu-item.current-menu-item > .item-link, .navigation-top-left .menu-item.current_page_item > .item-link, .navigation-top-left .menu-item.current-menu-ancestor > .item-link, .navigation-top-left .menu-item.current-menu-parent > .item-link, .navigation-top-right .menu-item.current-menu-item > .item-link, .navigation-top-right .menu-item.current_page_item > .item-link, .navigation-top-right .menu-item.current-menu-ancestor > .item-link, .navigation-top-right .menu-item.current-menu-parent > .item-link {
  color: #2DCEA3 !important;
}
.navigation-top-left .menu-item > .item-link, .navigation-top-right .menu-item > .item-link {
  position: relative;
  display: flex;
  align-items: center;
  -moz-transition: color 225ms;
  -o-transition: color 225ms;
  -webkit-transition: color 225ms;
  transition: color 225ms;
}
@media (max-width: 1199.98px) {
  .navigation-top-left .menu-item > .item-link, .navigation-top-right .menu-item > .item-link {
    font-weight: 700;
  }
}
.navigation-top-left .menu-item > .item-link:hover, .navigation-top-right .menu-item > .item-link:hover {
  color: #2DCEA3 !important;
}
.navigation-top-left .menu-item.menu-item-has-children:hover > .item-link, .navigation-top-right .menu-item.menu-item-has-children:hover > .item-link {
  color: #2DCEA3 !important;
}
.navigation-top-left .menu-item.menu-item-has-children:hover .nav-drop, .navigation-top-right .menu-item.menu-item-has-children:hover .nav-drop {
  opacity: 1 !important;
  visibility: visible !important;
}
.navigation-top-left .menu-item.menu-item-has-children:hover .nav-drop > li, .navigation-top-right .menu-item.menu-item-has-children:hover .nav-drop > li {
  opacity: 1 !important;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.navigation-top-left .menu-item.menu-item-has-children .nav-drop, .navigation-top-right .menu-item.menu-item-has-children .nav-drop {
  top: 1.875rem;
  z-index: 0;
  width: 160%;
  -moz-transition: all 225ms;
  -o-transition: all 225ms;
  -webkit-transition: all 225ms;
  transition: all 225ms;
}
@media (min-width: 1200px) {
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop, .navigation-top-right .menu-item.menu-item-has-children .nav-drop {
    opacity: 0;
    visibility: hidden;
  }
}
@media (min-width: 1200px) {
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li, .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li {
    opacity: 0;
    -webkit-transform: translateY(-60%);
    -moz-transform: translateY(-60%);
    -ms-transform: translateY(-60%);
    transform: translateY(-60%);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(1), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(1) {
    transition: all 1s 0.08s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(2), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(2) {
    transition: all 1s 0.16s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(3), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(3) {
    transition: all 1s 0.24s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(4), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(4) {
    transition: all 1s 0.32s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(5), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(5) {
    transition: all 1s 0.4s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(6), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(6) {
    transition: all 1s 0.48s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(7), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(7) {
    transition: all 1s 0.56s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(8), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(8) {
    transition: all 1s 0.64s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(9), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(9) {
    transition: all 1s 0.72s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(10), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(10) {
    transition: all 1s 0.8s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(11), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(11) {
    transition: all 1s 0.88s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(12), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(12) {
    transition: all 1s 0.96s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(13), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(13) {
    transition: all 1s 1.04s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(14), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(14) {
    transition: all 1s 1.12s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(15), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(15) {
    transition: all 1s 1.2s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(16), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(16) {
    transition: all 1s 1.28s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(17), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(17) {
    transition: all 1s 1.36s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(18), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(18) {
    transition: all 1s 1.44s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(19), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(19) {
    transition: all 1s 1.52s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(20), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(20) {
    transition: all 1s 1.6s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(21), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(21) {
    transition: all 1s 1.68s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(22), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(22) {
    transition: all 1s 1.76s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(23), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(23) {
    transition: all 1s 1.84s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(24), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(24) {
    transition: all 1s 1.92s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(25), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(25) {
    transition: all 1s 2s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(26), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(26) {
    transition: all 1s 2.08s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(27), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(27) {
    transition: all 1s 2.16s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(28), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(28) {
    transition: all 1s 2.24s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(29), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(29) {
    transition: all 1s 2.32s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(30), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(30) {
    transition: all 1s 2.4s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(31), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(31) {
    transition: all 1s 2.48s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(32), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(32) {
    transition: all 1s 2.56s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(33), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(33) {
    transition: all 1s 2.64s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(34), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(34) {
    transition: all 1s 2.72s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(35), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(35) {
    transition: all 1s 2.8s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(36), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(36) {
    transition: all 1s 2.88s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(37), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(37) {
    transition: all 1s 2.96s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(38), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(38) {
    transition: all 1s 3.04s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(39), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(39) {
    transition: all 1s 3.12s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(40), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(40) {
    transition: all 1s 3.2s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(41), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(41) {
    transition: all 1s 3.28s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(42), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(42) {
    transition: all 1s 3.36s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(43), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(43) {
    transition: all 1s 3.44s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(44), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(44) {
    transition: all 1s 3.52s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(45), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(45) {
    transition: all 1s 3.6s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(46), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(46) {
    transition: all 1s 3.68s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(47), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(47) {
    transition: all 1s 3.76s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(48), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(48) {
    transition: all 1s 3.84s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(49), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(49) {
    transition: all 1s 3.92s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
  .navigation-top-left .menu-item.menu-item-has-children .nav-drop > li:nth-child(50), .navigation-top-right .menu-item.menu-item-has-children .nav-drop > li:nth-child(50) {
    transition: all 1s 4s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  }
}

#burger-menu {
  position: relative;
  z-index: 1;
  width: 2.1875rem;
  height: 2.1875rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -moz-transition: all 600ms ease-in-out;
  -o-transition: all 600ms ease-in-out;
  -webkit-transition: all 600ms ease-in-out;
  transition: all 600ms ease-in-out;
  cursor: pointer;
}
#burger-menu.open span:nth-child(1) {
  top: 0.625rem;
  width: 0;
  left: 50%;
}
#burger-menu.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
#burger-menu.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#burger-menu.open span:nth-child(4) {
  top: 0.625rem;
  width: 0;
  left: 50%;
}
#burger-menu span {
  display: block;
  position: absolute;
  height: 0.125rem;
  width: 100%;
  background-color: #001646;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
body.open-menu #burger-menu span {
  background-color: #fff;
}
#burger-menu span:nth-child(1) {
  top: 0.3125rem;
}
#burger-menu span:nth-child(2), #burger-menu span:nth-child(3) {
  top: 1.0625rem;
}
#burger-menu span:nth-child(4) {
  top: 1.8125rem;
}

#site-navigation-top__mobile {
  z-index: 0;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -moz-transition: transform 600ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: transform 600ms cubic-bezier(0.42, 0, 0.58, 1);
  -webkit-transition: transform 600ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: transform 600ms cubic-bezier(0.42, 0, 0.58, 1);
}
#site-navigation-top__mobile.open {
  pointer-events: auto !important;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.header {
  overflow: hidden;
  z-index: 0;
}
.header__title strong {
  font-weight: 800;
}
.header#site-header-home {
  overflow: visible;
}
@media (min-width: 1200px) {
  .header#site-header-home {
    height: calc(100vh - 124px);
  }
}
@media (max-width: 991.98px) {
  .header__link-2 {
    position: relative !important;
    right: auto !important;
    bottom: auto !important;
  }
}
.header__deco-circle {
  z-index: 2;
  bottom: -12.5rem;
  left: -12.5rem;
}
.header__deco-circle svg circle {
  stroke: #2DCEA3;
}

.footer {
  position: relative;
  z-index: 2;
}
@media (min-width: 992px) {
  .footer .menu-item.icon-arrow-rotate {
    margin-top: 6.25rem;
  }
}
.footer .menu-item.icon-arrow-rotate .item-link:hover {
  color: #F06325 !important;
}
.footer .menu-item.icon-arrow-rotate .item-link .icon {
  width: 1.25rem;
  height: 1.25rem;
}
.footer .menu-item.icon-arrow-rotate .item-link .icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.footer__phone .icon svg path {
  fill: #fff;
}
.footer__social-medias-items-item {
  width: 1.6875rem;
  height: 1.6875rem;
}
.footer__social-medias-items-item:hover svg path {
  fill: #2DCEA3;
}
.footer__social-medias-items-item svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.footer__social-medias-items-item svg path {
  -moz-transition: all 225ms;
  -o-transition: all 225ms;
  -webkit-transition: all 225ms;
  transition: all 225ms;
}

.news__title h4, .news__title .h4 {
  -moz-transition: color 225ms;
  -o-transition: color 225ms;
  -webkit-transition: color 225ms;
  transition: color 225ms;
}
.news__title:hover h4, .news__title:hover .h4 {
  color: #2DCEA3;
}

.breadcrumb ul li {
  position: relative;
  padding-right: 0.375rem;
  margin-right: 0.375rem;
  font-size: 0.75rem;
}
.breadcrumb ul li:last-child {
  padding: 0;
  margin: 0;
}
.breadcrumb ul li:last-child:after {
  content: none;
}
.breadcrumb ul li:after {
  content: "";
  position: absolute;
  top: 0.6875rem;
  right: -0.0625rem;
  width: 0.1875rem;
  height: 0.1875rem;
  background-color: #001646;
  border-radius: 50%;
}

body.home .key-figures {
  position: relative;
  z-index: 2;
  margin-top: -14.0625rem;
  padding-bottom: 0 !important;
}
@media (min-width: 992px) {
  body.home .key-figures {
    margin-top: -21.875rem;
  }
}
body.page-template-why-join-fd .key-figures {
  position: relative;
  z-index: 2;
  padding-bottom: 0 !important;
}
@media (min-width: 992px) {
  body.page-template-why-join-fd .key-figures {
    margin-top: -14.0625rem;
  }
}
.key-figures__boxes {
  margin-top: -6.25rem;
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(1) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 0.08s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(2) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 0.16s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(3) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 0.24s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(4) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 0.32s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(5) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 0.4s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(6) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 0.48s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(7) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 0.56s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(8) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 0.64s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(9) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 0.72s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(10) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 0.8s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(11) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 0.88s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(12) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 0.96s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(13) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 1.04s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(14) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 1.12s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(15) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 1.2s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(16) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 1.28s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(17) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 1.36s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(18) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 1.44s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(19) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 1.52s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(20) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 1.6s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(21) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 1.68s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(22) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 1.76s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(23) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 1.84s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(24) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 1.92s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(25) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 2s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(26) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 2.08s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(27) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 2.16s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(28) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 2.24s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(29) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 2.32s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(30) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 2.4s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(31) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 2.48s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(32) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 2.56s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(33) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 2.64s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(34) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 2.72s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(35) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 2.8s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(36) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 2.88s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(37) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 2.96s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(38) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 3.04s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(39) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 3.12s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(40) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 3.2s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(41) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 3.28s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(42) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 3.36s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(43) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 3.44s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(44) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 3.52s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(45) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 3.6s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(46) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 3.68s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(47) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 3.76s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(48) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 3.84s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(49) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 3.92s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__boxes.is-active .key-figures__box-item:nth-child(50) .key-figures__box-item-number {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 1s 4s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.key-figures__box {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
}
@media (min-width: 992px) {
  .key-figures__box {
    height: 24.375rem;
  }
}
.key-figures__box-item-number {
  display: inline-flex;
  font-family: "Lexend", sans-serif;
}
@media (min-width: 992px) {
  .key-figures__box-item-number {
    opacity: 0;
    -webkit-transform: translateX(-15px);
    -moz-transform: translateX(-15px);
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
.key-figures__box-item-number > .icon {
  top: 0.9375rem;
  left: calc(100% + 15px);
}

form {
  overflow: hidden;
}
@media (min-width: 1200px) {
  form .row {
    margin-bottom: 1.875rem;
  }
}
@media (max-width: 1199.98px) {
  form .row > * {
    margin-bottom: 1.875rem;
  }
}
form .row:last-child {
  margin-bottom: 0;
}
form .row > .border-custom > p {
  border-bottom: 1px solid #001646;
  margin-bottom: 0;
  padding-bottom: 0.625rem;
}
form label {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  margin-bottom: 0.9375rem;
  font-size: 1.125rem;
  font-weight: 700;
}
form label.anim-label {
  -moz-transition: all 225ms;
  -o-transition: all 225ms;
  -webkit-transition: all 225ms;
  transition: all 225ms;
  top: 1.25rem;
  margin-bottom: 0;
  cursor: pointer;
}
form label.anim-label.active {
  font-size: 0.875rem;
  top: 0rem;
}
form label.textarea {
  top: 2.5rem;
  left: 1.5625rem;
}
form label.textarea.active {
  top: -0.625rem;
  left: 0rem;
}
form input {
  font-size: 1.125rem;
  border-radius: 0;
  color: #001646;
  outline: 0;
}
form textarea {
  font-size: 1.125rem;
  padding: 1.5625rem;
  outline: 0;
  color: #001646;
  border-radius: 0;
  resize: none;
  width: 100%;
  height: 9.375rem;
}
@media (min-width: 1200px) {
  form textarea {
    height: 15.625rem;
  }
}

.wpcf7-radio .wpcf7-list-item {
  margin: 0 0 0 1.6rem;
}
.wpcf7-radio .wpcf7-list-item input[type=radio] {
  position: relative;
  border: 1px solid #001646;
  border-radius: 50%;
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.125rem;
  padding: 0;
  cursor: pointer;
}
.wpcf7-radio .wpcf7-list-item input[type=radio]:checked:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  width: 0.375rem;
  height: 0.375rem;
  background-color: #001646;
}

.wpcf7-acceptance .wpcf7-list-item {
  margin: 0;
}
.wpcf7-acceptance label {
  font-size: 1rem;
  font-weight: 400;
  padding-left: 0;
  align-items: flex-start;
}
.wpcf7-acceptance label a {
  text-decoration: underline;
}
.wpcf7-acceptance label input[name=acceptance] {
  position: relative;
  border: 1px solid #001646;
  border-radius: 4px;
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.625rem;
  top: 0.25rem;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
}
.wpcf7-acceptance label input[name=acceptance]:checked {
  background-image: url("../img/icons/icon-check.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px;
}

.wpcf7-not-valid-tip {
  color: #dc3545;
  position: absolute;
  bottom: -50px;
}

.block-box__items-item {
  position: relative;
}
@media (min-width: 992px) {
  .block-box__items-item {
    padding-right: calc(var(--bs-gutter-x) * 1.5);
    padding-left: calc(var(--bs-gutter-x) * 1.5);
  }
  .block-box__items-item:nth-child(even) {
    top: 12.5rem;
  }
}
.block-box__deco-circle {
  z-index: 2;
  width: 31.875rem;
  height: 31.875rem;
  top: 37.5rem;
  right: -12.5rem;
}
.block-box__deco-circle svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.block-box__deco-circle svg circle {
  stroke: #F06325;
}
@media (min-width: 1441px) {
  .block-box__deco-circle {
    right: -9.375rem;
  }
}
.block-box__deco-circle-2 {
  z-index: 2;
  top: -12.5rem;
  left: -12.5rem;
}
.block-box__deco-circle-2 svg circle {
  stroke: #2DCEA3;
}
.block-members {
  margin-top: -20.9375rem;
}
.block-members__bg {
  z-index: -1;
}
@media (min-width: 992px) {
  .block-members__video, .block-members__image {
    height: 53.125rem;
  }
}
.block-news-events {
  z-index: 0;
}
.block-news-events__deco-circle {
  z-index: 2;
  width: 31.875rem;
  height: 31.875rem;
  top: -21.875rem;
  left: -18.75rem;
}
.block-news-events__deco-circle svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.block-news-events__deco-circle svg circle {
  stroke: #F06325;
}
@media (min-width: 1441px) {
  .block-news-events__deco-circle {
    right: -18.75rem;
  }
}
.block-news-events__bg {
  z-index: -1;
  height: 73.75rem;
}
.block-news-events__title {
  line-height: 3.3125rem;
}

#page-404 .block-title {
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
#page-404 .block-title .block-title-number {
  display: block;
  font-size: 250px;
  line-height: normal;
  color: #f3f3f3;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -45%);
  -moz-transform: translate(-50%, -45%);
  -ms-transform: translate(-50%, -45%);
  transform: translate(-50%, -45%);
}
#page-404 .block-title .block-title-line-1 {
  display: block;
  font-size: 16px;
  line-height: normal;
  font-weight: 100;
  color: #666;
}
#page-404 .block-title .block-title-line-2 {
  display: block;
  font-weight: 700;
  font-size: 36px;
}

.single-news .news-others__bg {
  z-index: -1;
  height: 36.8125rem;
}

.template-who-are-we__timeline .tns-inner {
  display: flex;
}
.template-who-are-we__blockquote-text {
  line-height: 3.5625rem;
}
.template-who-are-we__members-bg, .template-who-are-we__our-strengths-bg {
  z-index: -1;
}
.template-who-are-we__ope-team {
  margin-top: -28.125rem;
}
.template-who-are-we__our-strengths {
  margin-top: -18.75rem;
}
@media (min-width: 992px) {
  .template-who-are-we__our-strengths {
    margin-top: -25rem;
  }
  .template-who-are-we__our-strengths-items-item {
    width: calc(45% - 1.875rem);
  }
}
.template-who-are-we .customize-tools {
  border-bottom: 1px solid #2DCEA3;
}
.template-who-are-we .customize-tools .slider-button {
  position: relative;
  top: 0.3125rem;
}
.template-who-are-we .customize-tools .slider-button[disabled] {
  opacity: 0;
  pointer-events: none;
}
.template-who-are-we .customize-tools .tns-nav {
  position: relative;
  top: 1.3125rem;
}
.template-who-are-we .customize-tools .tns-nav button {
  position: relative;
}
.template-who-are-we .customize-tools .tns-nav button:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  transform-origin: center;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  height: 0.125rem;
  bottom: -0.0625rem;
  background-color: #2DCEA3;
  -moz-transition: transform 600ms;
  -o-transition: transform 600ms;
  -webkit-transition: transform 600ms;
  transition: transform 600ms;
}
.template-who-are-we .customize-tools .tns-nav button.tns-nav-active {
  font-weight: 700;
  font-size: 1.875rem;
  color: #2DCEA3;
}
.template-who-are-we .customize-tools .tns-nav button.tns-nav-active:after {
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.template-why-join-fd__link {
  left: -3.5625rem;
  bottom: -3.5625rem;
  z-index: 1;
}
.template-why-join-fd__deco-circle {
  z-index: 2;
  bottom: -12.5rem;
  left: -12.5rem;
}
.template-why-join-fd__deco-circle svg circle {
  stroke: #2DCEA3;
}
@media (min-width: 1441px) {
  .template-why-join-fd__deco-circle {
    bottom: -9.375rem;
    left: -9.375rem;
  }
}
.template-why-join-fd__share-image {
  position: relative;
  z-index: 3;
}
.template-why-join-fd__share-bg {
  position: relative;
  z-index: -1;
}
.template-why-join-fd__testimonials {
  z-index: 0;
  margin-top: -20.625rem;
}
.template-why-join-fd__strategies-items-item .counter {
  z-index: -1;
  margin-top: -0.3125rem;
}
.template-why-join-fd__share-rich-text ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (min-width: 768px) {
  .template-why-join-fd__share-rich-text ul {
    column-count: 2;
  }
}
.template-why-join-fd__share-rich-text ul li {
  background-image: url("../img/icons/icon-arrow-right-orange-2.svg");
  background-repeat: no-repeat;
  background-position: top 6px left;
  background-size: 18px;
  display: flex;
  padding-left: 2.8125rem;
  margin-bottom: 0.5rem;
}
.template-why-join-fd__image, .template-why-join-fd__video {
  position: relative;
  z-index: 3;
}

.template-our-operation__link {
  right: 12.5rem;
  bottom: -3.5625rem;
  z-index: 1;
}
.template-our-operation__title-image {
  z-index: 1;
}
@media (max-width: 767.98px) {
  .template-our-operation__title-image.section-parallax:after {
    content: "";
    z-index: -1;
    position: absolute;
    bottom: -2px;
    right: 0;
    left: 0;
    height: 180px;
    background: rgb(0, 22, 70);
    background: linear-gradient(90deg, rgb(0, 22, 70) 0%, rgb(11, 39, 100) 100%);
  }
}
@media (min-width: 992px) {
  .template-our-operation__title-image {
    margin-top: -9.375rem;
  }
}
.template-our-operation__title-image-bg {
  z-index: -1;
}
.template-our-operation__bg-blue {
  z-index: 0;
}
@media (max-width: 767.98px) {
  .template-our-operation__bg-blue .row.pt-12.pt-xl-22.pb-5.pb-xl-65 {
    padding-top: 3rem !important;
  }
}
@media (min-width: 768px) {
  .template-our-operation__bg-blue {
    margin-top: -16.25rem;
  }
}
.template-our-operation__deco-circle {
  z-index: 2;
  top: -50px;
  right: -150px;
}
.template-our-operation__deco-circle svg circle {
  stroke: #F06325;
}
.template-our-operation__testimonials {
  z-index: 0;
}

.template-our-promise__deco-circle {
  z-index: 2;
  bottom: -12.5rem;
  left: -12.5rem;
}
.template-our-promise__deco-circle svg circle {
  stroke: #2DCEA3;
}
@media (min-width: 1441px) {
  .template-our-promise__deco-circle {
    bottom: -9.375rem;
    left: -9.375rem;
  }
}
.template-our-promise__boxes-with-icons {
  z-index: 3;
}
.template-our-promise__boxes-with-icons-deco-circle {
  z-index: 2;
  bottom: -12.5rem;
  right: -12.5rem;
}
.template-our-promise__boxes-with-icons-deco-circle svg circle {
  stroke: #2DCEA3;
}
@media (min-width: 1441px) {
  .template-our-promise__boxes-with-icons-deco-circle {
    bottom: -9.375rem;
    right: -9.375rem;
  }
}
.template-our-promise__boxes-with-icons-items {
  z-index: 4;
}
.template-our-promise__boxes-with-icons-items-item {
  width: 100%;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.07);
}
@media (min-width: 992px) {
  .template-our-promise__boxes-with-icons-items-item {
    width: 21.375rem;
    height: 21.375rem;
  }
  .template-our-promise__boxes-with-icons-items-item:nth-child(even) {
    position: relative;
    top: 12.5rem;
  }
  .template-our-promise__boxes-with-icons-items-item:last-child {
    margin-bottom: -50px !important;
  }
}
.template-our-promise__double_column-bis-rich-text-2 ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.template-our-promise__double_column-bis-rich-text-2 ul li {
  padding-left: 3.125rem;
  margin-bottom: 1.25rem;
  background-image: url("../img/icons/icon-arrow-right-green.svg");
  background-repeat: no-repeat;
  background-position: top 5px left;
  background-size: 18px;
}
.template-our-promise__double_column-bis-rich-text-2 ul li:last-child {
  margin-bottom: 0 !important;
}
.template-our-promise__texts-image-rich-text-1 strong, .template-our-promise__texts-image-rich-text-2 strong {
  color: #2DCEA3;
  display: flex;
  margin-bottom: 1.875rem;
}
.template-our-promise__texts-image-rich-text-1 ul, .template-our-promise__texts-image-rich-text-2 ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.template-our-promise__texts-image-rich-text-1 ul li, .template-our-promise__texts-image-rich-text-2 ul li {
  position: relative;
  margin-bottom: 0.625rem;
}
.template-our-promise__texts-image-rich-text-1 ul li:before, .template-our-promise__texts-image-rich-text-2 ul li:before {
  content: "•";
  position: relative;
  display: inline-flex;
  margin-right: 0.3125rem;
}
.template-our-promise__texts-image-rich-text-1 ul li:last-child, .template-our-promise__texts-image-rich-text-2 ul li:last-child {
  margin-bottom: 0 !important;
}
.template-our-promise__texts-image {
  position: relative;
  z-index: 3;
}
.template-our-promise__texts-image-image {
  position: relative;
}
@media (min-width: 992px) {
  .template-our-promise__texts-image-image {
    margin-bottom: -12.5rem;
  }
}
.template-our-promise__testimonials {
  z-index: 0;
}
@media (min-width: 992px) {
  .template-our-promise__testimonials {
    margin-top: -20.625rem;
  }
}

.template-the-procedure-in-detail__steps-counter-item {
  position: relative;
  font-family: "Helvetica", Arial, serif;
}
@media (min-width: 992px) {
  .template-the-procedure-in-detail__steps-counter-item:after {
    content: "";
    display: inline-flex;
    width: 0.375rem;
    height: 0.375rem;
    margin-left: 0.375rem;
    background-color: #F06325;
  }
}
.template-the-procedure-in-detail__steps-items {
  position: relative;
}
.template-the-procedure-in-detail__steps-items:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  top: 4.0625rem;
  left: 0;
  background-color: #fff;
}
.template-the-procedure-in-detail__steps-items-item {
  width: 100%;
}
@media (min-width: 992px) {
  .template-the-procedure-in-detail__steps-items-item {
    width: 13.75rem;
  }
}
.template-the-procedure-in-detail__double-column {
  position: relative;
  z-index: 3;
}
@media (min-width: 992px) {
  .template-the-procedure-in-detail__double-column {
    margin-bottom: -12.5rem;
  }
}

.template-our-members ul.nav-tabs li:hover a, .template-our-members ul.nav-tabs li.active a {
  background-color: #001646;
  color: #fff !important;
}
.template-our-members ul.nav-tabs li:hover a .icon svg line, .template-our-members ul.nav-tabs li.active a .icon svg line {
  stroke: #fff;
}
.template-our-members ul.nav-tabs li:hover a .icon svg path, .template-our-members ul.nav-tabs li.active a .icon svg path {
  fill: #fff;
}
.template-our-members ul.nav-tabs li a {
  -moz-transition: all 225ms;
  -o-transition: all 225ms;
  -webkit-transition: all 225ms;
  transition: all 225ms;
}
.template-our-members ul.nav-tabs li a svg path, .template-our-members ul.nav-tabs li a svg line {
  -moz-transition: all 225ms;
  -o-transition: all 225ms;
  -webkit-transition: all 225ms;
  transition: all 225ms;
}
.template-our-members__head {
  position: relative;
  z-index: 0;
}
.template-our-members__form {
  position: relative;
  z-index: 1;
  margin-top: -3.125rem;
}
@media (min-width: 1200px) {
  .template-our-members__form {
    margin-bottom: 3.375rem;
  }
}
@media (max-width: 991.98px) {
  .template-our-members__form > .container > div {
    border-radius: 0 !important;
  }
}
.template-our-members__form label {
  min-width: 100%;
  background-image: url("../img/icons/icon-arrow-down-white.svg");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 15px;
  margin-bottom: 0.9375rem;
}
@media (min-width: 992px) {
  .template-our-members__form label {
    min-width: 9.375rem;
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .template-our-members__form label {
    min-width: 18.75rem;
  }
}
.template-our-members__form label select {
  font-size: 0.9375rem;
  color: #fff;
}
.template-our-members #tab-2 {
  height: 53.125rem;
}

.template-news-events input[type=checkbox] {
  position: relative;
  border: 1px solid #001646;
  border-radius: 4px;
  top: 0.125rem;
  width: 1.0625rem;
  height: 1.0625rem;
  margin-right: 0.75rem;
  padding: 0;
  cursor: pointer;
  pointer-events: none;
}
.template-news-events input[type=checkbox]:checked {
  background-image: url("../img/icons/icon-check.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px;
}
.template-news-events__items-item-metas-city {
  position: relative;
}
.template-news-events__items-item-metas-city:before {
  content: "";
  position: absolute;
  top: 0.125rem;
  left: -0.0625rem;
  width: 0.125rem;
  height: 1.4375rem;
  background-color: #2DCEA3;
}
.template-news-events__filters-item.active input[type=checkbox] {
  background-image: url("../img/icons/icon-check.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px;
}

.template-comite__content-anchors {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
}
.template-comite__content-anchors-wrapper {
  z-index: 10;
}
.template-comite__content-anchors li {
  position: relative;
  scroll-snap-align: start;
}
.template-comite__content-anchors li:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.3125rem;
  background-color: #001646;
  transform-origin: center;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -moz-transition: transform, 600ms;
  -o-transition: transform, 600ms;
  -webkit-transition: transform, 600ms;
  transition: transform, 600ms;
}
@media (min-width: 768px) {
  .template-comite__content-anchors li:hover:after {
    -webkit-transform: scaleX(0.5);
    -moz-transform: scaleX(0.5);
    -ms-transform: scaleX(0.5);
    transform: scaleX(0.5);
  }
}
.template-comite__content-anchors li.active:after {
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
.template-comite__content-anchors li.active button {
  font-weight: 700;
}
.template-comite__content-items-item-rich-text h3, .template-comite__content-items-item-rich-text .h3 {
  position: relative;
  color: #2DCEA3;
  font-weight: 700;
  font-size: 1.125rem;
  margin-top: 2.1875rem;
  margin-bottom: 1.875rem;
}

.template-the-procedure-in-detail .block-testimonials .block-testimonials__items .block-testimonials__items-item {
  padding-bottom: 0px !important;
}

@media (max-width: 767.98px) {
  .tns-outer {
    max-width: 100%;
    width: 100%;
  }
  .tns-outer .block-words-slider__items-item {
    max-width: 100%;
    width: 100%;
    word-break: break-word;
  }
}

.relative {
  position: relative;
}

.z-10 {
  z-index: 10;
}

@media (max-width: 767.98px) {
  .md-h-content {
    height: fit-content;
  }
}
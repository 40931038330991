// Template "Pourquoi rejoindre France défi ?"
.template-why-join-fd {
  &__link {
    @include toRem(left, -57);
    @include toRem(bottom, -57);
    z-index: 1;
  }

  &__deco-circle {
    z-index: 2;
    @include toRem(bottom, -200);
    @include toRem(left, -200);

    svg circle {
      stroke: $green_4;
    }

    @include media-breakpoint-up(xxl) {
      @include toRem(bottom, -150);
      @include toRem(left, -150);
    }
  }

  &__share {
    &-image {
      position: relative;
      z-index: 3;
    }

    &-bg {
      position: relative;
      z-index: -1;
    }
  }

  &__testimonials {
    z-index: 0;
    @include toRem(margin-top, -330);
  }

  &__strategies-items-item {
    .counter {
      z-index: -1;
      @include toRem(margin-top, -5);
    }
  }

  &__share-rich-text {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      @include media-breakpoint-up(md) {
        column-count: 2;
      }

      li {
        background-image: url("../img/icons/icon-arrow-right-orange-2.svg");
        background-repeat: no-repeat;
        background-position: top 6px left;
        background-size: 18px;
        display: flex;
        @include toRem(padding-left, 45);
        @include toRem(margin-bottom, 8);
      }
    }
  }

  &__image,
  &__video {
    position: relative;
    z-index: 3;

  }
}
//Colors
$white: #fff;
$blue_1: #001646;
$blue_2: #0B2764;
$green_0: #B9C3D8;
$green_1: #ECF8F5;
$green_2: #DFF8F1;
$green_3: #97D9C7;
$green_4: #2DCEA3;
$orange: #F06325;
$black: #000000;
$gray: #ACACAC;
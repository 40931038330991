// Header
.header {
  overflow: hidden;
  z-index: 0;

  &__title {
    strong {
      font-weight: 800;
    }
  }

  &#site-header-home {
    overflow: visible;

    @include media-breakpoint-up(xl) {
      height: calc(100vh - 124px);
    }
  }

  &__link-2 {
    @include media-breakpoint-down(lg) {
      position: relative !important;
      right: auto !important;
      bottom: auto !important;
    }
  }

  &__deco-circle {
    z-index: 2;
    @include toRem(bottom, -200);
    @include toRem(left, -200);

    svg circle {
      stroke: $green_4;
    }
  }
}
// Template "La procédure en détails"
.template-the-procedure-in-detail {
  &__steps-counter-item {
    position: relative;
    font-family: 'Helvetica', Arial, serif;

    @include media-breakpoint-up(lg) {
      &:after {
        content: '';
        display: inline-flex;
        @include toRem(width, 6);
        @include toRem(height, 6);
        @include toRem(margin-left, 6);
        background-color: $orange;
      }
    }
  }

  &__steps-items {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      @include toRem(height, 1);
      @include toRem(top, 65);
      left: 0;
      background-color: $white;
    }

    &-item {
      width: 100%;

      @include media-breakpoint-up(lg) {
        @include toRem(width, 220);
      }
    }
  }

  &__double-column {
    position: relative;
    z-index: 3;

    @include media-breakpoint-up(lg) {
      @include toRem(margin-bottom, -200);
    }
  }
}
// Template "Nos membres"
.template-our-members {
  ul.nav-tabs {
    li {
      &:hover,
      &.active {
        a {
          background-color: $blue_1;
          color: $white !important;

          .icon {
            svg {
              line {
                stroke: $white;
              }

              path {
                fill: $white;
              }
            }
          }
        }
      }

      a {
        @include transition(all 225ms);

        svg {
          path, line {
            @include transition(all 225ms);
          }
        }
      }
    }
  }

  &__head {
    position: relative;
    z-index: 0;
  }

  &__form {
    position: relative;
    z-index: 1;
    @include toRem(margin-top, -50);

    @include media-breakpoint-up(xl) {
      @include toRem(margin-bottom, 54);
    }

    > .container > div {
      @include media-breakpoint-down(lg) {
        border-radius: 0 !important;
      }
    }

    label {
      min-width: 100%;
      background-image: url("../img/icons/icon-arrow-down-white.svg");
      background-repeat: no-repeat;
      background-position: center right;
      background-size: 15px;
      @include toRem(margin-bottom, 15);

      @include media-breakpoint-up(lg) {
        @include toRem(min-width, 150);
        margin-bottom: 0;
      }

      @include media-breakpoint-up(xl) {
        @include toRem(min-width, 300);
      }

      select {
        @include toRem(font-size, 15);
        color: $white;
      }
    }
  }

  #tab-2 {
    @include toRem(height, 850);
  }
}